import React from "react";
import { Grid } from "@mui/material";
import * as colors from "../../../utils/colors";
import "./pricing_main_styles.css";
import "../../../components/GlassmorphBG/glassmorph_styles.css";
import { GLOBAL_TOP_MARGIN } from "../../../utils/ui-constants";

const PricingMain = () => {
  return (
    <div
      style={{
        backgroundColor: colors.MAIN_BACKGROUND,
        width: "100vw",
        zIndex: 1,
      }}
    >
      <div className="pricing-main-ellipse-1 blur400" />
      <div className="pricing-main-ellipse-2 blur400" />
      <Grid
        container
        className="pricing-main-bg"
        sx={{ pt: GLOBAL_TOP_MARGIN }}
      >
        <Grid
          container
          sx={{ mb: 24 }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={3} />
          <Grid
            container
            item
            xs={6}
            justifyContent="center"
            alignItems="center"
            rowSpacing={2}
          >
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              xs={12}
            >
              <div className="glassmorph_chip">
                <label className="glassmorph_chip_text">Our Pricing</label>
              </div>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
            >
              <label className="pricing-main-info-text">
                Get Started with{" "}
                <span className="pricing-main-info-text pricing-main-info-text-highlight">
                  Our Plans
                </span>{" "}
                Pick What is Suitable For You.
              </label>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
            >
              <label className="pricing-main-info-text-small">
                Get Started with Fitmedik. Monitor, Prevent, Treat and Manage
                burnout of care providers. We're with you each step of the way.
              </label>
            </Grid>
          </Grid>
          <Grid item xs={3} />
        </Grid>
      </Grid>
    </div>
  );
};

export default PricingMain;
