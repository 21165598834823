import React from "react";
import "../glassmorph_styles.css";

const GlassmorphMessageBox = ({title, content, image, name, position}) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: 'fit-content',
                justifyContent: 'space-between',
                height: '100%',
                padding: '30px',
                marginRight: '15px'
            }}
            className="glassmorph_style"
        >
            <label className="glassmorph_title">{title}</label>
            <br/>
            <label className="glassmorph_content">{content}</label>
            <br/>
            <br/>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <img
                    style={{height: '45px', width: '45px', borderRadius: 100}}
                    src={image}
                    alt={'profile_pic'}
                />
                <div style={{display: 'flex', flexDirection: 'column', marginLeft: '20px'}}>
                    <label style={{
                        fontSize: '16px',
                        fontFamily: 'Poppins',
                        fontWeight: '500',
                        lineHeight: '24px',
                        color: 'white'
                    }}>
                        {name}
                    </label>
                    <label style={{
                        fontSize: '16px',
                        fontFamily: 'Poppins',
                        fontWeight: '400',
                        lineHeight: '24px',
                        color: 'white'
                    }}>
                        {position}
                    </label>
                </div>
            </div>
        </div>
    );
};

export default GlassmorphMessageBox