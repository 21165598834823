import React from "react";
import {Box, Typography} from "@mui/material";
import {formatDate} from "../../utils/HelperFunctions";

const PartnershipDetails = ({partnership}) => {

    const date = formatDate(partnership.createdAt)

    return (
        <Box style={{
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: '10px',
            paddingLeft: '7.5%',
            paddingRight: '7.5%'
        }}>
            <Typography
                gutterBottom
                variant="h3"
                component="div"
                sx={{fontWeight: '700', color: 'white', textAlign: 'center'}}
            >
                {partnership.title}
            </Typography>
            <Typography
                gutterBottom
                variant="body2"
                color="text.secondary"
                component="div"
                sx={{marginTop: '20px', color: 'whitesmoke', textAlign: 'center'}}
            >
                {date}
            </Typography>
            <Typography
                gutterBottom
                variant="body2"
                color="text.secondary"
                component="div"
                sx={{marginTop: '4px', color: 'grey', textAlign: 'center'}}
            >
                Reading Time: {partnership.readMinutes} mins read
            </Typography>
            <Box style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30px',}}>
                <img
                    src={partnership.pic}
                    alt="Partnership Picture"
                    loading={"lazy"}
                    style={{
                        width: '100%',
                        height: '100%',
                        // maxWidth: '1100px',
                        // maxHeight: '500px',
                        objectFit: 'cover',
                        borderRadius: '10px'
                    }}
                />
            </Box>
            <Typography
                gutterBottom
                color="text.primary"
                sx={{marginTop: '20px', fontSize: '17.5px', color: 'white'}}
            >
                <span dangerouslySetInnerHTML={{__html: partnership.content}}/>
            </Typography>
            <Typography
                gutterBottom
                color="text.primary"
                sx={{marginTop: '10px', color: 'whitesmoke'}}
            >
                Written by
            </Typography>
            <Box style={{display: 'flex', alignItems: 'center'}}>
                <img
                    src={partnership.authorPic}
                    alt="Author Picture"
                    style={{width: '45px', height: '45px', objectFit: 'contain', borderRadius: 100}}
                />
                <Typography
                    gutterBottom
                    color="text.secondary"
                    component="div"
                    sx={{marginLeft: '10px', color: 'white'}}
                >
                    {partnership.author}
                </Typography>
            </Box>
        </Box>
    )
}

export default PartnershipDetails