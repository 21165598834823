import React from "react";
import {Card, CardContent, CardMedia, Typography} from "@mui/material";

const AuthorCard = ({item}) => {

    const {name, image, designation} = item

    return (
        <Card
            className={"glassmorph_style"}
            elevation={4}
            sx={{
                backgroundColor: 'transparent',
                width: '100%',
                height: '100%',
                cursor: 'pointer',
                transition: 'box-shadow 0.3s ease',
                '&:hover': {
                    boxShadow: '0 0 10px rgba(128, 128, 128, 0.8)',
                },
                borderRadius: '8px',
                borderWidth: 0
            }}
        >
            <CardMedia
                sx={{height: '300px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px', objectFit: 'contain'}}
                image={image}
                title="image"
            />
            <CardContent>
                <Typography
                    sx={{
                        color: 'white',
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        fontFamily: "Calibri, sans-serif"
                    }}
                    gutterBottom
                    variant="h6"
                    component="div"
                >
                    {name}
                </Typography>
                <Typography
                    gutterBottom
                    color="text.secondary"
                    component="div"
                    sx={{marginTop: '20px', color: 'whitesmoke', fontSize: '17px', fontFamily: "Calibri, sans-serif"}}
                >
                    {designation}
                </Typography>
            </CardContent>
        </Card>
    )
}

export default AuthorCard