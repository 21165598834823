import React from "react";
import "./styles.css";
import {useNavigate} from "react-router-dom";
import {createTheme, Grid, Typography} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

const TrialCard = () => {

    const navigate = useNavigate();
    const date = new Date()

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    return (
        <div
            style={{
                marginBottom: isMobile || isBigMobile || isTablet ? '8%' : '3%',
                WebkitTransform: "translate3d(0,0,0)",
                transform: "translate3d(0,0,0)",
            }}
        >
            <Grid container alignItems="center" justifyContent="center">
                <Grid
                    container
                    item
                    xs={10}
                    md={10}
                    lg={6}
                    className="trial-card-background"
                    sx={{p: 5}}
                >
                    <Grid
                        container
                        item
                        xs={12}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography
                            sx={{color: 'black', fontWeight: '700', marginBottom: '20px', textAlign: 'center'}}
                            gutterBottom
                            variant="h4"
                            component="div"
                        >
                            Make a big impact tomorrow, by taking a small step today.
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={6}
                        justifyContent="center"
                        alignItems="center"
                        sx={{pt: 4}}
                    >
                        <div
                            className="trial-card-button-left"
                            onClick={() => window.open(`https://calendly.com/fitmedik_intro/30min?month=${date.getFullYear()}-${date.getMonth() + 1}`, "_blank")}
                        />
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={6}
                        justifyContent="center"
                        alignItems="center"
                        sx={{pt: 4}}
                    >
                        <div
                            className="trial-card-button-right"
                            onClick={() => navigate("/partnerships/contact-us")}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default TrialCard