import React, {useEffect, useState} from "react";
import axios from "axios";
import {BASE_API_URL} from "../../utils/Constants";
import {Box, createTheme, LinearProgress, Typography} from "@mui/material";
import PartnershipCard from "../../components/PartnershipCard/PartnershipCard";
import Footer from "../../components/Footer/Footer";
import useMediaQuery from "@mui/material/useMediaQuery";
import Colors from "../../utils/Colors";
import "./styles.css";
import {Helmet} from "react-helmet";
import TrialCard from "../../components/TrialCard/TrialCard";
import Navbar from "../../components/Navbar/Navbar";

const AllPartnerships = () => {

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    let cardsPerRow = 1;

    if (isMobile) {
        cardsPerRow = 1
    } else if (isBigMobile) {
        cardsPerRow = 1
    } else if (isTablet) {
        cardsPerRow = 2
    } else if (isLaptop) {
        cardsPerRow = 3
    } else if (isDesktop) {
        cardsPerRow = 4
    }

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const getPartnerships = async () => {
        setLoading(true)
        try {
            await axios.get(`${BASE_API_URL}/partnerships/getPartnerships`)
                .then((response) => setData(response.data.partnerships))
                .catch((e) => console.error(e))
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getPartnerships()
    }, [])

    if (loading) {
        return <LinearProgress style={{marginLeft: '10%', marginRight: '10%', marginTop: '4%'}}/>;
    }

    const sortedPartnerships = data.sort((a, b) => b.createdAt.localeCompare(a.createdAt))

    return (
        <>
            <Navbar/>
            <div
                className={"db-main-bg"}
                style={{backgroundColor: 'white', display: 'flex', flexDirection: 'column', minHeight: '100vh'}}
            >
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>All Partnerships</title>
                    <meta name="description" content={"All Partnerships"}/>
                </Helmet>
                {sortedPartnerships.length > 0 ? (
                    <Box
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexWrap: 'wrap',
                            marginTop: isMobile || isBigMobile || isTablet ? '18%' : '8%',
                            marginBottom: '50px',
                            justifyContent: 'center',
                            minHeight: '100vh',
                            padding: isMobile || isBigMobile || isTablet ? '2%' : '0%'
                        }}
                        gap={1.2}
                    >
                        {sortedPartnerships.map((item, index) => (
                            <Box
                                key={index}
                                width={isMobile || isBigMobile || isTablet ? '100%' : `calc(100% / ${cardsPerRow + 1})`}
                                maxWidth={isMobile || isBigMobile || isTablet ? '100%' : `calc(100% / ${cardsPerRow + 1})`}
                                maxHeight={'500px'}
                                flexShrink={0}
                            >
                                <PartnershipCard item={item} borderRadius={'10px'}/>
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <div style={{margin: '8px', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography sx={{color: Colors.YELLOW}} variant="subtitle1">No partnerships found.</Typography>
                    </div>
                )}
                <div style={{paddingBottom: isMobile || isBigMobile || isTablet ? '70%' : '10%'}}/>
                <TrialCard/>
            </div>
            <Footer/>
        </>
    )
}

export default AllPartnerships