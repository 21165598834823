import React, {useEffect, useState} from "react";
import axios from "axios";
import {BASE_API_URL} from "../../utils/Constants";
import {Box, createTheme, LinearProgress, Typography} from "@mui/material";
import TagsFilter from "../../components/TagsFilter/TagsFilter";
import EditorialCard from "../../components/EditorialCard/EditorialCard";
import Footer from "../../components/Footer/Footer";
import useMediaQuery from "@mui/material/useMediaQuery";
import Colors from "../../utils/Colors";
import "./styles.css";
import {Helmet} from "react-helmet";
import NavBar from "../../../components/NavBar";

const AllDailyFeedPage = () => {

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    let cardsPerRow = 1;

    if (isMobile) {
        cardsPerRow = 1
    } else if (isBigMobile) {
        cardsPerRow = 1
    } else if (isTablet) {
        cardsPerRow = 2
    } else if (isLaptop) {
        cardsPerRow = 5
    } else if (isDesktop) {
        cardsPerRow = 7
    }

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const getEditorials = async () => {
        setLoading(true)
        try {
            await axios.get(`${BASE_API_URL}/editorials/getEditorials`)
                .then((response) => setData(response.data.editorials))
                .catch((e) => console.error(e))
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getEditorials()
    }, [])

    if (loading) {
        return <LinearProgress/>;
    }

    const currentDate = new Date()
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(currentDate.getDate() - 14)
    let filteredEditorials = data.filter(
        (editorial) => {
            const editorialDate = Date.parse(editorial.createdAt)
            return editorialDate >= twoWeeksAgo.getTime() || editorial.dailyFeed === true
        }
    )
    filteredEditorials = filteredEditorials.sort((a, b) => b.createdAt.localeCompare(a.createdAt))

    return (
        <>
            <NavBar/>
            <div
                className={"db-main-bg"}
                style={{
                    backgroundColor: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    paddingLeft: '1%',
                    paddingRight: '1%'
                }}
            >
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Daily Feed</title>
                    <meta name="description" content={"Daily Feed"}/>
                </Helmet>
                {filteredEditorials.length > 0 ? (
                    <Box
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexWrap: 'wrap',
                            marginTop: '50px',
                            marginBottom: '50px',
                            paddingTop: isLaptop || isDesktop ? '2%' : '6%',
                            justifyContent: 'center',
                            paddingLeft: isMobile || isBigMobile || isTablet ? '2%' : '0%',
                            paddingRight: isMobile || isBigMobile || isTablet ? '2%' : '0%',
                            minHeight: '100vh'
                        }}
                        gap={1}
                    >
                        {filteredEditorials.map((item, index) => (
                            <Box
                                key={index}
                                width={isMobile || isBigMobile || isTablet ? '100%' : `calc(100% / ${cardsPerRow + 1})`}
                                maxWidth={isMobile || isBigMobile || isTablet ? '100%' : `calc(100% / ${cardsPerRow + 1})`}
                                maxHeight={'400px'}
                                flexShrink={0}
                            >
                                <EditorialCard item={item} borderRadius={'10px'}/>
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <div style={{margin: '8px', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography
                            sx={{color: Colors.YELLOW, fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif"}}
                            variant="subtitle1">No editorials found.</Typography>
                    </div>
                )}
            </div>
            <Footer/>
        </>
    )
}

export default AllDailyFeedPage