import React from "react";
import {Grid} from "@mui/material";
import "./glassmorph_styles.css";
import Fade from "react-reveal/Fade";

const Glassmorph = ({logo, title1, title2, content}) => {
    return (
        <Grid container item xs={12} md={6} lg={3}>
            <Fade>
                <div className="glassmorph_style" style={{padding: 20}}>
                    <Grid item xs={12}>
                        <img src={logo} alt={title1}/>
                    </Grid>
                    <Grid item xs={12} sx={{mt: 2}}>
                        <label className="glassmorph_title">{title1}</label>
                    </Grid>
                    <Grid item xs={12}>
                        <label className="glassmorph_title">{title2}</label>
                    </Grid>
                    <Grid item xs={12} sx={{mt: 1}}>
                        <label className="glassmorph_content">{content}</label>
                    </Grid>
                </div>
            </Fade>
        </Grid>
    );
};

export default Glassmorph;
