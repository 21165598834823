import React from "react";
import {createTheme, Grid, Typography, useMediaQuery} from "@mui/material";
import DashboardFooter from "../Dashboard/Footer";
import "./styles.css";
import * as colors from "../../utils/colors";
import NavigationBar from "../../components/NavBar";
import {GLOBAL_TOP_MARGIN} from "../../utils/ui-constants";
import {Helmet} from "react-helmet";
import {metaData} from "../../utils/meta-constants";

const PrivacyPolicy = () => {

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    return (
        <div style={{backgroundColor: colors.MAIN_BACKGROUND, zIndex: 1}} className="pnf-container">
            {/*<div className="pnf-ellipse-1 blur300"/>*/}
            {/*<div className="pnf-ellipse-2 blur300"/>*/}
            <NavigationBar/>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{metaData.privacyPolicy.title}</title>
                <meta name="description" content={metaData.privacyPolicy.description}/>
            </Helmet>
            <Grid container>
                <Grid container style={{
                    paddingTop: GLOBAL_TOP_MARGIN,
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: isLaptop || isDesktop ? '12%' : '8%',
                    paddingRight: isLaptop || isDesktop ? '12%' : '8%',
                    paddingBottom: isLaptop || isDesktop ? '10%' : '18%',
                    minHeight: '100vh'
                }}>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{
                            fontWeight: '700',
                            color: 'white',
                            textAlign: 'center',
                            marginTop: isLaptop || isDesktop ? '5%' : '15%',
                            fontSize: '36px'
                        }}
                    >
                        Privacy Policy
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '2%', fontSize: '18px'}}
                    >
                        Effective Date: 8th July 2023

                        This Privacy Policy ("Policy") explains how Fitmedik, a Delaware C Corp Company ("Fitmedik,"
                        "we,"
                        "us," or "our") collects, uses, and protects the personal information of users ("Users" or
                        "you")
                        who access and use our mobile application ("App"). We are committed to safeguarding your privacy
                        and
                        ensuring the security of your personal information. By using our App, you consent to the
                        practices
                        described in this Policy.
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '2%', fontSize: '18px', fontWeight: '600'}}
                    >
                        Collection of Personal Information
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '1%', fontSize: '18px'}}
                    >
                        We may collect various types of personal information from you when you sign up for our App. This
                        may
                        include but is not limited to:
                        <br/>
                        • Demographic information: We may collect information such as your name, age, gender, and
                        contact
                        details to create your user profile and provide personalized services.
                        <br/>
                        • GPS and Location Information: With your consent, we may collect GPS and location data to
                        monitor
                        work-life balance, track physical activity, and provide relevant insights and recommendations.
                        <br/>
                        • Health and Fitness Data: We may collect data related to sleep patterns, steps taken, fatigue
                        levels,
                        and other health and fitness-related information to offer personalized features and promote your
                        well-being.
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '2%', fontSize: '18px', fontWeight: '600'}}
                    >
                        Use of Personal Information
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '1%', fontSize: '18px'}}
                    >
                        We may collect various types of personal information from you when you sign up for our App. This
                        may
                        include but is not limited to:
                        <br/>
                        • Demographic information: We may collect information such as your name, age, gender, and
                        contact
                        details to create your user profile and provide personalized services.
                        <br/>
                        • GPS and Location Information: With your consent, we may collect GPS and location data to
                        monitor
                        work-life balance, track physical activity, and provide relevant insights and recommendations.
                        <br/>
                        • Health and Fitness Data: We may collect data related to sleep patterns, steps taken, fatigue
                        levels,
                        and other health and fitness-related information to offer personalized features and promote your
                        well-being.
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '2%', fontSize: '18px', fontWeight: '600'}}
                    >
                        Data Security and Encryption
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '1%', fontSize: '18px'}}
                    >
                        We take the security of your personal information seriously. We implement appropriate technical
                        and
                        organizational measures to protect your data from unauthorized access, disclosure, alteration,
                        and
                        destruction. All personal information collected through our App is encrypted using
                        industry-standard
                        encryption protocols to ensure its confidentiality.
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '2%', fontSize: '18px', fontWeight: '600'}}
                    >
                        Sharing of Personal Information
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '1%', fontSize: '18px'}}
                    >
                        We do not sell, rent, or trade your personal information to third parties. However, we may share
                        your information in the following circumstances:
                        <br/>
                        • With your consent: We may share your personal information when you explicitly authorize us to
                        do
                        so.
                        <br/>
                        • Service Providers: We may engage trusted third-party service providers to assist us in
                        delivering
                        our services. These providers have access to personal information only to the extent necessary
                        to
                        perform their functions and are contractually obligated to maintain its confidentiality.
                        <br/>
                        • Legal Compliance: We may disclose your personal information if required by law or to protect
                        our
                        rights, property, or safety, or that of others.
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '2%', fontSize: '18px', fontWeight: '600'}}
                    >
                        Your Rights and Choices
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '1%', fontSize: '18px'}}
                    >
                        You have certain rights regarding your personal information, including:
                        <br/>
                        • Access and Correction: You have the right to access and update your personal information
                        within
                        the App.
                        <br/>
                        • Opt-Out: You may choose to opt out of certain data collection and processing activities,
                        subject
                        to the functionality and services available within the App.
                        <br/>
                        • Data Retention: We retain your personal information for as long as necessary to fulfill the
                        purposes outlined in this Policy, unless a longer retention period is required or permitted by
                        law.
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '2%', fontSize: '18px', fontWeight: '600'}}
                    >
                        Children's Privacy
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '1%', fontSize: '18px'}}
                    >
                        Our App is not intended for use by individuals under the age of 18. We do not knowingly collect
                        personal information from children. If we become aware that we have collected personal
                        information
                        from a child without appropriate consent, we will take steps to delete such information.
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '2%', fontSize: '18px', fontWeight: '600'}}
                    >
                        Changes to this Policy
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '1%', fontSize: '18px'}}
                    >
                        We reserve the right to modify or update this Policy from time to time. We will notify you of
                        any
                        material changes by posting the revised Policy within the App or by other means of
                        communication. We
                        encourage you to review this Policy periodically to stay informed about how we collect, use, and
                        protect your personal information.
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '2%', fontSize: '18px', fontWeight: '600'}}
                    >
                        Contact Us
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '1%', fontSize: '18px'}}
                    >
                        If you have any questions about our Privacy Policy or how we handle your personal information,
                        please contact us at kamal@fitmedik.com
                        <br/>
                        By using our App, you acknowledge that you have read and understood this Privacy Policy and
                        consent
                        to the collection, use, and disclosure of your personal information as described herein.
                    </Typography>
                </Grid>
                <DashboardFooter/>
            </Grid>
        </div>
    )
}

export default PrivacyPolicy