import React from "react";
import "./styles.css";
import * as routes from "../../../utils/ui-routes";
import {useNavigate} from "react-router-dom";

const Fourth = () => {

    const navigate = useNavigate()

    return (
        <div style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#44292B',
            paddingTop: '5%',
            paddingBottom: '5%'
        }}>
            <label style={{textAlign: 'center'}} className="cbf_placard_heading">
                Ready to Unlock Excellence?
                <br/>
                Book a call now
            </label>
            <br/>
            <div
                className="cbf_fourth_btn"
                onClick={() => navigate(routes.ROUTE_CONTACT_US)}
            />
        </div>
    )
}

export default Fourth