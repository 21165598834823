import React, {useState} from "react";
import {Box, createTheme, Divider, IconButton, Link} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import Colors from "../../utils/Colors";
import logo from "../../assets/logo.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import {MenuSharp} from "@mui/icons-material";
import "./styles.css"

const TagsFilter = () => {

    const allTags = [
        'Digital Health',
        'Human Resources',
        'Quality and Safety',
        'Innovation and Strategy'
    ];

    const location = useLocation()
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const toggleMenu = () => {
        setOpen(!open);
    };

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    const handleTagClick = (tag) => {
        navigate(`/carechronicles/tagged/${tag}`);
    };

    return (
        <div style={{paddingLeft: '4%', paddingRight: '4%', paddingTop: isLaptop || isDesktop ? '1.5%' : '4%'}}>
            {isMobile || isBigMobile || isTablet ? (
                <div>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection: 'horizontal'
                    }}>
                        <a href="/" onClick={(e) => {
                            e.preventDefault();
                            window.location.href = "/carechronicles";
                        }}>
                            <img
                                style={{height: '50px', width: '220px'}}
                                src={logo}
                                alt="Logo"
                            />
                        </a>
                        <IconButton sx={{color: 'white'}} onClick={() => toggleMenu()}>
                            <MenuSharp sx={{height: '28px', width: '28px'}} fontSize={"medium"}/>
                        </IconButton>
                    </Box>
                    {open && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                overflowX: 'auto',
                                padding: '10px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '20px'
                            }}
                        >
                            {allTags.map((tag, index) => {
                                const formattedPath = decodeURIComponent(location.pathname);
                                const isSelected = formattedPath.includes(tag)

                                return (
                                    <Link
                                        className={'tag-link'}
                                        key={index}
                                        component="button"
                                        variant="body1"
                                        color={isSelected ? Colors.PRIMARY_ORANGE : 'white'}
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: '600',
                                            fontFamily: "Calibri, sans-serif"
                                        }}
                                        underline="none"
                                        onClick={() => handleTagClick(tag)}
                                    >
                                        {tag}
                                    </Link>
                                )
                            })}
                        </Box>
                    )}
                </div>
            ) : (
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'horizontal'
                }}>
                    <a href="/" onClick={(e) => {
                        e.preventDefault();
                        window.location.href = "/carechronicles";
                    }}>
                        <img
                            style={{height: '50px', width: '220px'}}
                            src={logo}
                            alt="Logo"
                        />
                    </a>
                    <Box
                        sx={{
                            display: 'flex',
                            overflowX: 'auto',
                            padding: '10px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '20px'
                        }}
                    >
                        {allTags.map((tag, index) => {
                            const formattedPath = decodeURIComponent(location.pathname);
                            const isSelected = formattedPath.includes(tag)

                            return (
                                <Link
                                    className={'tag-link'}
                                    key={index}
                                    component="button"
                                    variant="body1"
                                    color={isSelected ? Colors.PRIMARY_ORANGE : 'white'}
                                    sx={{
                                        fontSize: 22,
                                        fontWeight: '600',
                                        fontFamily: "Calibri, sans-serif"
                                    }}
                                    underline="none"
                                    onClick={() => handleTagClick(tag)}
                                >
                                    {tag}
                                </Link>
                            )
                        })}
                    </Box>
                </Box>
            )}
            <Divider sx={{backgroundColor: '#8c8c8c', height: 2, borderRadius: 100, marginTop: '20px'}}/>
        </div>
    )
}

export default TagsFilter