import {createTheme, Grid, useMediaQuery} from "@mui/material";
import React from "react";
import "./trial_card_styles.css";
import {navigateToExternal} from "../../utils/helper-functions";
import * as routes from "../../utils/ui-routes";
import {useNavigate} from "react-router-dom";

const TrialCard = () => {
    const ref = React.useRef(null);
    const [height, setHeight] = React.useState(0);

    React.useLayoutEffect(() => {
        if (ref.current) {
            setHeight(ref.current.clientHeight);
        }
    }, []);

    const navigate = useNavigate();

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    return (
        <div
            ref={ref}
            style={{
                marginTop: -height / 4,
                zIndex: 99,
                WebkitTransform: "translate3d(0,0,0)",
                transform: "translate3d(0,0,0)",
            }}
        >
            <Grid container alignItems="center" justifyContent="center">
                <Grid
                    container
                    item
                    xs={10}
                    md={10}
                    lg={6}
                    className="trial-card-background"
                    sx={{p: 5}}
                >
                    <Grid
                        container
                        item
                        xs={12}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <label className="trial-card-header">
                            Make a big impact tomorrow, by taking a small step today.
                        </label>
                    </Grid>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: isLaptop || isDesktop ? 'row' : 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            paddingTop: '20px'
                        }}>
                        <div
                            className="tc-btn-left"
                            onClick={() => {
                                navigateToExternal(routes.ROUTE_BOOK_A_DEMO)
                                window.scrollTo(0, 0)
                            }}
                        />
                        <div style={{width: isLaptop || isDesktop ? '16px' : 0, height: isLaptop || isDesktop ? 0 : '16px'}}/>
                        <div
                            className="tc-btn-right"
                            onClick={() => {
                                navigate(routes.ROUTE_HOW_IT_WORKS)
                                window.scrollTo(0, 0)
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default TrialCard;
