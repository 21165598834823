import React from "react";
import { Grid } from "@mui/material";
import "../../../components/GlassmorphBG";
import "./pricing_second_styles.css";
import Correct from "../../../assets/images/pricing/correct.svg";
import Wrong from "../../../assets/images/pricing/wrong.svg";
import * as routes from "../../../utils/ui-routes";
import * as colors from "../../../utils/colors";
import { useNavigate } from "react-router-dom";

const PricingSecondHeader = ({ header }) => {
  const navigate = useNavigate();
  return (
    <div>
      <Grid item xs={12}>
        <label className="pricing-second-info-text-medium">{header}</label>
      </Grid>
      {/* <Grid item xs={12}>
      <label className="pricing-second-info-text-sp">{amount}</label>
    </Grid> */}
      <Grid container item xs={12} sx={{ mt: 3 }}>
        <div
          className="pricing-second-try-for-free-button center-fit"
          onClick={() => navigate(routes.ROUTE_GET_FREE_TRIAL)}
        />
      </Grid>
    </div>
  );
};

const Divider = ({ mt }) => (
  <Grid container sx={{ mt }}>
    <Grid item xs={1} />
    <Grid item xs={10}>
      <div className="pricing-second-divider" />
    </Grid>
    <Grid item xs={1} />
  </Grid>
);

const DataRow = ({
  title = "",
  isNum = false,
  data = { first: "", second: "", third: "" },
  correct = { first: false, second: false, third: false },
}) => (
  <Grid container sx={{ mt: 2.5 }}>
    <Grid item xs={1} />
    <Grid container item xs={10}>
      <Grid item xs={4.5}>
        <label className="pricing-second-data-text-small">{title}</label>
      </Grid>
      <Grid item xs={2.5}>
        {isNum === true ? (
          <label className="pricing-second-data-text-small">{data.first}</label>
        ) : (
          <img
            src={correct.first === true ? Correct : Wrong}
            alt="data"
            className="center-fit"
          />
        )}
      </Grid>
      <Grid item xs={2.5}>
        {isNum === true ? (
          <label className="pricing-second-data-text-small">
            {data.second}
          </label>
        ) : (
          <img
            src={correct.second === true ? Correct : Wrong}
            alt="data"
            className="center-fit"
          />
        )}
      </Grid>
      <Grid item xs={2.5}>
        {isNum === true ? (
          <label className="pricing-second-data-text-small">{data.third}</label>
        ) : (
          <img
            src={correct.third === true ? Correct : Wrong}
            alt="data"
            className="center-fit"
          />
        )}
      </Grid>
    </Grid>
    <Grid item xs={1} />
  </Grid>
);

const PricingSecond = () => {
  return (
    <div
      style={{
        backgroundColor: colors.SECONDARY_BACKGROUND_COLOUR,
        zIndex: 1,
        width: "100vw",
      }}
    >
      <Grid container>
        <Grid container sx={{ mt: 17 }}>
          <Grid item xs={1} />
          <Grid container item xs={10}>
            <Grid item xs={4.5}>
              <label className="pricing-second-info-text">Compare Plans</label>
            </Grid>
            <Grid container item xs={2.5}>
              <PricingSecondHeader header={"Trial"} />
            </Grid>
            <Grid container item xs={2.5}>
              <PricingSecondHeader header={"Starter"} />
            </Grid>
            <Grid container item xs={2.5}>
              <PricingSecondHeader header={"Enterprise"} />
            </Grid>
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Divider mt={6} />
        <DataRow
          title="Number of Users/Providers"
          isNum={true}
          data={{ first: "upto 200", second: "200-400", third: "400+" }}
        />
        <Divider mt={2.5} />
        <DataRow
          title="Access to Web Dashboard"
          isNum={false}
          correct={{ first: true, second: true, third: true }}
        />
        <Divider mt={2.5} />
        <DataRow
          title="Access to Mobile App"
          isNum={false}
          correct={{ first: true, second: true, third: true }}
        />
        <Divider mt={2.5} />
        <DataRow
          title="Access to Care Pathways"
          isNum={false}
          correct={{ first: true, second: true, third: true }}
        />
        <Divider mt={2.5} />
        <DataRow
          title="Multiple accounts for management"
          isNum={false}
          correct={{ first: false, second: true, third: true }}
        />
        <Divider mt={2.5} />
        <DataRow
          title="24*7 Customer Support"
          isNum={false}
          correct={{ first: false, second: true, third: true }}
        />
        <Divider mt={2.5} />
        <DataRow
          title="Monthly/Quarterly Reports"
          isNum={false}
          correct={{ first: false, second: false, third: true }}
        />
        <Divider mt={2.5} />
        <DataRow
          title="Consultation on Workflows"
          isNum={false}
          correct={{ first: false, second: false, third: true }}
        />
        <Divider mt={2.5} />
        <Grid container sx={{ mb: 26 }} />
      </Grid>
    </div>
  );
};

export default PricingSecond;
