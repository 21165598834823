import {createTheme, Grid, useMediaQuery} from "@mui/material";
import React from "react";
import "./db_second_styles.css";
import "../../../components/GlassmorphBG/glassmorph_styles.css";
import {infoCards} from "./infoCards";
import {BROWSER_DIMENSIONS_STORE} from "../../../state/BROWSER_DIMENSIONS";
import {observer} from "mobx-react";
import {LARGE_SIZE} from "../../../utils/ui-constants";
import Glassmorph from "../../../components/GlassmorphBG";
import DashboardDigitalTwin from "../../../components/DashboardDigitalTwin";
import Slider from "../../../components/LogoSlider";
import Fade from "react-reveal/Fade";
import * as routes from "../../../utils/ui-routes";
import {useNavigate} from "react-router-dom";

const DashboardSecond = observer(() => {

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    const navigate = useNavigate()

    return (
        <div
            style={{
                backgroundColor: '#131519',
                zIndex: 1,
                width: "100%",
            }}
        >
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="ellipse_second_4 blur200"/>
            )}
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="ellipse_second_5 blur200"/>
            )}
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="ellipse_second_7 blur200"/>
            )}
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="ellipse_second_8 blur200"/>
            )}

            <Fade>
                <Slider/>
            </Fade>

            <DashboardDigitalTwin/>

            <br></br>
            <br></br>

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '8%',
                paddingRight: '8%',
            }}>
                <Fade>
                    <label className="db_main_heading">
                        Copilot your wellbeing & engagement efforts with the power of
                        <span className="db_main_heading db_main_heading_highlight_orange">
                            {" "}Generative AI.
                        </span>
                    </label>
                    <br/>
                </Fade>
                <br/>
                <br/>
                <Fade big>
                    <img
                        src={require('../../../assets/gifs/dashboard/AI Copilot.gif')}
                        width={'100%'}
                        height={'100%'}
                        alt={'img_1'}
                    />
                </Fade>
                <br/>
                <br/>
                <div style={{
                    width: '50vw',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <div
                        style={{marginTop: '2%'}}
                        className="db-second-btn"
                        onClick={() => {
                            navigate(routes.ROUTE_HOW_IT_WORKS)
                            window.scroll(0, 0)
                        }}
                    />
                </div>
            </div>

            <Grid container className="db-second-bg">
                <Grid container sx={{mt: 10}}>
                    <Grid item xs={1}/>
                    <Grid container item xs={10} rowSpacing={2}>
                        <Grid container item xs={12}>
                            <Fade>
                                <div className="glassmorph_chip">
                                    <label className="glassmorph_chip_text">
                                        Why Fitmedik
                                    </label>
                                </div>
                            </Fade>
                        </Grid>
                        <Grid item xs={12} lg={8}>
                            <Fade>
                                <label className="db_main_heading">
                                    Become
                                    <span className="db_main_heading db_main_heading_highlight_orange">
                                        {" "}Provider{" "}
                                    </span>
                                    of the choice by,
                                    <br/>
                                    Becoming
                                    <span className="db_main_heading db_main_heading_highlight_orange">
                                        {" "}Employer{" "}
                                    </span>
                                    of the choice.
                                </label>
                            </Fade>
                        </Grid>
                    </Grid>
                    <Grid item xs={1}/>
                </Grid>

                <Grid container sx={{mt: 8}}>
                    <Grid item xs={1}/>
                    <Grid container item xs={10} spacing={3}>
                        {infoCards.map((val, index) => (
                            <Glassmorph
                                logo={val.logo}
                                title1={val.title1}
                                title2={val.title2}
                                content={val.content}
                                key={index}
                            />
                        ))}
                    </Grid>
                    <Grid item xs={1}/>
                </Grid>

                {/*<Grid*/}
                {/*    container*/}
                {/*    alignItems="center"*/}
                {/*    justifyContent="center"*/}
                {/*    sx={{mt: 20}}*/}
                {/*>*/}
                {/*    <Grid item xs={1}/>*/}
                {/*    <Grid container item xs={10} rowSpacing={2}>*/}
                {/*        <Grid container item xs={12}>*/}
                {/*            <div className="glassmorph_chip">*/}
                {/*                <label className="glassmorph_chip_text">*/}
                {/*                    Web Dashboard for hospital Management*/}
                {/*                </label>*/}
                {/*            </div>*/}
                {/*        </Grid>*/}

                {/*        <Grid item xs={12} lg={7}>*/}
                {/*            <label className="info-text">*/}
                {/*                One stop solution to monitor and manage burnout for your healthcare facility,*/}
                {/*                <span className="info-text info-text-highlight">*/}
                {/*  {" "}*/}
                {/*                    passively, in real time.*/}
                {/*</span>*/}
                {/*                <br></br>*/}
                {/*                <br></br>*/}
                {/*            </label>*/}
                {/*        </Grid>*/}
                {/*    </Grid>*/}
                {/*    <Grid item className="image-container"*/}
                {/*          style={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}>*/}
                {/*        <img*/}
                {/*            style={{*/}
                {/*                width: isMobile || isBigMobile || isTablet ? '90%' : '100%',*/}
                {/*            }}*/}
                {/*            src={Website} alt="My Image"/>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}

                {/*<Grid container>*/}
                {/*    <Grid item xs={1}/>*/}
                {/*    <Grid*/}
                {/*        item*/}
                {/*        xs={10}*/}
                {/*        container*/}
                {/*        sx={{mt: 8}}*/}
                {/*        className="glassmorph_style"*/}
                {/*    >*/}
                {/*        <GlassmorphLargeFull*/}
                {/*            logo={Info1}*/}
                {/*            title="Continuously monitor burnout risk levels of your health system"*/}
                {/*            content="Determine your organization's burnout risk level by identifying  healthcare provider's risk profile in real-time."*/}
                {/*            href={routes.ROUTE_GET_FREE_TRIAL}*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={1}/>*/}
                {/*</Grid>*/}

                {/*<Grid container sx={{mt: 4}}>*/}
                {/*    <Grid item xs={1}/>*/}
                {/*    <Grid container item xs={10} spacing={4}>*/}
                {/*        <GlassmorphLargeHalf*/}
                {/*            logo={Info2}*/}
                {/*            title="Understand how burnout impacts your operational revenue"*/}
                {/*            content="Analyze cost-saving patterns that result from your management activities, and take right step forward."*/}
                {/*            href={routes.ROUTE_GET_FREE_TRIAL}*/}
                {/*        />*/}
                {/*        <GlassmorphLargeHalf*/}
                {/*            logo={Info3}*/}
                {/*            title="Recognize burnout pattern of each department"*/}
                {/*            content="Know the areas where burnout has the biggest impact and what causes it."*/}
                {/*            href={routes.ROUTE_GET_FREE_TRIAL}*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={1}/>*/}
                {/*</Grid>*/}

                {/*/!* PART 2 *!/*/}
                {/*<Grid container>*/}
                {/*    <Grid item xs={1}/>*/}
                {/*    <Grid*/}
                {/*        item*/}
                {/*        xs={10}*/}
                {/*        container*/}
                {/*        sx={{mt: 4}}*/}
                {/*        className="glassmorph_style"*/}
                {/*    >*/}
                {/*        <GlassmorphLargeFull*/}
                {/*            logo={Info4}*/}
                {/*            title="Assess how burnout effects socio-demographics of your system"*/}
                {/*            content="Determine which groups of providers are more vulnerable to the impact of burnout."*/}
                {/*            href={routes.ROUTE_GET_FREE_TRIAL}*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={1}/>*/}
                {/*</Grid>*/}

                {/*<Grid container sx={{mt: 4}}>*/}
                {/*    <Grid item xs={1}/>*/}
                {/*    <Grid container item xs={10} spacing={4}>*/}
                {/*        <GlassmorphLargeHalf*/}
                {/*            logo={Info5}*/}
                {/*            title="Learn how your proactive actions affects burnout"*/}
                {/*            content="Take data driven actions against burnout and learn their effectiveness. "*/}
                {/*            href={routes.ROUTE_GET_FREE_TRIAL}*/}
                {/*        />*/}
                {/*        <GlassmorphLargeHalf*/}
                {/*            logo={Info6}*/}
                {/*            title="Provide care pathways to your providers to counter burnout"*/}
                {/*            content="Provide various effective treatment options to your providers and treat burnout on individual level."*/}
                {/*            href={routes.ROUTE_GET_FREE_TRIAL}*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={1}/>*/}
                {/*</Grid>*/}

                <Grid container sx={{height: 160}}/>
            </Grid>
        </div>
    );
});

export default DashboardSecond;
