import React, {useRef, useState} from "react";
import {Alert, Box, createTheme, Snackbar, TextField, Typography} from "@mui/material";
import "./styles.css";
import emailjs from "@emailjs/browser";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";

const Newsletter = () => {

    const formRef = useRef()
    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = React.useState(false)
    const [error, setError] = useState(false)
    const navigate = useNavigate()

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    }

    const sendMail = async (e) => {
        try {
            //console.log(formRef.current)
            await emailjs.sendForm(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EMAILJS_TEMPLATE_ID_SUBSCRIBER,
                formRef.current,
                process.env.REACT_APP_EMAILJS_PUBLIC_KEY
            )
                .then((response) => {
                    console.log(response)
                    setLoading(false);
                    setOpen(true)
                    setEmail("")
                })
                .catch((e) => {
                    console.log(e)
                    setError(true)
                })
        } catch (e) {
            setError(true)
        } finally {
            setLoading(false)
        }
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Typography component="div" sx={{
                    fontWeight: '500',
                    fontSize: isMobile || isBigMobile ? '20px' : '30px',
                    color: 'white',
                    fontFamily: "Calibri, sans-serif"
                }}>
                    Subscribe to Care Chronicles
                </Typography>
                {/* <form style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '20px',
                    width: isTablet || isLaptop || isDesktop ? '50vw' : '75vw',
                    alignItems: 'center',
                    justifyContent: 'center'
                }} ref={formRef}
                      onSubmit={sendMail}
                > */}
                    {/* <TextField
                        name={"subscriber_email"}
                        style={{width: '100%', backgroundColor: 'whitesmoke', marginBottom: '20px'}}
                        label="Enter your email"
                        variant="outlined"
                        size="small"
                        value={email}
                        onChange={(event) => {
                            setEmail(event.target.value)
                        }}
                        InputProps={{
                            placeholder: 'Enter email',
                            inputProps: {
                                style: {
                                    color: 'black',
                                },
                            },
                        }}
                    /> */}
                    <div className={"cc_btn_subscribe_orange"} onClick={() => navigate("/newsletter")}/>
                {/* </form> */}
            </Box>
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                {!error ? (
                    <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                        Thank you for subscribing!
                    </Alert>
                ) : (
                    <Alert onClose={handleClose} severity="error" sx={{width: '100%'}}>
                        Cannot process your request
                    </Alert>
                )}
            </Snackbar>
        </>
    )
}

export default Newsletter