import React, {useEffect, useState} from "react";
import axios from "axios";
import {BASE_API_URL} from "../../utils/Constants";
import {Card, CardContent, createTheme, Divider, LinearProgress, Typography} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import useMediaQuery from "@mui/material/useMediaQuery";

const BreakingNews = () => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    const getEditorials = async () => {
        setLoading(true)
        try {
            console.log(`${BASE_API_URL}/editorials/getEditorials`)
            await axios.get(`${BASE_API_URL}/editorials/getEditorials`)
                .then((response) => setData(response.data.editorials))
                .catch((e) => console.error(e))
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getEditorials()
    }, [])

    if (loading) {
        return <LinearProgress/>;
    }

    let filteredData = data.filter((editorial) => editorial.breakingNews === true)
    filteredData = filteredData.sort((a, b) => b.createdAt.localeCompare(a.createdAt))

    return (
        <div>
            <Typography component="div" sx={{
                fontWeight: '700',
                fontSize: '40px',
                color: 'white',
                fontFamily: "Calibri, sans-serif"
            }}>
                Latest Articles
            </Typography>
            <Divider sx={{backgroundColor: '#8c8c8c', height: 2, marginTop: '10px', marginBottom: '20px'}}/>
            {filteredData.length > 0 && (
                <Carousel
                    autoPlay={true}
                    animation="slide"
                    sx={{height: isMobile || isBigMobile ? '250px' : '600px', backgroundColor: 'transparent'}}
                >
                    {filteredData.map((editorial, index) => (
                        <Card
                            key={index}
                            sx={{
                                cursor: 'pointer',
                                transition: 'box-shadow 0.3s ease',
                                '&:hover': {
                                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
                                }
                            }}
                            onClick={() => window.open(`/carechronicles/open-editorial?id=${editorial._id}`, '_self')}
                        >
                            <img
                                src={editorial.pic}
                                alt="picture"
                                loading={"lazy"}
                                style={{
                                    width: '100%',
                                    height: isMobile || isBigMobile ? '190px' : '550px',
                                    borderRadius: '4px',
                                    backgroundColor: 'transparent',
                                    objectFit: isMobile || isBigMobile ? 'contain' : 'cover'
                                }}
                            />
                            {isTablet || isLaptop || isDesktop ? (
                                <CardContent
                                    style={{
                                        position: 'absolute',
                                        bottom: '0',
                                        background: 'rgba(0, 0, 0, 0.4)',
                                        marginBottom: '5px',
                                        borderRadius: 8
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: 'white',
                                            display: '-webkit-box',
                                            overflow: 'hidden',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 2
                                        }}
                                    >
                                        {editorial.title}
                                    </Typography>
                                </CardContent>
                            ) : null}
                        </Card>
                    ))}
                </Carousel>
            )}
        </div>
    )
}

export default BreakingNews