import React from "react";
import "../glassmorph_styles.css";
import Fade from "react-reveal/Fade";

const GlassmorphNews = ({title, link}) => {
    return (
        <Fade>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    padding: '20px',
                    marginBottom: '25px',
                    cursor: 'pointer'
                }}
                onClick={() => window.open(link, '_self')}
                className="glassmorph_style"
            >
                <label style={{cursor: "pointer"}} className="glassmorph_title">{title}</label>
                <label style={{color: '#FFC255', marginTop: '15px', cursor: "pointer"}} className="glassmorph_content">
                    Read Article
                </label>
            </div>
        </Fade>
    );
};

export default GlassmorphNews;