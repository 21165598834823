import React from "react";
import {observer} from "mobx-react";
import * as colors from "../../../utils/colors";
import {createTheme, useMediaQuery} from "@mui/material";
import {BROWSER_DIMENSIONS_STORE} from "../../../state/BROWSER_DIMENSIONS";
import {LARGE_SIZE} from "../../../utils/ui-constants";
import "./styles.css";
import {useNavigate} from "react-router-dom";
import * as routes from "../../../utils/ui-routes";
import Fade from "react-reveal/Fade";

const First = observer(() => {

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    const navigate = useNavigate()

    return (
        <div style={{
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            paddingLeft: '8%',
            paddingRight: '8%',
            paddingTop: isMobile || isBigMobile || isTablet ? '20%' : '12%',
            backgroundColor: colors.MAIN_BACKGROUND
        }}>
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="ab_ellipse_second_2 blur200"/>
            )}
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="ab_ellipse_second_3 blur200"/>
            )}

            <div style={{
                display: 'flex',
                flexDirection: isMobile || isBigMobile || isTablet ? 'column' : 'row',
                justifyContent: 'space-between'
            }}>
                <Fade>
                    <div style={{display: 'flex', flex: 0.48, flexDirection: 'column'}}>
                        <div className="cbf_placard_glassmorph_chip">
                            <label className="cbf_placard_glassmorph_chip_text">
                                About Us
                            </label>
                        </div>
                        <br/>
                        <label className="ab_heading">
                            Discover Our Story{" "}
                            <br/>
                            <br/>
                            Empowering{" "}
                            <br/>
                            Healthcare Heroes:{" "}
                            <br/>
                            The Fitmedik Journey
                        </label>
                    </div>
                    <div style={{display: 'flex', flex: 0.48, marginTop: '56px'}}>
                        <label className="ab_content_small">
                            Born from the corridors of a leading healthcare company, Fitmedik emerged from the firsthand
                            experiences of our founders. Witnessing the invaluable role healthcare workers play in the
                            health system - our founders recognized a stark reality: despite being the backbone, the
                            work-life of these heroes often remained arduous.{" "}
                            <br/>
                            <br/>
                            In the wake of the pandemic, where over 540,000 healthcare workers left the profession,
                            Fitmedik
                            took shape with a singular vision - to be the catalyst in transforming healthcare facilities
                            into environments where these superheroes are not just retained but truly empowered. Join us
                            on
                            this transformative journey as we redefine the narrative and empower healthcare heroes
                            through
                            Fitmedik.
                        </label>
                    </div>
                </Fade>
            </div>

            <Fade big>
                <div
                    className={'glassmorph_style'}
                    style={{
                        display: 'flex',
                        flexDirection: isMobile || isBigMobile || isTablet ? 'column' : 'row',
                        padding: '40px',
                        justifyContent: 'space-between',
                        width: '100%',
                        height: 'fit-content',
                        marginTop: '12%'
                    }}
                >
                    <div style={{display: 'flex', flexDirection: 'column', flex: 0.5}}>
                        <label className="ab_heading">
                            What Value AI Retention Companion adds?
                        </label>
                        <br/>
                        <label className="ab_content_small">
                            Experience unprecedented value with our AI Retention Companion. It's a transformative force
                            ensuring your healthcare facility not only retains its heroes but provides an environment
                            where
                            they truly flourish.
                        </label>
                        <br/>
                        <br/>
                        <div
                            className="ab_placard_btn"
                            onClick={() => navigate(routes.ROUTE_HOW_IT_WORKS)}
                        />
                    </div>
                    <div style={{
                        display: 'flex',
                        flex: 0.5,
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: isMobile || isBigMobile || isTablet ? '15%' : 0
                    }}>
                        <img
                            height={'100%'}
                            width={'100%'}
                            src={isLaptop || isDesktop ? require('../../../assets/images/about_us/laptop.svg')
                                : require('../../../assets/images/about_us/laptop.png')}
                            alt={'laptop'}
                        />
                    </div>
                </div>
            </Fade>
        </div>
    )
})

export default First