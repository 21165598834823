import React, {useEffect, useState} from "react";
import axios from "axios";
import {BASE_API_URL} from "../../utils/Constants";
import {Box, Card, CardContent, CardMedia, createTheme, LinearProgress, Link, Typography} from "@mui/material";
import Tags from "../Tags/Tags";
import {useNavigate} from "react-router-dom";
import Newsletter from "../Newsletter/Newsletter";
import Colors from "../../utils/Colors";
import "./styles.css";
import useMediaQuery from "@mui/material/useMediaQuery";

const RightBarEditorials = () => {

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const getEditorials = async () => {
        setLoading(true)
        try {
            await axios.get(`${BASE_API_URL}/editorials/getEditorials`)
                .then((response) => setData(response.data.editorials))
                .catch((e) => console.error(e))
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getEditorials()
    }, [])

    if (loading) {
        return <LinearProgress/>;
    }

    const shuffledData = data.sort(() => 0.5 - Math.random())
    const selectedEditorials = shuffledData.slice(0, 3)

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            {selectedEditorials.length > 0 ? (
                selectedEditorials.map((editorial) => (
                    <Card
                        className={"glassmorph_style"}
                        key={editorial._id}
                        elevation={4}
                        sx={{
                            backgroundColor: 'transparent',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '10px',
                            borderRadius: '8px',
                            borderWidth: '0px',
                            cursor: 'pointer',
                            transition: 'box-shadow 0.3s ease',
                        }}
                        onClick={() => window.open(`/carechronicles/open-editorial?id=${editorial._id}`, '_self')}
                    >
                        <CardMedia
                            sx={{height: '140px'}}
                            image={editorial.pic}
                            title="image"
                        />
                        <CardContent>
                            <Typography
                                sx={{
                                    color: 'white',
                                    fontWeight: '500',
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 3,
                                    fontFamily: "Calibri, sans-serif"
                                }}
                                variant="h6"
                            >
                                {editorial.title}
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                gap: '8px',
                                marginTop: '10px'
                            }}>
                                {editorial.tags.map((tag, index) => (
                                    <Tags title={tag}/>
                                ))}
                            </Box>
                        </CardContent>
                    </Card>
                ))
            ) : (
                <Typography sx={{color: Colors.YELLOW}} variant="subtitle1">No editorials found.</Typography>
            )}
            {selectedEditorials.length > 0 && (
                <Box style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    marginTop: '10px'
                }}>
                    <Link
                        component="button"
                        variant="body1"
                        color="#F44336"
                        sx={{fontWeight: '700', fontSize: 13, marginLeft: '8px', marginRight: '8px'}}
                        underline="none"
                        onClick={() => navigate("/carechronicles/allEditorials")}
                    >
                        VIEW MORE POSTS
                    </Link>
                </Box>
            )}
            {/*{isLaptop || isDesktop ? (*/}
            {/*    <Box style={{marginTop: '40px', marginLeft: '24px', marginRight: '10px'}}>*/}
            {/*        <Newsletter/>*/}
            {/*    </Box>*/}
            {/*) : null}*/}
        </div>
    )
}

export default RightBarEditorials