import React, {useContext} from "react";
import {Box, Card, CardContent, CardMedia, Typography} from "@mui/material";
import Tags from "../Tags/Tags";
import {formatDate} from "../../utils/HelperFunctions";
import "./styles.css";

const EditorialCard = ({item, borderRadius}) => {

    const {_id, title, content, pic, author, tags, createdAt} = item

    const getRandomTags = (arr, count) => {
        const shuffled = arr.sort(() => 0.5 - Math.random());
        return shuffled.slice(0, count);
    };

    let randomTags
    if (tags.length === 1) {
        randomTags = tags
    } else {
        randomTags = getRandomTags(tags, 2)
    }

    return (
        <Card
            className={"glassmorph_style"}
            elevation={4}
            sx={{
                backgroundColor: 'transparent',
                width: '100%',
                height: '100%',
                cursor: 'pointer',
                transition: 'box-shadow 0.3s ease',
                '&:hover': {
                    boxShadow: '0 0 10px rgba(128, 128, 128, 0.8)',
                },
                borderRadius: borderRadius,
                borderWidth: 0
            }}
            onClick={() => window.open(`/carechronicles/open-editorial?id=${_id}`, '_self')}
        >
            <CardMedia
                sx={{height: '190px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px', objectFit: 'contain', backgroundColor: '#FFFFFF'}}
                component="img"
                image={pic}
                title="image"
            />
            <CardContent>
                <Typography
                    sx={{
                        color: 'white',
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 3,
                        fontFamily: "Calibri, sans-serif"
                    }}
                    gutterBottom
                    variant="h6"
                    component="div"
                >
                    {title}
                </Typography>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    gap: '8px',
                    paddingTop: '10px',
                    paddingBottom: '40px'
                }}>
                    {tags.map((tag, index) => (
                        <Tags title={tag}/>
                    ))}
                </Box>
                <Typography
                    gutterBottom
                    variant="body2"
                    color="text.secondary"
                    component="div"
                    sx={{
                        position: 'absolute',
                        bottom: '10px',
                        color: 'whitesmoke',
                        fontFamily: "Calibri, sans-serif"
                    }}
                >
                    {formatDate(createdAt)}
                </Typography>
            </CardContent>
        </Card>
    )
}

export default EditorialCard