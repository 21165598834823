import React from "react";
import {observer} from "mobx-react";
import {createTheme, useMediaQuery} from "@mui/material";
import * as colors from "../../../utils/colors";
import "./styles.css";
import {BROWSER_DIMENSIONS_STORE} from "../../../state/BROWSER_DIMENSIONS";
import {LARGE_SIZE} from "../../../utils/ui-constants";
import {navigateToExternal} from "../../../utils/helper-functions";
import * as routes from "../../../utils/ui-routes";
import {useNavigate} from "react-router-dom";
import Fade from "react-reveal/Fade";

const First = observer(() => {

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    const navigate = useNavigate()

    return (
        <div style={{
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100%',
            width: '100%',
            paddingTop: isMobile || isBigMobile || isTablet ? '20%' : '10%',
            paddingBottom: '10%',
            backgroundColor: colors.MAIN_BACKGROUND
        }}>

            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="dtt_ellipse_second_1 blur200"/>
            )}
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="dtt_ellipse_second_2 blur200"/>
            )}
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="dtt_ellipse_second_3 blur200"/>
            )}

            <Fade big>
                <div
                    className={'glassmorph_style'}
                    style={{
                        display: 'flex',
                        width: '84%',
                        flexDirection: isMobile || isBigMobile || isTablet ? 'column' : 'row',
                        padding: isLaptop || isDesktop ? '40px' : '20px',
                        justifyContent: 'space-between',
                    }}
                >
                    <div style={{display: 'flex', flexDirection: 'column', flex: 0.6}}>
                        <div className="cbf_placard_glassmorph_chip">
                            <label className="cbf_placard_glassmorph_chip_text">
                                Digital Twin Technology
                            </label>
                        </div>
                        <br/>
                        <label className="dtt_first_heading">
                            Welcome to the Future of
                            <br/>
                            <span className="dtt_first_heading dtt_first_heading_highlight">
                            {" "}Healthcare Excellence!{" "}
                        </span>
                        </label>
                        <br/>
                        <label className="dtt_first_content_small">
                            Autopilot well being monitoring & management with Generative AI powered Digital Twins of
                            care workers.{" "}
                            <br/>
                            <br/>
                            Say goodbye to outdated surveys and welcome the era of proactive healthcare management.{" "}
                            <br/>
                            <br/>
                            Join us in the leap into the future, where every decision is informed by real-time data,
                            ensuring that your facility remains at the forefront of operational excellence.
                        </label>
                        <br/>
                        <br/>
                        <div style={{
                            display: 'flex',
                            flexDirection: isLaptop || isDesktop ? 'row' : 'column',
                            alignItems: 'center',
                            justifyContent: isMobile || isBigMobile || isTablet ? 'center' : 'flex-start'
                        }}>
                            <div
                                className="dtt_first_btn_orange"
                                onClick={() => navigateToExternal(routes.ROUTE_BOOK_A_DEMO)}
                            />
                            <div
                                style={{
                                    marginLeft: isLaptop || isDesktop ? '30px' : 0,
                                    marginTop: isLaptop || isDesktop ? 0 : '20px'
                                }}
                                className="dtt_first_btn_black"
                                onClick={() => navigate(routes.ROUTE_HOW_IT_WORKS)}
                            />
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        flex: 0.4,
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: isMobile || isBigMobile || isTablet ? '15%' : 0
                    }}>
                        <img
                            style={{height: 'fit-content', width: 'fit-content'}}
                            src={isLaptop || isDesktop ? require('../../../assets/images/digital_twin_tech/right_doctor.svg')
                                : require('../../../assets/images/digital_twin_tech/right_doc.png')}
                            alt={'doctor'}
                        />
                    </div>
                </div>
            </Fade>
        </div>
    )
})

export default First