import React, {useEffect, useState} from "react";
import axios from "axios";
import {BASE_API_URL} from "../../utils/Constants";
import {Box, createTheme, Grid, LinearProgress, Link, Typography} from "@mui/material";
import PartnershipCard from "../PartnershipCard/PartnershipCard";
import {useNavigate} from "react-router-dom";
import "./styles.css";
import Colors from "../../utils/Colors";
import useMediaQuery from "@mui/material/useMediaQuery";

const AllPosts = () => {

    let cardsPerRow = 3;
    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    let padding = '1%';

    if (isMobile) {
        padding = '3%'
    } else if (isBigMobile) {
        padding = '3%'
    } else if (isTablet) {
        padding = '5%'
    } else if (isLaptop) {
        padding = '8%'
    } else if (isDesktop) {
        padding = '20%'
    }

    const getPartnerships = async () => {
        setLoading(true)
        try {
            await axios.get(`${BASE_API_URL}/partnerships/getPartnerships`)
                .then((response) => setData(response.data.partnerships))
                .catch((e) => console.error(e))
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getPartnerships()
    }, [])

    if (loading) {
        return (
            <LinearProgress style={{marginLeft: '10%', marginRight: '10%', marginTop: '4%'}}/>
        )
    }

    const sortedPartnerships = data.sort((a, b) => b.createdAt.localeCompare(a.createdAt))

    return (
        <div style={{paddingBottom: '1%', paddingTop: '4%', minHeight: '100vh'}}>
            {sortedPartnerships.length > 0 ? (
                <>
                    <Grid
                        container
                        spacing={1.2}
                        columns={24}
                        style={{
                            marginBottom: '30px',
                            justifyContent: 'center',
                            paddingLeft: padding,
                            paddingRight: padding
                        }}
                    >
                        {sortedPartnerships.slice(0, cardsPerRow * 3).map((item, index) => (
                            <Grid key={index} item xs={24} sm={24} md={8} lg={8} xl={8}
                                  sx={{justifyContent: 'center'}}>
                                <Box
                                    style={{
                                        height: isDesktop ? '520px' : '500px',
                                        boxSizing: 'border-box',
                                        borderRadius: '10px',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <PartnershipCard item={item} borderRadius={'10px'}/>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                    {sortedPartnerships.length > cardsPerRow * 3 && (
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Link
                                component="button"
                                variant="body1"
                                color="#F44336"
                                sx={{fontWeight: '700', fontSize: 13}}
                                underline="none"
                                onClick={() => navigate("/partnerships/allPartnerships")}
                            >
                                VIEW ALL
                            </Link>
                        </Box>
                    )}
                </>
            ) : (
                <div style={{padding: '4%', alignItems: 'center', justifyContent: 'center'}}>
                    <Typography sx={{color: Colors.YELLOW}} variant="subtitle1">No partnerships found.</Typography>
                </div>
            )}
            <div style={{paddingBottom: isMobile || isBigMobile || isTablet ? '70%' : '10%'}} />
        </div>
    )
}

export default AllPosts