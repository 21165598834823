import React from "react";
import {Box, createTheme, Link, Typography} from "@mui/material";
import {formatDate} from "../../utils/HelperFunctions";
import Tags from "../Tags/Tags";
import {useNavigate} from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

const EditorialDetails = ({editorial}) => {

    const date = formatDate(editorial.createdAt)
    const navigate = useNavigate()

    return (
        <Box style={{display: 'flex', flexDirection: 'column', paddingBottom: '10px'}}>
            <Typography
                gutterBottom
                component="div"
                sx={{fontWeight: '700', color: 'white', fontSize: '48px', fontFamily: "Calibri, sans-serif"}}
            >
                {editorial.title}
            </Typography>
            <Typography
                gutterBottom
                variant="body2"
                color="text.secondary"
                component="div"
                sx={{marginTop: '4px', color: 'whitesmoke', fontFamily: "Calibri, sans-serif"}}
            >
                {date}
            </Typography>
            <Typography
                gutterBottom
                variant="body2"
                color="text.secondary"
                component="div"
                sx={{marginTop: '4px', color: 'grey', fontFamily: "Calibri, sans-serif"}}
            >
                Reading Time: {editorial.readMinutes} mins read
            </Typography>
            <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '8px', marginTop: '10px'}}>
                {editorial.tags.map((tag, index) => (
                    <Tags title={tag}/>
                ))}
            </Box>
            <img
                src={editorial.pic}
                alt="Editorial Picture"
                loading={"lazy"}
                style={{width: '100%', height: '100%', objectFit: 'cover', marginTop: '20px', borderRadius: 8}}
            />
            <Typography
                gutterBottom
                color="text.primary"
                sx={{marginTop: '20px', width: '85%', fontSize: '20px', color: 'white', fontFamily: "Calibri, sans-serif"}}
            >
                <span dangerouslySetInnerHTML={{__html: editorial.content}}/>
            </Typography>
            <Typography
                gutterBottom
                color="text.primary"
                sx={{marginTop: '10px', color: 'whitesmoke', fontFamily: "Calibri, sans-serif"}}
            >
                Article written by
            </Typography>
            <Box style={{display: 'flex', alignItems: 'center'}}>
                <img
                    src={editorial.authorPic}
                    alt="Author Picture"
                    style={{width: '45px', height: '45px', objectFit: 'contain', borderRadius: 100}}
                />
                <Link
                    // onClick={() => {
                    //     navigate('/editorials/authors')
                    //     window.scrollTo(0, 0)
                    // }}
                    underline={'none'}
                    component="button"
                >
                    <Typography
                        gutterBottom
                        color="text.secondary"
                        component="div"
                        sx={{marginLeft: '10px', color: 'white', fontFamily: "Calibri, sans-serif"}}
                    >
                        {editorial.author}
                    </Typography>
                </Link>
            </Box>
        </Box>
    )
}

export default EditorialDetails