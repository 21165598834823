import React from "react";
import {createTheme, Grid, Typography} from "@mui/material";
import BreakingNews from "../BreakingNews/BreakingNews";
import DailyFeed from "../DailyFeed/DailyFeed";
import "./styles.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";

const MidBarEditorials = () => {

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const navigate = useNavigate()
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    return (
        <Grid
            container
            columnSpacing={3}
            sx={{paddingLeft: '7.5%', paddingRight: '7.5%'}}
        >
            <div style={{
                display: 'flex',
                flexDirection: isTablet || isLaptop || isDesktop ? 'row' : 'column',
                alignItems: isTablet || isLaptop || isDesktop ? 'center' : 'flex-start',
                justifyContent: 'space-between',
                width: '100%',
                paddingBottom: '4%',
                paddingTop: isMobile || isBigMobile ? '24%' : '10%',
                paddingLeft: isMobile || isBigMobile ? '7%' : '2%',
                paddingRight: '2%'
            }}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <a href="/" onClick={(e) => {
                        e.preventDefault();
                        window.location.href = "/carechronicles";
                    }}>
                        <img
                            style={{height: '50px', width: '220px'}}
                            src={logo}
                            alt="Logo"
                        />
                    </a>
                    <Typography component="div" sx={{
                        fontWeight: '500',
                        color: 'white',
                        fontFamily: "Calibri, sans-serif"
                    }} variant={"h6"}>
                        Stay ahead of the curve,
                        <br/>
                        Weekly insights for healthcare leaders of the next Generation
                    </Typography>
                </div>
                <div
                    style={{marginTop: isLaptop || isDesktop ? 0 : '4%'}}
                    className="cc_btn_subscribe_orange"
                    onClick={() => {
                        // window.scrollTo(0, document.body.scrollHeight)
                        navigate("/newsletter")
                    }}
                />
            </div>
            <Grid item xs={12} md={9}>
                <BreakingNews/>
            </Grid>
            {isTablet || isLaptop || isDesktop ? (
                <Grid item xs={12} md={3}>
                    <DailyFeed/>
                </Grid>
            ) : null}
        </Grid>
    )
}

export default MidBarEditorials