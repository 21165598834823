import React, {useState} from "react";
import {Alert, Box, Button, createTheme, Grid, IconButton, Link, Snackbar, TextField, Typography} from "@mui/material";
import axios from "axios";
import {BASE_API_URL} from "../../utils/Constants";
import {LinkedIn} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import Logo from "../../assets/logo-white.png";

const Footer = () => {

    const navigate = useNavigate()
    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = React.useState(false)
    const [error, setError] = useState(false)

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    }

    const subscribeToNewsletter = async () => {
        try {
            setLoading(true)
            const requestBody = {
                email: email.trim()
            }
            await axios.post(`${BASE_API_URL}/editorials/addEditorialSubscriber`, requestBody)
                .then((response) => {
                    setOpen(true)
                    setEmail("")
                })
                .catch((e) => setError(true))
        } catch (e) {
            setError(true)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Grid container spacing={0} style={{
            backgroundColor: "#1E1E1E",
            paddingTop: isMobile || isBigMobile || isTablet ? '65%' : '9%',
            paddingBottom: isMobile || isBigMobile || isTablet ? '8%' : '4%',
            flexDirection: isMobile || isBigMobile || isTablet ? 'column' : 'row',
            justifyContent: 'center'
        }}>
            <Grid item xs={isMobile || isBigMobile || isTablet ? 0 : 3}
                  sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img
                    src={Logo}
                    alt={"Logo"}
                    style={{width: '150px'}}
                />
            </Grid>
            <Grid item xs={isMobile || isBigMobile || isTablet ? 0 : 3}
                  sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingLeft: isMobile || isBigMobile || isTablet ? '0%' : '8%',
                      alignItems: isMobile || isBigMobile || isTablet ? 'center' : null,
                      marginTop: isMobile || isBigMobile || isTablet ? '20px' : '0px'
                  }}>
                <Typography
                    style={{marginBottom: 10, fontWeight: '700', fontSize: 18, color: 'white'}}
                    variant="subtitle1"
                >
                    Useful Links
                </Typography>
                <Link
                    component="button"
                    variant="body1"
                    color={'white'}
                    sx={{fontSize: 16, textAlign: 'start'}}
                    underline="none"
                    onClick={() => navigate('/partnerships')}
                >
                    Home
                </Link>
                <Link
                    component="button"
                    variant="body1"
                    color={'white'}
                    sx={{fontSize: 16, marginTop: '8px', textAlign: 'start'}}
                    underline="none"
                    onClick={() => {
                        navigate("/partnerships/authors")
                        window.scrollTo(0, 0)
                    }}
                >
                    Authors
                </Link>
                <Link
                    component="button"
                    variant="body1"
                    color={'white'}
                    sx={{fontSize: 16, marginTop: '8px', textAlign: 'start'}}
                    underline="none"
                    onClick={() => {
                        navigate("/partnerships/about-us")
                        window.scrollTo(0, 0)
                    }}
                >
                    About Us
                </Link>
                <Link
                    component="button"
                    variant="body1"
                    color={'white'}
                    sx={{fontSize: 16, marginTop: '8px', textAlign: 'start'}}
                    underline="none"
                    onClick={() => {
                        navigate("/contact-us")
                        window.scrollTo(0, 0)
                    }}
                >
                    Contact Us
                </Link>
            </Grid>
            <Grid item xs={isMobile || isBigMobile || isTablet ? 0 : 3}
                  sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingLeft: isMobile || isBigMobile || isTablet ? '0%' : '4%',
                      alignItems: isMobile || isBigMobile || isTablet ? 'center' : null,
                      marginTop: isMobile || isBigMobile || isTablet ? '20px' : '0px'
                  }}>
                <Typography
                    style={{marginBottom: 2, fontWeight: '700', fontSize: 18, color: 'white', paddingLeft: '10px'}}
                    variant="subtitle1"
                >
                    Follow Us
                </Typography>
                <Link
                    component="button"
                    variant="body1"
                    color={'white'}
                    underline="none"
                    onClick={() => window.open("https://www.linkedin.com/company/fitmedik/", "_blank")}
                >
                    <Box style={{display: 'flex', alignItems: 'center'}}>
                        <IconButton sx={{color: 'white'}}>
                            <LinkedIn fontSize={"medium"}/>
                        </IconButton>
                        <Typography
                            variant="body1"
                            color="white"
                            sx={{fontSize: 16}}
                        >
                            LinkedIn
                        </Typography>
                    </Box>
                </Link>
                {/*<Box style={{display: 'flex', alignItems: 'center'}}>*/}
                {/*    <IconButton*/}
                {/*        sx={{color: 'white'}}*/}
                {/*        onClick={() => window.open("https://www.linkedin.com/company/fitmedik/", "_blank")}*/}
                {/*    >*/}
                {/*        <Twitter fontSize={"medium"}/>*/}
                {/*    </IconButton>*/}
                {/*    <Typography*/}
                {/*        variant="body1"*/}
                {/*        color="white"*/}
                {/*        sx={{fontSize: 16}}*/}
                {/*    >*/}
                {/*        Twitter*/}
                {/*    </Typography>*/}
                {/*</Box>*/}
                {/*<Box style={{display: 'flex', alignItems: 'center'}}>*/}
                {/*    <IconButton*/}
                {/*        sx={{color: 'white'}}*/}
                {/*        onClick={() => window.open("https://www.linkedin.com/company/fitmedik/", "_blank")}*/}
                {/*    >*/}
                {/*        <YouTube fontSize={"medium"}/>*/}
                {/*    </IconButton>*/}
                {/*    <Typography*/}
                {/*        variant="body1"*/}
                {/*        color="white"*/}
                {/*        sx={{fontSize: 16}}*/}
                {/*    >*/}
                {/*        YouTube*/}
                {/*    </Typography>*/}
                {/*</Box>*/}
                {/*<Box style={{display: 'flex', alignItems: 'center'}}>*/}
                {/*    <IconButton*/}
                {/*        sx={{color: 'white'}}*/}
                {/*        onClick={() => window.open("https://www.linkedin.com/company/fitmedik/", "_blank")}*/}
                {/*    >*/}
                {/*        <Instagram fontSize={"medium"}/>*/}
                {/*    </IconButton>*/}
                {/*    <Typography*/}
                {/*        variant="body1"*/}
                {/*        color="white"*/}
                {/*        sx={{fontSize: 16}}*/}
                {/*    >*/}
                {/*        Instagram*/}
                {/*    </Typography>*/}
                {/*</Box>*/}
                {/*<Box style={{display: 'flex', alignItems: 'center'}}>*/}
                {/*    <IconButton*/}
                {/*        sx={{color: 'white'}}*/}
                {/*        onClick={() => window.open("https://www.linkedin.com/company/fitmedik/", "_blank")}*/}
                {/*    >*/}
                {/*        <Facebook fontSize={"medium"}/>*/}
                {/*    </IconButton>*/}
                {/*    <Typography*/}
                {/*        variant="body1"*/}
                {/*        color="white"*/}
                {/*        sx={{fontSize: 16}}*/}
                {/*    >*/}
                {/*        Facebook*/}
                {/*    </Typography>*/}
                {/*</Box>*/}
            </Grid>
            <Grid item xs={isMobile || isBigMobile || isTablet ? 0 : 3}
                  sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingRight: isMobile || isBigMobile || isTablet ? '0px' : '7%',
                      alignItems: isMobile || isBigMobile || isTablet ? 'center' : null,
                      marginTop: isMobile || isBigMobile || isTablet ? '20px' : '0px'
                  }}>
                <Typography
                    style={{marginBottom: 10, fontWeight: '700', fontSize: 18, color: 'white'}}
                    variant="subtitle1"
                >
                    Sign up for our Newsletter!
                </Typography>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <TextField
                        style={{width: '100%', backgroundColor: 'whitesmoke'}}
                        label="Email"
                        variant="outlined"
                        size="small"
                        value={email}
                        onChange={(event) => {
                            setEmail(event.target.value)
                        }}
                    />
                    <Button
                        style={{marginLeft: '4px', width: '100px', height: '100%'}}
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={email.trim() === ""}
                        onClick={() => subscribeToNewsletter()}
                    >
                        <span style={{color: email.trim() === "" ? 'slategray' : 'white'}}>
                            {loading ? "..." : "Sign Up"}
                        </span>
                    </Button>
                    <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                        {!error ? (
                            <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                                Thank you for subscribing!
                            </Alert>
                        ) : (
                            <Alert onClose={handleClose} severity="error" sx={{width: '100%'}}>
                                Cannot process your request
                            </Alert>
                        )}
                    </Snackbar>
                </Box>
            </Grid>
        </Grid>
    )
}

export default Footer