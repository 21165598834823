import React from "react";
import {observer} from "mobx-react";
import {createTheme, useMediaQuery} from "@mui/material";
import {BROWSER_DIMENSIONS_STORE} from "../../../state/BROWSER_DIMENSIONS";
import {LARGE_SIZE} from "../../../utils/ui-constants";
import "./styles.css";
import Fade from "react-reveal/Fade";

const Third = observer(() => {

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    return (
        <div style={{
            zIndex: 1,
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#44292B',
            paddingTop: '5%',
            paddingLeft: '8%',
            paddingRight: '8%',
            paddingBottom: isMobile || isBigMobile || isTablet ? '45%' : '10%'
        }}>
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="ab_third_ellipse1 blur200"/>
            )}
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="ab_third_ellipse2 blur200"/>
            )}

            <Fade big>
                <label style={{textAlign: 'center'}} className="ab_heading">
                    Why Fitmedik?
                </label>
                <br/>
                <br/>
                <label className="ab_content_small">
                    Our inspiration lies in the essence of 'Fitmedik,' a name carefully crafted to mirror our
                    deep-rooted philosophy. Rooted in the idea of 'fit'—health and wellness—and 'medik,' a nod to the
                    medical world, our name symbolizes the unity of these elements. We are driven by the belief that a
                    care facility, like a well-tailored remedy, should align with the principles of employee wellbeing.
                    'Fitmedik' encapsulates our commitment to cultivating a care facility that not only promotes health
                    but also stands as a testament to our dedication to the medical community.
                    <br/>
                    <br/>
                    Fitmedik echoes our mission to create an environment deserving of acclaim—where healthcare
                    facilities thrive, and both
                    care workers and patient experiences reach new heights of excellence.
                </label>
            </Fade>
        </div>
    )
})

export default Third