export const AuthorsData = [
    {
        "name": "John Doe",
        "image": "https://www.alchinlong.com/wp-content/uploads/2015/09/sample-profile.png",
        "designation": "Writer"
    },
    {
        "name": "Jane Doe",
        "image": "https://www.ateneo.edu/sites/default/files/styles/large/public/2021-11/istockphoto-517998264-612x612.jpeg?itok=aMC1MRHJ",
        "designation": "Writer"
    }
]