import { action, computed, makeObservable, observable } from "mobx";

class BrowserDimensionsStore {
  state = {
    height: window.innerHeight,
    width: window.innerWidth,
  };

  setDimensions(h, w) {
    this.state.height = h;
    this.state.width = w;
  }

  get getDimensions() {
    return this.state;
  }

  constructor() {
    makeObservable(this, {
      state: observable,
      setDimensions: action,
      getDimensions: computed,
    });
  }
}

export const BROWSER_DIMENSIONS_STORE = new BrowserDimensionsStore();
