import React from "react";
import * as colors from "../../../utils/colors";
import * as routes from "../../../utils/ui-routes";
import "../../../components/GlassmorphBG/glassmorph_styles.css";
import "./hiw_third_styles.css";
import {createTheme, Grid, useMediaQuery} from "@mui/material";
import {BROWSER_DIMENSIONS_STORE} from "../../../state/BROWSER_DIMENSIONS";
import {LARGE_SIZE} from "../../../utils/ui-constants";
import {observer} from "mobx-react";
import GlassmorphLargeFull from "../../../components/GlassmorphBG/GlassmorphLargeFull";
import Fade from "react-reveal/Fade";
import Infographic1 from "../../../assets/animations/Infographic 1.json";
import Infographic2 from "../../../assets/animations/Infographic 2.json";
import Infographic3 from "../../../assets/animations/Infographic 3.json";
import Infographic4 from "../../../assets/animations/Infographic 4.json";


const HowItWorksThird = observer(() => {

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    return (
        <div
            style={{
                zIndex: 2,
                backgroundColor: colors.MAIN_BACKGROUND,
                width: "100vw",
            }}
        >
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="hiw-third-ellipse-1 blur200"/>
            )}
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="hiw-third-ellipse-2 blur200"/>
            )}
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="hiw-third-ellipse-3 blur200"/>
            )}

            <Grid container className="hiw-third-bg">
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '5%',
                    paddingLeft: '8%',
                    paddingRight: '8%',
                    paddingBottom: '5%',
                }}>
                    <div className="glassmorph_chip">
                        <label className="glassmorph_chip_text">
                            Features
                        </label>
                    </div>
                    <br/>
                    <label className="hiw-info-text-second">
                        Where retention isn't just a metric but a testament to{" "}
                        <br/>
                        a culture that values, supports and uplifts.
                    </label>
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingBottom: isLaptop || isDesktop ? '15%' : '50%',
                    paddingLeft: '8%',
                    paddingRight: '8%',
                }}
                >
                    <Fade bottom big>
                        <div className={'glassmorph_style'}>
                            <GlassmorphLargeFull
                                logo={Infographic1}
                                title="GenAI-Powered Brilliance: Elevate Staff Retention with an All-Encompassing AI Companion"
                                content=""
                                href={routes.ROUTE_GET_FREE_TRIAL}
                            />
                        </div>
                    </Fade>
                    <div style={{height: '16px'}}/>
                    <Fade bottom big>
                        <div className={'glassmorph_style'}>
                            <GlassmorphLargeFull
                                logo={Infographic2}
                                title="Foresight in Action: Uncover Trends, Root Causes, and High-Risk Demographics with Predictive Analytics"
                                content=""
                                href={routes.ROUTE_GET_FREE_TRIAL}
                            />
                        </div>
                    </Fade>
                    <div style={{height: '16px'}}/>
                    <Fade bottom big>
                        <div className={'glassmorph_style'}>
                            <GlassmorphLargeFull
                                logo={Infographic3}
                                title="Strategic Impact: Monitor and Pivot Effectively with Real-time Intervention Effectiveness Tracking"
                                content=""
                                href={routes.ROUTE_GET_FREE_TRIAL}
                            />
                        </div>
                    </Fade>
                    <div style={{height: '16px'}}/>
                    <Fade bottom big>
                        <div className={'glassmorph_style'}>
                            <GlassmorphLargeFull
                                logo={Infographic4}
                                title="Expert Guidance at Your Fingertips: Access Best Practices, Insights, and Expertise Instantly"
                                content=""
                                href={routes.ROUTE_GET_FREE_TRIAL}
                            />
                        </div>
                    </Fade>
                </div>
            </Grid>
        </div>
    );
});

export default HowItWorksThird;
