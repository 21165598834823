import {Grid} from "@mui/material";
import React from "react";
import {NAVIGATION_STORE} from "../../state/NAVIGATION_STORE";
import {NAV_HOW_IT_WORKS} from "../../utils/nav-constants";
import DashboardFooter from "../Dashboard/Footer";
import HowItWorksFourth from "./Fourth";
import HowItWorksMain from "./Main";
import HowItWorksSecond from "./Second";
import "./styles.css";
import HowItWorksThird from "./Third";
import NavigationBar from "../../components/NavBar";
import {metaData} from "../../utils/meta-constants";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga4";
import {intercom_initialiser} from "../../utils/intercom_initializer";

const HowItWorks = () => {
    React.useEffect(() => {
        NAVIGATION_STORE.setScreen(NAV_HOW_IT_WORKS);
        ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
        ReactGA.send("pageview");
        intercom_initialiser();
    }, []);

    return (
        <div className="container">
            <Helmet>
                <meta name="title" content={metaData.hiw.title}/>
                <meta name="description" content={metaData.hiw.description}/>
                <title>{metaData.hiw.title}</title>
            </Helmet>
            <NavigationBar/>
            <Grid container>
                <HowItWorksMain/>
                {/*<HowItWorksSecond/>*/}
                <HowItWorksThird/>
                {/*<HowItWorksFourth/>*/}
                <DashboardFooter/>
            </Grid>
        </div>
    );
};

export default HowItWorks;
