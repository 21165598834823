import React from "react";
import {Grid} from "@mui/material";
import "./hiw_fourth_styles.css";
import Glassmorph from "../../../components/GlassmorphBG";
import {data} from "./infoCards";
import {BROWSER_DIMENSIONS_STORE} from "../../../state/BROWSER_DIMENSIONS";
import {LARGE_SIZE} from "../../../utils/ui-constants";
import Fade from "react-reveal/Fade";

const Third = () => {
    return (
        <div
            style={{
                zIndex: 1,
                backgroundColor: "#282C34",
                width: "100vw",
            }}
        >
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="hiw-fourth-ellipse-1 center-fit blur400"/>
            )}
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="hiw-fourth-ellipse-2 center-fit blur400"/>
            )}
            <Grid container className="hiw-fourth-bg">
                <Grid container sx={{mt: 12}}>
                    <Grid item xs={1}/>
                    <Grid container item xs={10}>
                        <Grid item xs={12} lg={7}>
                            <Fade>
                                <label className="hiw-info-text-fourth">
                                    <span className="hiw-info-text-fourth hiw-info-text-fourth-highlight">
                                      Data Protection{" "}
                                    </span>
                                    is our top priority and fundamental duty.
                                </label>
                            </Fade>
                        </Grid>
                    </Grid>
                    <Grid item xs={1}/>
                </Grid>
                <Grid container sx={{mt: 8, mb: 27}}>
                    <Grid item xs={1}/>
                    <Grid container item xs={10} spacing={3}>
                        {data.map((val, index) => (
                            <Glassmorph
                                logo={val.logo}
                                title1={val.title1}
                                title2={val.title2}
                                content={val.content}
                                key={index}
                            />
                        ))}
                    </Grid>
                    <Grid item xs={1}/>
                </Grid>
            </Grid>
        </div>
    );
};

export default Third
