import React, {useLayoutEffect, useRef, useState} from "react";
import "./placard_styles.css";
import {createTheme, useMediaQuery} from "@mui/material";
import * as routes from "../../../../utils/ui-routes";
import {useNavigate} from "react-router-dom";
import Fade from "react-reveal/Fade";
import {navigateToExternal} from "../../../../utils/helper-functions";

const Placard = () => {
    const ref = useRef(null);
    const [height, setHeight] = useState(0);
    const navigate = useNavigate()

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    useLayoutEffect(() => {
        if (ref.current) {
            setHeight(ref.current.clientHeight);
        }
    }, []);

    return (
        <Fade big>
            <div
                className={'cbf_card'}
                ref={ref}
                style={{
                    display: 'flex',
                    marginTop: isMobile || isBigMobile ? "-200px" : "-80px",
                    zIndex: 99,
                    flexDirection: isMobile || isBigMobile || isTablet ? 'column' : 'row',
                    padding: isLaptop || isDesktop ? '40px' : '20px',
                    justifyContent: 'space-between',
                }}
            >
                <div style={{display: 'flex', flexDirection: 'column', flex: 0.6}}>
                    <div className="cbf_placard_glassmorph_chip">
                        <label className="cbf_placard_glassmorph_chip_text">
                            Digital Twin Technology
                        </label>
                    </div>
                    <br/>
                    <label className="dtt_first_heading">
                        Welcome to the Future of
                        <br/>
                        <span className="dtt_first_heading dtt_first_heading_highlight">
                            {" "}Healthcare Excellence!{" "}
                        </span>
                    </label>
                    <br/>
                    <label className="dtt_first_content_small">
                        Autopilot well being monitoring & management with Generative AI powered Digital Twins of
                        care workers.{" "}
                        <br/>
                        <br/>
                        Say goodbye to outdated surveys and welcome the era of proactive healthcare management.{" "}
                        <br/>
                        <br/>
                        Join us in the leap into the future, where every decision is informed by real-time data,
                        ensuring that your facility remains at the forefront of operational excellence.
                    </label>
                    <br/>
                    <br/>
                    <div style={{
                        display: 'flex',
                        flexDirection: isLaptop || isDesktop ? 'row' : 'column',
                        alignItems: 'center',
                        justifyContent: isMobile || isBigMobile || isTablet ? 'center' : 'flex-start'
                    }}>
                        <div
                            className="dtt_first_btn_orange"
                            onClick={() => navigateToExternal(routes.ROUTE_BOOK_A_DEMO)}
                        />
                        <div
                            style={{
                                marginLeft: isLaptop || isDesktop ? '30px' : 0,
                                marginTop: isLaptop || isDesktop ? 0 : '20px'
                            }}
                            className="dtt_first_btn_black"
                            onClick={() => navigate(routes.ROUTE_HOW_IT_WORKS)}
                        />
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    flex: 0.4,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: isMobile || isBigMobile || isTablet ? '15%' : 0
                }}>
                    <img
                        style={{height: 'fit-content', width: 'fit-content'}}
                        src={isLaptop || isDesktop ? require('../../../../assets/images/digital_twin_tech/right_doctor.svg')
                            : require('../../../../assets/images/digital_twin_tech/right_doc.png')}
                        alt={'doctor'}
                    />
                </div>
            </div>
        </Fade>
    );
};

export default Placard
