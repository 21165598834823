import React from "react";
import "./styles.css";
import TagsFilter from "../../components/TagsFilter/TagsFilter";
import Footer from "../../components/Footer/Footer";
import {Box, createTheme, Typography} from "@mui/material";
import {Helmet} from "react-helmet";
import contactus from "../../assets/contact-us.webp";
import useMediaQuery from "@mui/material/useMediaQuery";

const AboutUs = () => {

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    return (
        <>
            <div className={"db-main-bg"} style={{paddingLeft: '1%', paddingRight: '1%', minHeight: '100vh'}}>
                <TagsFilter/>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{"About Us"}</title>
                    <meta
                        name="description"
                        content={"Fitmedik editorial about us"}
                    />
                </Helmet>
                <Box style={{display: 'flex', flexDirection: 'column', padding: '4%', minHeight: '100vh'}}>
                    <Typography
                        sx={{
                            color: 'white',
                            fontWeight: '700',
                            marginBottom: '20px',
                            fontFamily: "Calibri, sans-serif",
                            fontSize: '40px'
                        }}
                        gutterBottom
                        component="div"
                    >
                        About Us
                    </Typography>
                    <Box style={{display: 'flex', flexDirection: isLaptop || isDesktop ? 'row' : 'column'}}>
                        <Box style={{display: 'flex', flex: 0.7, paddingRight: '20px'}}>
                            <Typography
                                sx={{color: 'white', fontSize: 20}}
                                gutterBottom
                                variant="body1"
                                component="div"
                            >
                                The Care Chronicle is dedicated to providing valuable and insightful information that
                                empowers
                                non-medical
                                professionals in the healthcare sector. Whether you are a senior-level official in a
                                reputed
                                hospital or
                                someone starting their journey In this field. The Care Chronicle aims to equip you with
                                the
                                knowledge and
                                the necessary tools to understand and optimize the performance of your Hospital.
                                We equip you with the latest developments and disruptions in the Healthcare ecosystem
                                while
                                keeping true
                                to the core of ethical managerial values.
                            </Typography>
                        </Box>
                        <Box
                            className={"glassmorph_style"}
                            style={{
                                display: 'flex',
                                flex: 0.3,
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingTop: '30px',
                                paddingBottom: '30px'
                            }}
                        >
                            <img
                                src={contactus}
                                alt="we will get in touch soon"
                            />
                        </Box>
                    </Box>
                </Box>
            </div>
            <Footer/>
        </>
    )
}

export default AboutUs