import React, {useEffect, useState} from "react";
import axios from "axios";
import {BASE_API_URL} from "../../utils/Constants";
import {Box, Card, CardContent, Divider, LinearProgress, Link, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import Tags from "../Tags/Tags";
import Colors from "../../utils/Colors";
import "./styles.css";

const DailyFeed = () => {

    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const getEditorials = async () => {
        setLoading(true)
        try {
            await axios.get(`${BASE_API_URL}/editorials/getEditorials`)
                .then((response) => setData(response.data.editorials))
                .catch((e) => console.error(e))
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getEditorials()
    }, [])

    if (loading) {
        return <LinearProgress/>;
    }

    const currentDate = new Date()
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(currentDate.getDate() - 14)
    let filteredEditorials = data.filter(
        (editorial) => {
            const editorialDate = Date.parse(editorial.createdAt)
            return editorialDate >= twoWeeksAgo.getTime() || editorial.dailyFeed === true
        }
    )
    filteredEditorials = filteredEditorials.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
    let slicedEditorials = filteredEditorials
    if (filteredEditorials.length > 4) {
        slicedEditorials = filteredEditorials.slice(0, 4)
    }

    return (
        <>
            <Typography component="div" sx={{
                fontWeight: '700',
                fontSize: '38px',
                color: 'white',
                fontFamily: "Calibri, sans-serif"
            }}>
                Feed
            </Typography>
            <Divider sx={{backgroundColor: '#8c8c8c', height: 2, marginTop: '13px'}}/>
            {slicedEditorials.length > 0 ? (
                slicedEditorials.map((editorial) => (
                    <Card
                        key={editorial._id}
                        sx={{
                            backgroundColor: 'transparent',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '8px',
                            borderRadius: '8px',
                            cursor: 'pointer',
                            transition: 'box-shadow 0.3s ease'
                        }}
                        elevation={0}
                        onClick={() => window.open(`/carechronicles/open-editorial?id=${editorial._id}`, '_self')}
                    >
                        <CardContent>
                            <Typography
                                sx={{
                                    color: 'white',
                                    fontWeight: '500',
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 2,
                                    fontFamily: "Calibri, sans-serif"
                                }}
                                variant="h6"
                            >
                                {editorial.title}
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                gap: '8px',
                                marginTop: '10px'
                            }}>
                                {editorial.tags.map((tag, index) => (
                                    <Tags title={tag}/>
                                ))}
                            </Box>
                        </CardContent>
                        <Divider
                            sx={{backgroundColor: '#8c8c8c', height: '1px', marginLeft: '8px', marginRight: '8px'}}/>
                    </Card>
                ))
            ) : (
                <div style={{margin: '8px', alignItems: 'center', justifyContent: 'center'}}>
                    <Typography sx={{color: Colors.YELLOW, fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif"}}
                                variant="subtitle1">No editorials found.</Typography>
                </div>
            )}
            <Divider sx={{backgroundColor: '#212121', height: '1px', marginTop: '20px', marginBottom: '10px'}}/>
            {filteredEditorials.length >= 4 && (
                <Box style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    marginTop: '20px'
                }}>
                    <Link
                        component="button"
                        variant="body1"
                        color="#F44336"
                        sx={{
                            fontWeight: '700',
                            fontSize: 13,
                            marginLeft: '8px',
                            marginRight: '8px',
                            fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif"
                        }}
                        underline="none"
                        onClick={() => navigate("/carechronicles/dailyFeed")}
                    >
                        VIEW MORE POSTS
                    </Link>
                </Box>
            )}
        </>
    );
}

export default DailyFeed