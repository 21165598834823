import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {NAVIGATION_STORE} from "../../state/NAVIGATION_STORE";
import {NAV_ABOUT_US} from "../../utils/nav-constants";
import ReactGA from "react-ga4";
import {intercom_initialiser} from "../../utils/intercom_initializer";
import * as colors from "../../utils/colors";
import {Helmet} from "react-helmet";
import {metaData} from "../../utils/meta-constants";
import NavigationBar from "../../components/NavBar";
import {Grid} from "@mui/material";
import First from "./First";
import Second from "./Second";
import Third from "./Third";
import DashboardFooter from "../Dashboard/Footer";

const AboutUs = observer(() => {

    useEffect(() => {
        NAVIGATION_STORE.setScreen(NAV_ABOUT_US);
        ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
        ReactGA.send("pageview");
        intercom_initialiser();
    }, []);

    return (
        <div className="container" style={{backgroundColor: colors.MAIN_BACKGROUND}}>
            <Helmet>
                <meta name="title" content={metaData.aboutUs.title}/>
                <meta name="description" content={metaData.aboutUs.description}/>
                <title>{metaData.aboutUs.title}</title>
            </Helmet>
            <NavigationBar/>
            <Grid container>
                <First/>
                <Second/>
                <Third/>
                <DashboardFooter/>
            </Grid>
        </div>
    );
})

export default AboutUs