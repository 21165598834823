import React from "react";
import "./ddt_styles.css";
import {createTheme, Grid, useMediaQuery} from "@mui/material";
import DigitalTwin from "../DigitalTwin/DigitalTwin";
import {useNavigate} from "react-router-dom";
import * as routes from "../../utils/ui-routes";
import Fade from "react-reveal/Fade";

const DashboardDigitalTwin = () => {

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    const navigate = useNavigate()

    return (
        <div
            style={{
                backgroundColor: "#131519",
                zIndex: 1,
                width: "100%"
            }}
        >
            <div className="ddt_eclipse1"/>

            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <Grid container sx={{mt: 10}}>
                    <Grid item xs={1}/>
                    <Grid item xs={10}>
                        <Fade>
                            <label className="db_main_heading">
                                Enhance wellbeing of care workers and predict turnover risks in real-time through their
                                <span className="db_main_heading db_main_heading_highlight_orange">
                                    {" "}Digital Twins.
                                </span>
                            </label>
                        </Fade>
                    </Grid>
                    <Grid item xs={1}/>
                </Grid>
                <Fade big>
                    <div
                        className={'twin-container'}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            cursor: 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'16\' height=\'16\' viewBox=\'0 0 32 32\' fill=\'%2370E6F1\'><circle cx=\'16\' cy=\'16\' r=\'16\'/></svg>") 16 16, auto',
                        }}
                    >
                        <div style={{
                            flex: 1,
                            width: '80%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: isMobile || isBigMobile ? 'center' : 'flex-end'
                        }}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '80px',
                                    width: '80px',
                                    borderRadius: 100,
                                    backgroundColor: '#70E6F1',
                                    cursor: 'pointer',
                                    transition: 'width 0.3s, height 0.3s'
                                }}
                                onClick={() => navigate(routes.ROUTE_HOW_IT_WORKS)}
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.width = '100px';
                                    e.currentTarget.style.height = '100px';
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.width = '80px';
                                    e.currentTarget.style.height = '80px';
                                }}
                            >
                                <label style={{
                                    fontFamily: 'Poppins',
                                    color: 'black',
                                    fontWeight: '600',
                                    fontSize: 14,
                                    cursor: 'pointer'
                                }}>
                                    LEARN{" "}
                                    <br/>
                                    MORE
                                </label>
                            </div>
                        </div>
                        <DigitalTwin/>
                        <label style={{width: '80%', paddingTop: '4%'}} className="hiw-info-text-second-small">
                            Predict and address turnover risks among care workers by utilizing digital twins of care
                            providers in real-time. Offer frontline support and continuous assistance to mitigate
                            frustration and enhance workplace satisfaction.
                        </label>
                        <div
                            style={{marginTop: '2%'}}
                            className="db-second-btn"
                            onClick={() => {
                                navigate(routes.ROUTE_DIGITAL_TWIN_TECHNOLOGY)
                                window.scroll(0, 0)
                            }}
                        />
                    </div>
                </Fade>
            </div>
        </div>
    );
};

export default DashboardDigitalTwin;
