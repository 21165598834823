import React from "react";
import "./styles.css";
import * as colors from "../../utils/colors";
import { Grid } from "@mui/material";
import ReactGA from "react-ga4";

const PageNotFound = () => {
  React.useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
    ReactGA.send("pageview");
  }, []);
  return (
    <div
      style={{
        backgroundColor: colors.MAIN_BACKGROUND,
        width: "100vw",
        height: "100vh",
        zIndex: 1,
      }}
      className="pnf-container"
    >
      <div className="pnf-ellipse-1 blur300" />
      <div className="pnf-ellipse-2 blur300" />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100vh" }}
        className="pnf-container"
      >
        <label className="pnf-text">Page not found :/</label>
      </Grid>
    </div>
  );
};

export default PageNotFound;
