import React, {useState} from "react";
import "./hiw_main_styles.css";
import * as colors from "../../../utils/colors";
import {BROWSER_DIMENSIONS_STORE} from "../../../state/BROWSER_DIMENSIONS";
import {LARGE_SIZE} from "../../../utils/ui-constants";
import {observer} from "mobx-react";
import circles2 from "../../../assets/images/how_it_works/circles2.png";
import {Box, createTheme, Tab, Tabs, useMediaQuery} from "@mui/material";
import {TabContext, TabPanel} from "@mui/lab";
import Fade from "react-reveal/Fade";

const HowItWorksMain = observer(() => {

    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    return (
        <div style={{zIndex: 1, backgroundColor: colors.MAIN_BACKGROUND, width: "100%"}}>
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="hiw-ellipse-main-1 blur200"/>
            )}
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="hiw-ellipse-main-2 blur200"/>
            )}
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="hiw-ellipse-main-3 blur200"/>
            )}
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    paddingTop: '5%',
                    paddingLeft: '8%',
                    paddingRight: '8%',
                    paddingBottom: '5%',
                    minHeight: '100%',
                    flexDirection: 'column',
                    background: `url(${circles2})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                }}
            >
                <div style={{
                    marginTop: '80px',
                    textAlign: 'center',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <label style={{textAlign: 'center'}} className="monitor-text">
                        AI Retention Companion
                    </label>
                    <br/>
                    <label style={{textAlign: 'center'}} className="monitor-sub-text">
                        Co-Pilot your retention efforts with the power of generative AI and predictive analytics.
                    </label>
                    <br/>
                    <br/>
                    <br/>
                    <img
                        src={require('../../../assets/gifs/dashboard/AI Copilot.gif')}
                        width={'100%'}
                        height={'100%'}
                        alt={'img_1'}
                    />
                </div>

                {/*{isMobile || isBigMobile ? (*/}
                {/*    <div style={{*/}
                {/*        display: 'flex',*/}
                {/*        flexDirection: 'column',*/}
                {/*        alignItems: 'center',*/}
                {/*        justifyContent: 'center'*/}
                {/*    }}>*/}
                {/*        <label style={{textAlign: 'center'}} className="monitor-sub-text">*/}
                {/*            Experience the power of Generative AI*/}
                {/*        </label>*/}
                {/*        <img src={require('../../../assets/gifs/cbf/dashboard gif.png')} width={'100%'}*/}
                {/*             height={'100%'} alt={'gif_1'}/>*/}
                {/*        <br/>*/}
                {/*        <label style={{textAlign: 'center'}} className="monitor-sub-text">*/}
                {/*            Make data-driven interventions*/}
                {/*        </label>*/}
                {/*        <img src={require('../../../assets/gifs/cbf/2.gif')} width={'100%'}*/}
                {/*             height={'100%'} alt={'gif_2'}/>*/}
                {/*        <br/>*/}
                {/*        <label style={{textAlign: 'center'}} className="monitor-sub-text">*/}
                {/*            Monitor the Effectiveness of Interventions*/}
                {/*        </label>*/}
                {/*        <img src={require('../../../assets/gifs/cbf/3.gif')} width={'100%'}*/}
                {/*             height={'100%'} alt={'gif_3'}/>*/}
                {/*        <br/>*/}
                {/*        <label style={{textAlign: 'center'}} className="monitor-sub-text">*/}
                {/*            Access all the resources you need*/}
                {/*        </label>*/}
                {/*        <img src={require('../../../assets/gifs/cbf/4.gif')} width={'100%'}*/}
                {/*             height={'100%'} alt={'gif_4'}/>*/}
                {/*    </div>*/}
                {/*) : (*/}
                {/*    <Fade big>*/}
                {/*        <div className={'glassmorph_style'} style={{*/}
                {/*            display: 'flex',*/}
                {/*            flexDirection: 'column',*/}
                {/*            alignItems: 'center',*/}
                {/*            justifyContent: 'center',*/}
                {/*            paddingTop: '20px'*/}
                {/*        }}>*/}
                {/*            <TabContext value={value}>*/}
                {/*                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>*/}
                {/*                    <Tabs*/}
                {/*                        value={value}*/}
                {/*                        onChange={handleChange}*/}
                {/*                        variant="scrollable"*/}
                {/*                        scrollButtons="auto"*/}
                {/*                        allowScrollButtonsMobile*/}
                {/*                        sx={{borderBottom: '0.5px solid #e8e8e8'}}*/}
                {/*                        TabIndicatorProps={{style: {background: "#FFFFFF"}}}*/}
                {/*                    >*/}
                {/*                        <Tab*/}
                {/*                            label={*/}
                {/*                                <div style={{cursor: 'pointer'}}>*/}
                {/*                                    <label style={{cursor: 'pointer'}}>Experience the power{" "}</label>*/}
                {/*                                    <br/>*/}
                {/*                                    <label style={{cursor: 'pointer'}}>of Generative AI</label>*/}
                {/*                                </div>*/}
                {/*                            }*/}
                {/*                            value="1"*/}
                {/*                            sx={{*/}
                {/*                                color: '#E8E8E8',*/}
                {/*                                textTransform: 'none',*/}
                {/*                                fontSize: '16px',*/}
                {/*                                fontFamily: 'Poppins',*/}
                {/*                                fontWeight: '500',*/}
                {/*                                textAlign: 'center',*/}
                {/*                                width: '250px',*/}
                {/*                                '&:hover': {*/}
                {/*                                    color: '#FFFFFF',*/}
                {/*                                    opacity: 0.8,*/}
                {/*                                },*/}
                {/*                                '&.Mui-selected': {*/}
                {/*                                    color: '#FFFFFF',*/}
                {/*                                    fontWeight: '700'*/}
                {/*                                },*/}
                {/*                            }}*/}
                {/*                        />*/}
                {/*                        <Tab*/}
                {/*                            label={*/}
                {/*                                <div style={{cursor: 'pointer'}}>*/}
                {/*                                    <label style={{cursor: 'pointer'}}>Make data-driven{" "}</label>*/}
                {/*                                    <br/>*/}
                {/*                                    <label style={{cursor: 'pointer'}}>interventions</label>*/}
                {/*                                </div>*/}
                {/*                            }*/}
                {/*                            value="2"*/}
                {/*                            sx={{*/}
                {/*                                color: '#E8E8E8',*/}
                {/*                                textTransform: 'none',*/}
                {/*                                fontSize: '16px',*/}
                {/*                                fontFamily: 'Poppins',*/}
                {/*                                fontWeight: '500',*/}
                {/*                                textAlign: 'center',*/}
                {/*                                width: '250px',*/}
                {/*                                '&:hover': {*/}
                {/*                                    color: '#FFFFFF',*/}
                {/*                                    opacity: 0.8,*/}
                {/*                                },*/}
                {/*                                '&.Mui-selected': {*/}
                {/*                                    color: '#FFFFFF',*/}
                {/*                                    fontWeight: '700'*/}
                {/*                                },*/}
                {/*                            }}*/}
                {/*                        />*/}
                {/*                        <Tab*/}
                {/*                            label={*/}
                {/*                                <div style={{cursor: 'pointer'}}>*/}
                {/*                                    <label style={{cursor: 'pointer'}}>Monitor the Effectiveness{" "}</label>*/}
                {/*                                    <br/>*/}
                {/*                                    <label style={{cursor: 'pointer'}}>of Interventions</label>*/}
                {/*                                </div>*/}
                {/*                            }*/}
                {/*                            value="3"*/}
                {/*                            sx={{*/}
                {/*                                color: '#E8E8E8',*/}
                {/*                                textTransform: 'none',*/}
                {/*                                fontSize: '16px',*/}
                {/*                                fontFamily: 'Poppins',*/}
                {/*                                fontWeight: '500',*/}
                {/*                                textAlign: 'center',*/}
                {/*                                width: '250px',*/}
                {/*                                '&:hover': {*/}
                {/*                                    color: '#FFFFFF',*/}
                {/*                                    opacity: 0.8,*/}
                {/*                                },*/}
                {/*                                '&.Mui-selected': {*/}
                {/*                                    color: '#FFFFFF',*/}
                {/*                                    fontWeight: '700'*/}
                {/*                                },*/}
                {/*                            }}*/}
                {/*                        />*/}
                {/*                        <Tab*/}
                {/*                            label={*/}
                {/*                                <div style={{cursor: 'pointer'}}>*/}
                {/*                                    <label style={{cursor: 'pointer'}}>Access all the resources{" "}</label>*/}
                {/*                                    <br/>*/}
                {/*                                    <label style={{cursor: 'pointer'}}>you need</label>*/}
                {/*                                </div>*/}
                {/*                            }*/}
                {/*                            value="4"*/}
                {/*                            sx={{*/}
                {/*                                color: '#E8E8E8',*/}
                {/*                                textTransform: 'none',*/}
                {/*                                fontSize: '16px',*/}
                {/*                                fontFamily: 'Poppins',*/}
                {/*                                fontWeight: '500',*/}
                {/*                                textAlign: 'center',*/}
                {/*                                width: '250px',*/}
                {/*                                '&:hover': {*/}
                {/*                                    color: '#FFFFFF',*/}
                {/*                                    opacity: 0.8,*/}
                {/*                                },*/}
                {/*                                '&.Mui-selected': {*/}
                {/*                                    color: '#FFFFFF',*/}
                {/*                                    fontWeight: '700'*/}
                {/*                                },*/}
                {/*                            }}*/}
                {/*                        />*/}
                {/*                    </Tabs>*/}
                {/*                </Box>*/}
                {/*                <TabPanel value="1">*/}
                {/*                    <img src={require('../../../assets/gifs/cbf/1.gif')} width={'100%'}*/}
                {/*                         height={'100%'} alt={'gif_1'}/>*/}
                {/*                </TabPanel>*/}
                {/*                <TabPanel value="2">*/}
                {/*                    <img src={require('../../../assets/gifs/cbf/2.gif')} width={'100%'}*/}
                {/*                         height={'100%'} alt={'gif_2'}/>*/}
                {/*                </TabPanel>*/}
                {/*                <TabPanel value="3">*/}
                {/*                    <img src={require('../../../assets/gifs/cbf/3.gif')} width={'100%'}*/}
                {/*                         height={'100%'} alt={'gif_3'}/>*/}
                {/*                </TabPanel>*/}
                {/*                <TabPanel value="4">*/}
                {/*                    <img src={require('../../../assets/gifs/cbf/4.gif')} width={'100%'}*/}
                {/*                         height={'100%'} alt={'gif_4'}/>*/}
                {/*                </TabPanel>*/}
                {/*            </TabContext>*/}
                {/*        </div>*/}
                {/*    </Fade>*/}
                {/*)}*/}
            </div>
        </div>
    );
});

export default HowItWorksMain;
