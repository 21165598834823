import React, {useEffect, useState} from "react";
import "./styles.css";
import * as colors from "../../../utils/colors";
import phone_mock1 from "../../../assets/images/digital_twin_tech/phone_mock11.svg";
import phone_mock2 from "../../../assets/images/digital_twin_tech/phone_mock21.svg";
import phone_mock3 from "../../../assets/images/digital_twin_tech/phone_mock31.svg";
import {createTheme, useMediaQuery} from "@mui/material";
import {BROWSER_DIMENSIONS_STORE} from "../../../state/BROWSER_DIMENSIONS";
import {LARGE_SIZE} from "../../../utils/ui-constants";
import Fade from "react-reveal/Fade";

const Third = () => {

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    const [scrollPosition, getScrollPosition] = useState(document.documentElement.scrollTop)

    useEffect(() => {
        window.addEventListener('scroll', () => {
            getScrollPosition(document.documentElement.scrollTop);
        })
    }, [])

    return (
        <div style={{zIndex: 1, backgroundColor: colors.MAIN_BACKGROUND, minHeight: '100vh'}}>
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="cbf_third_orange-bg blur200"/>
            )}
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="cbf_third_yellow-bg blur200"/>
            )}
            <div className="content" id="appscreen">
                <div className="div-text1">
                    <Fade big>
                        <div className="div-text2">
                            <h1 className={"text-heading"}>
                                Provide wellbeing companion to address workplace challenges
                            </h1>
                            <h5 className={"text-content"}>
                                We revolutionize the healthcare landscape by empowering you to take control of your
                                well-being through our innovative mobile app. Continuously monitor your burnout risk
                                levels
                                with ease as our app discreetly tracks over 18 key parameters, all in
                                real-time and entirely passively, without any input required from you.
                            </h5>
                        </div>
                    </Fade>
                    <Fade big>
                        <div className="div-text2">
                            <h1 className={"text-heading"}>
                                Continuously monitor & manage wellbeing risk levels
                            </h1>
                            <h5 className={"text-content"}>
                                With real-time insights into 18+ parameters, the app provides personalized
                                recommendations to
                                address stress, fatigue, and other risk factors. Prioritize the proactive management of
                                wellbeing to enhance care worker satisfaction, retention, and overall patient care.
                            </h5>
                        </div>
                    </Fade>
                    <Fade big>
                        <div className="div-text2">
                            <h1 className={"text-heading"}>
                                Centralized Access to Wellness Resources in a Tap
                            </h1>
                            <h5 className={"text-content"}>
                                Facilitate seamless communication between management and care workers by centralizing
                                wellness
                                resources on our mobile app's dedicated page. With a simple touch, care workers can
                                access all
                                provided resources, ensuring vital information and support systems are readily available
                            </h5>
                        </div>
                    </Fade>
                </div>
                <div className="phone_div-1">
                    <div style={{top: isLaptop ? '10%' : isDesktop ? '20%' : '2%'}} className="phone_div-2">
                        <div className="phone_div-3">
                            <img
                                className={'picture'}
                                loading={'lazy'}
                                src={scrollPosition < 3000 ? phone_mock1 : scrollPosition < 3800 ? phone_mock2 : phone_mock3}
                                alt={'image'}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="content" id="appscreen_mob">
                <div>
                    <img style={{objectFit: 'cover'}} className={'picture'}
                         src={require('../../../assets/images/digital_twin_tech/pm1.png')}
                         loading={'lazy'} alt={'phone_1'}/>
                    <h1 className={"text-heading"}>
                        Provide wellbeing companion to address workplace challenges
                    </h1>
                    <h5 className={"text-content"}>
                        Utilizing GPT 3.5 turbo, the app facilitates self-recognition, patient note-taking, and
                        explanations of medical terms, enhancing communication. It also includes a dedicated reflection
                        space, optimizing work-life balance. This all-in-one solution streamlines administrative tasks,
                        allowing your team to focus on delivering exceptional patient care.
                    </h5>
                </div>
                <div style={{paddingTop: "2rem"}}>
                    <img style={{objectFit: 'cover'}} className={'picture'}
                         src={require('../../../assets/images/digital_twin_tech/pm2.png')} alt={"image"}
                         loading={'lazy'}/>
                    <h1 className={"text-heading"}>
                        Continuously monitor & manage wellbeing risk levels
                    </h1>
                    <h5 className={"text-content"}>
                        With real-time insights into 18+ parameters, the app provides personalized recommendations to
                        address stress, fatigue, and other risk factors. Prioritize the proactive management of
                        wellbeing to enhance care worker satisfaction, retention, and overall patient care.
                    </h5>
                </div>
                <div style={{paddingTop: "2rem"}}>
                    <img style={{objectFit: 'cover'}} className={'picture'}
                         src={require('../../../assets/images/digital_twin_tech/pm3.png')} alt={"image"}
                         loading={'lazy'}/>
                    <h1 className={"text-heading"}>
                        Centralized Access to Wellness Resources in a Tap
                    </h1>
                    <h5 className={"text-content"}>
                        Facilitate seamless communication between management and care workers by centralizing wellness
                        resources on our mobile app's dedicated page. With a simple touch, care workers can access all
                        provided resources, ensuring vital information and support systems are readily available
                    </h5>
                </div>
            </div>
        </div>
    )
};

export default Third
