import React from "react";
import "./styles.css";
import {newsData} from "./newsData";
import GlassmorphNews from "../../../components/GlassmorphBG/GlassmorphNews";
import {createTheme, useMediaQuery} from "@mui/material";
import Fade from "react-reveal/Fade";

const DashboardFourth = () => {

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    return (
        <div style={{
            display: 'flex',
            flexDirection: isMobile || isBigMobile || isTablet ? 'column' : 'row',
            flex: 1,
            paddingTop: '10%',
            paddingLeft: '8%',
            paddingRight: '8%',
            paddingBottom: '200px'
        }}>
            {isMobile || isBigMobile || isTablet ? (
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div className="glassmorph_chip">
                        <label className="glassmorph_chip_text">
                            Care Chronicles
                        </label>
                    </div>
                    <br/>
                    <label className="db_main_heading">
                        Stay ahead of the curve, Weekly insights for healthcare leaders of the next Generation
                    </label>
                    <br/>
                    <div
                        className="dbs_fourth_button dbs_fourth_button_left"
                        onClick={() => window.open(`/carechronicles`, '_self')}
                    />
                    <br/>
                    <br/>
                    {newsData.map((val, index) => (
                        <GlassmorphNews title={val.title} link={val.link} key={index}/>
                    ))}
                </div>
            ) : (
                <>
                    <Fade big>
                        <div style={{display: 'flex', flex: 0.45, flexDirection: 'column'}}>
                            <div className="glassmorph_chip">
                                <label className="glassmorph_chip_text">
                                    Care Chronicles
                                </label>
                            </div>
                            <br/>
                            <label className="db_main_heading">
                                Stay ahead of the curve,{" "}
                                <br/>
                                Weekly insights for healthcare leaders of the next Generation
                            </label>
                            <br/>
                            <div
                                className="dbs_fourth_button dbs_fourth_button_left"
                                onClick={() => window.open(`/carechronicles`, '_self')}
                            />
                        </div>
                    </Fade>
                    <div style={{
                        display: 'flex',
                        flex: 0.55,
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end'
                    }}>
                        {newsData.map((val, index) => (
                            <GlassmorphNews title={val.title} link={val.link} key={index}/>
                        ))}
                    </div>
                </>
            )}
        </div>
    )
}

export default DashboardFourth