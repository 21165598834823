import React from "react";
import {createTheme, Grid, useMediaQuery} from "@mui/material";
import "../glassmorph_styles.css";
import {useNavigate} from "react-router-dom";
import Lottie from 'react-lottie-player';
import {useInView} from "react-intersection-observer";

const GlassmorphLargeFull = ({title, logo, content, href}) => {

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    const navigate = useNavigate();
    const { ref, inView, entry } = useInView({
        threshold: 0.75
    })

    return (
        <Grid container item xs={12} sx={{p: 5}} spacing={3} ref={ref}>
            <Grid
                container
                item
                lg={6}
                md={12}
                mt={{lg: 4, md: 0, xs: 0}}
                spacing={4}
                order={{lg: 1, md: 2, xs: 2}}
            >
                <Grid item xs={12}>
                    <label className="glassmorph_title">{title}</label>
                </Grid>
                {/*<Grid item xs={12}>*/}
                {/*    <label className="glassmorph_content">{content}</label>*/}
                {/*</Grid>*/}
                <Grid container item xs={12} alignItems="center">
                    <div
                        className="glassmorph_btn"
                        onClick={() => navigate(href)}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                item
                lg={6}
                md={12}
                justifyContent={{lg: "flex-end", md: "center", xs: "center"}}
                order={{lg: 2, md: 1, xs: 1}}
            >
                <Lottie
                    style={{height: isLaptop || isDesktop ? 280 : 190, width: isLaptop || isDesktop ? 330 : 220}}
                    play={inView}
                    loop={false}
                    speed={0.75}
                    animationData={logo}
                />
            </Grid>
        </Grid>
    );
};

export default GlassmorphLargeFull;
