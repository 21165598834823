import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import "./App.css";
import {BROWSER_DIMENSIONS_STORE} from "./state/BROWSER_DIMENSIONS";
import {SCROLL_STORE} from "./state/SCROLL_STORE";
import {GetPageYOffset} from "./utils/helper-functions";
import React from "react";
import * as routes from "./utils/ui-routes";

import Dashboard from "./pages/Dashboard";
import HowItWorks from "./pages/HowItWorks";
import Pricing from "./pages/Pricing";
import ContactUs from "./pages/ContactUs";
import PageNotFound from "./pages/PageNotFound";
import AboutUs from "./pages/AboutUs";
import Newsletter from "./pages/newsletter";

import ReactGA from "react-ga4";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "./pages/Terms&Conditions/TermsConditions";
import HomePage from "./website-carechronicles/pages/Home/HomePage";
import EditorialPage from "./website-carechronicles/pages/Editorial/EditorialPage";
import TaggedEditorialsPage from "./website-carechronicles/pages/TaggedEditorials/TaggedEditorialsPage";
import AllDailyFeedPage from "./website-carechronicles/pages/AllDailyFeed/AllDailyFeedPage";
import AllEditorialsPage from "./website-carechronicles/pages/AllEditorials/AllEditorialsPage";
import Authors from "./website-carechronicles/pages/Authors/Authors";
import AboutUs1 from "./website-carechronicles/pages/About Us/AboutUs";
import ContactUs2 from "./website-carechronicles/pages/Contact Us/ContactUs";
import AllPartnerships from "./website-partnerships/pages/AllPartnerships/AllPartnerships";
import Authors2 from "./website-partnerships/pages/Authors/Authors";
import AboutUs2 from "./website-partnerships/pages/About Us/AboutUs";
import ContactUs3 from "./website-partnerships/pages/Contact Us/ContactUs";
import HomePage2 from "./website-partnerships/pages/Home/HomePage";
import PartnershipPage from "./website-partnerships/pages/Partnership/PartnershipPage";
import ClinicianBurnoutFoundation from "./pages/ClinicianBurnoutFoundation";
import DigitalTwinTechnology from "./pages/DigitalTwinTechnology";

const App = () => {
    const onBrowserResize = () => {
        BROWSER_DIMENSIONS_STORE.setDimensions(
            window.innerHeight,
            window.innerWidth
        );
    };

    const onBrowserScroll = () => {
        SCROLL_STORE.setYOffset(GetPageYOffset(window, document));
    };

    React.useEffect(() => {
        window.addEventListener("resize", onBrowserResize);
        window.addEventListener("scroll", onBrowserScroll);

        ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

        return () => {
            window.removeEventListener("resize", onBrowserResize);
            window.removeEventListener("scroll", onBrowserScroll);
        };
    }, []);

    return (
        <div>
            <Router>
                <Routes>
                    <Route path={routes.ROUTE_HOME} element={<Dashboard/>}/>
                    <Route path={routes.ROUTE_HOW_IT_WORKS} element={<HowItWorks/>}/>
                    <Route path={routes.ROUTE_PRICING} element={<Pricing/>}/>
                    <Route path={routes.ROUTE_CONTACT_US} element={<ContactUs/>}/>
                    <Route path={routes.ROUTE_PRIVACY_POLICY} element={<PrivacyPolicy/>}/>
                    <Route path={routes.ROUTE_TERMS_CONDITIONS} element={<TermsConditions/>}/>
                    <Route path={routes.ROUTE_ABOUT_US} element={<AboutUs/>}/>
                    <Route path={routes.ROUTE_CLINICIAN_BURNOUT_FOUNDATION} element={<ClinicianBurnoutFoundation/>}/>
                    <Route path={routes.ROUTE_DIGITAL_TWIN_TECHNOLOGY} element={<DigitalTwinTechnology/>}/>

                    <Route path={routes.ROUTE_CARECHRONICLES} element={<HomePage/>}/>
                    <Route path={`${routes.ROUTE_CARECHRONICLES}/:id`} element={<EditorialPage/>}/>
                    <Route path={`${routes.ROUTE_CARECHRONICLES}/tagged/:tag`} element={<TaggedEditorialsPage/>}/>
                    <Route path={`${routes.ROUTE_CARECHRONICLES}/dailyFeed`} element={<AllDailyFeedPage/>}/>
                    <Route path={`${routes.ROUTE_CARECHRONICLES}/allEditorials`} element={<AllEditorialsPage/>}/>
                    <Route path={`${routes.ROUTE_CARECHRONICLES}/authors`} element={<Authors/>}/>
                    <Route path={`${routes.ROUTE_CARECHRONICLES}/about-us`} element={<AboutUs1/>}/>
                    <Route path={`/newsletter`} element={<Newsletter/>}/>
                    <Route path={`${routes.ROUTE_CARECHRONICLES}/contact-us`} element={<ContactUs2/>}/>

                    <Route path={routes.ROUTE_PARTNERSHIPS} element={<HomePage2/>}/>
                    <Route path={`${routes.ROUTE_PARTNERSHIPS}/:id`} element={<PartnershipPage/>}/>
                    <Route path={`${routes.ROUTE_PARTNERSHIPS}/allPartnerships`} element={<AllPartnerships/>}/>
                    <Route path={`${routes.ROUTE_PARTNERSHIPS}/authors`} element={<Authors2/>}/>
                    <Route path={`${routes.ROUTE_PARTNERSHIPS}/about-us`} element={<AboutUs2/>}/>
                    <Route path={`${routes.ROUTE_PARTNERSHIPS}/contact-us`} element={<ContactUs3/>}/>

                    <Route path="*" element={<PageNotFound/>}/>
                </Routes>
            </Router>
        </div>
    );
};

export default App;
