import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  Link,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import Logo from "../../assets/images/nav_bar/logo1.webp";
import "./navbar_styles.css";
import { NAVIGATION_STORE } from "../../state/NAVIGATION_STORE";
import { BROWSER_DIMENSIONS_STORE } from "../../state/BROWSER_DIMENSIONS";
import { LARGE_SIZE } from "../../utils/ui-constants";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { observer } from "mobx-react";
import {
  NAV_CONTACT_US,
  NAV_DIGITAL_TWIN_TECH,
  NAV_HOW_IT_WORKS,
  NAV_NEWSLETTER,
} from "../../utils/nav-constants";
import * as routes from "../../utils/ui-routes";
import * as colors from "../../utils/colors";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

const NavigationBar = observer(() => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);

  const toggleMenu = () => {
    setOpen(!open);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleMenuClose1 = () => {
    setAnchorEl1(null);
  };

  const openMenu = Boolean(anchorEl);
  const openMenu1 = Boolean(anchorEl1);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    let timeoutId;

    const handleScroll = () => {
      setIsScrolled(true);
      clearTimeout(timeoutId);
      // timeoutId = setTimeout(() => {
      //     setIsScrolled(false);
      // }, 500);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ pt: 1, pb: 1 }}
      rowSpacing={1}
      style={{
        zIndex: 100,
        position: "fixed",
        display: "flex",
        flexDirection: "row",
        backgroundColor:
          BROWSER_DIMENSIONS_STORE.getDimensions.width <= LARGE_SIZE
            ? colors.MAIN_BACKGROUND
            : isScrolled
            ? colors.MAIN_BACKGROUND
            : "transparent",
        transition: "opacity 0.1s ease-out",
      }}
    >
      {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE ? (
        <>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 0.2,
              flexDirection: "row",
              paddingTop: "10px",
              paddingLeft: "2%",
            }}
          >
            <img
              src={Logo}
              alt="Fitmedik"
              onClick={() => navigate(routes.ROUTE_HOME)}
              className="navBar-logo center-fit"
            />
          </Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "60px",
              flex: 0.52,
              flexDirection: "row",
              paddingTop: "10px",
            }}
          >
            <Link
              underline="hover"
              style={{
                color: "white",
                opacity:
                  NAVIGATION_STORE.getScreen === NAV_HOW_IT_WORKS ? 1 : 0.5,
              }}
              className="link-text"
              href={routes.ROUTE_HOW_IT_WORKS}
              onClick={() => NAVIGATION_STORE.setScreen(NAV_HOW_IT_WORKS)}
            >
              {BROWSER_DIMENSIONS_STORE.getDimensions.width < 1500
                ? "How it Works"
                : "How Co-Pilot Works"}
            </Link>
            <Link
              alignSelf={"center"}
              underline="hover"
              style={{
                color: "white",
                opacity:
                  NAVIGATION_STORE.getScreen === NAV_DIGITAL_TWIN_TECH
                    ? 1
                    : 0.5,
              }}
              className="link-text"
              href={routes.ROUTE_DIGITAL_TWIN_TECHNOLOGY}
              onClick={() => NAVIGATION_STORE.setScreen(NAV_DIGITAL_TWIN_TECH)}
            >
              {BROWSER_DIMENSIONS_STORE.getDimensions.width < 1500
                ? "Twin Technology"
                : "Digital Twin Technology"}
            </Link>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Link
                alignSelf="center"
                underline="hover"
                style={{
                  color: "white",
                  cursor: "pointer",
                }}
                className="link-text"
                onClick={handleMenuOpen}
              >
                Resources
                <ArrowDropDownIcon />
              </Link>
              <Menu
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleMenuClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                sx={{ "& .MuiMenu-paper": { backgroundColor: "#282C34" } }}
              >
                <MenuItem
                  onClick={() => {
                    navigate(routes.ROUTE_CARECHRONICLES);
                    window.scrollTo(0, 0);
                    handleMenuClose();
                  }}
                >
                  <ListItemText
                    primary={
                      <h1 className={"text-primary"}>Care Chronicles</h1>
                    }
                    secondary={
                      <h1 className={"text-secondary"}>
                        Insights Shaping Healthcare Leadership
                      </h1>
                    }
                  />
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/newsletter");
                    window.scrollTo(0, 0);
                    handleMenuClose();
                  }}
                >
                  <ListItemText
                    primary={<h1 className={"text-primary"}>Newsletter</h1>}
                    secondary={
                      <h1 className={"text-secondary"}>
                        Get biweekly updates to make informed decisions
                      </h1>
                    }
                  />
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => {
                    navigate(routes.ROUTE_PARTNERSHIPS);
                    window.scrollTo(0, 0);
                    handleMenuClose();
                  }}
                >
                  <ListItemText
                    primary={<h1 className={"text-primary"}>Partnerships</h1>}
                    secondary={
                      <h1 className={"text-secondary"}>
                        Fostering Collaborative Healthcare Innovation
                      </h1>
                    }
                  />
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate(routes.ROUTE_CLINICIAN_BURNOUT_FOUNDATION);
                    window.scrollTo(0, 0);
                    handleMenuClose();
                  }}
                >
                  <ListItemText
                    primary={
                      <h1 className={"text-primary"}>
                        Clinician Burnout Foundation
                      </h1>
                    }
                    secondary={
                      <h1 className={"text-secondary"}>
                        Autopilot burnout monitoring with AI
                      </h1>
                    }
                  />
                </MenuItem>
              </Menu>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Link
                alignSelf="center"
                underline="hover"
                style={{
                  color: "white",
                  cursor: "pointer",
                }}
                className="link-text"
                onClick={handleMenuOpen1}
              >
                About Us
                <ArrowDropDownIcon />
              </Link>
              <Menu
                anchorEl={anchorEl1}
                open={openMenu1}
                onClose={handleMenuClose1}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                sx={{ "& .MuiMenu-paper": { backgroundColor: "#282C34" } }}
              >
                <MenuItem
                  onClick={() => {
                    navigate(routes.ROUTE_ABOUT_US);
                    window.scrollTo(0, 0);
                    handleMenuClose1();
                  }}
                >
                  <ListItemText
                    primary={<h1 className={"text-primary"}>About Us</h1>}
                  />
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => {
                    navigate(routes.ROUTE_CONTACT_US);
                    window.scrollTo(0, 0);
                    handleMenuClose1();
                  }}
                >
                  <ListItemText
                    primary={<h1 className={"text-primary"}>Contact Us</h1>}
                  />
                </MenuItem>
              </Menu>
            </div>
          </Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 0.28,
              flexDirection: "row",
              paddingTop: "10px",
              paddingRight: "2%",
              gap: "10px",
            }}
          >
            <div
              className="btn-left"
              onClick={() => {
                navigate(routes.ROUTE_GET_FREE_TRIAL);
                window.scrollTo(0, 0);
                NAVIGATION_STORE.setScreen(NAV_CONTACT_US);
              }}
            />
            <div
              className="btn-right"
              onClick={() => {
                window.open("https://organization.fitmedik.com/", "_self");
              }}
            />
          </Box>
        </>
      ) : (
        <>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            item
            xs={
              BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE ? 3 : 6
            }
          >
            <img
              src={Logo}
              alt="Fitmedik"
              onClick={() => navigate(routes.ROUTE_HOME)}
              className="navBar-logo center-fit"
            />
          </Grid>
          {BROWSER_DIMENSIONS_STORE.getDimensions.width < LARGE_SIZE && (
            <Grid
              container
              item
              xs={6}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={5} />
              <Grid
                container
                item
                xs={5}
                justifyContent="center"
                alignItems="center"
              >
                <div className="menu" onClick={toggleMenu}>
                  <MenuIcon style={{ color: "white" }} />
                </div>
              </Grid>
              <Grid item xs={2} />
            </Grid>
          )}
          {(BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE ||
            open) && (
            <>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                item
                lg={1}
                sx={{ paddingBottom: "10px" }}
              >
                <Link
                  underline="hover"
                  style={{
                    color: "white",
                    opacity:
                      NAVIGATION_STORE.getScreen === NAV_HOW_IT_WORKS ? 1 : 0.5,
                  }}
                  className="link-text"
                  href={routes.ROUTE_HOW_IT_WORKS}
                  onClick={() => NAVIGATION_STORE.setScreen(NAV_HOW_IT_WORKS)}
                >
                  How Co-Pilot Works
                </Link>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                item
                lg={1}
                sx={{ paddingBottom: "10px" }}
              >
                <Link
                  underline="hover"
                  style={{
                    color: "white",
                    opacity:
                      NAVIGATION_STORE.getScreen === NAV_DIGITAL_TWIN_TECH
                        ? 1
                        : 0.5,
                  }}
                  className="link-text"
                  href={routes.ROUTE_DIGITAL_TWIN_TECHNOLOGY}
                  onClick={() =>
                    NAVIGATION_STORE.setScreen(NAV_DIGITAL_TWIN_TECH)
                  }
                >
                  Digital Twin Technology
                </Link>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                item
                lg={1}
                sx={{ paddingBottom: "10px" }}
              >
                <Link
                  underline="hover"
                  style={{
                    color: "white",
                    opacity:
                      NAVIGATION_STORE.getScreen === NAV_DIGITAL_TWIN_TECH
                        ? 1
                        : 0.5,
                  }}
                  className="link-text"
                  href={routes.ROUTE_DIGITAL_TWIN_TECHNOLOGY}
                  onClick={() =>
                    NAVIGATION_STORE.setScreen(NAV_DIGITAL_TWIN_TECH)
                  }
                >
                  Digital Twin Technology
                </Link>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                item
                lg={1}
                sx={{ paddingBottom: "10px" }}
              >
                <Link
                  alignSelf="center"
                  underline="hover"
                  style={{
                    color: "white",
                    cursor: "pointer",
                  }}
                  className="link-text"
                  onClick={handleMenuOpen}
                >
                  Newsletter
                  <ArrowDropDownIcon />
                </Link>
                <Menu
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleMenuClose}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  sx={{ "& .MuiMenu-paper": { backgroundColor: "#282C34" } }}
                >
                  <MenuItem
                    onClick={() => {
                      navigate(routes.ROUTE_CARECHRONICLES);
                      window.scrollTo(0, 0);
                      handleMenuClose();
                    }}
                  >
                    <ListItemText
                      primary={
                        <h1 className={"text-primary"}>Care Chronicles</h1>
                      }
                      secondary={
                        <h1 className={"text-secondary"}>
                          Insights Shaping Healthcare Leadership
                        </h1>
                      }
                    />
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      navigate(routes.ROUTE_PARTNERSHIPS);
                      window.scrollTo(0, 0);
                      handleMenuClose();
                    }}
                  >
                    <ListItemText
                      primary={<h1 className={"text-primary"}>Partnerships</h1>}
                      secondary={
                        <h1 className={"text-secondary"}>
                          Fostering Collaborative Healthcare Innovation
                        </h1>
                      }
                    />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      navigate(routes.ROUTE_CLINICIAN_BURNOUT_FOUNDATION);
                      window.scrollTo(0, 0);
                      handleMenuClose();
                    }}
                  >
                    <ListItemText
                      primary={
                        <h1 className={"text-primary"}>
                          Clinician Burnout Foundation
                        </h1>
                      }
                      secondary={
                        <h1 className={"text-secondary"}>
                          Autopilot burnout monitoring with AI
                        </h1>
                      }
                    />
                  </MenuItem>
                </Menu>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                item
                lg={1}
                sx={{ paddingBottom: "10px" }}
              >
                <Link
                  alignSelf="center"
                  underline="hover"
                  style={{
                    color: "white",
                    cursor: "pointer",
                  }}
                  className="link-text"
                  onClick={handleMenuOpen1}
                >
                  About Us
                  <ArrowDropDownIcon />
                </Link>
                <Menu
                  anchorEl={anchorEl1}
                  open={openMenu1}
                  onClose={handleMenuClose1}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  sx={{ "& .MuiMenu-paper": { backgroundColor: "#282C34" } }}
                >
                  <MenuItem
                    onClick={() => {
                      navigate(routes.ROUTE_ABOUT_US);
                      window.scrollTo(0, 0);
                      handleMenuClose1();
                    }}
                  >
                    <ListItemText
                      primary={<h1 className={"text-primary"}>About Us</h1>}
                    />
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      navigate(routes.ROUTE_CONTACT_US);
                      window.scrollTo(0, 0);
                      handleMenuClose1();
                    }}
                  >
                    <ListItemText
                      primary={<h1 className={"text-primary"}>Contact Us</h1>}
                    />
                  </MenuItem>
                </Menu>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                item
                lg={4}
                sx={{ paddingBottom: "10px" }}
              >
                <div
                  className="button button-left navBar-button"
                  onClick={() => {
                    navigate(routes.ROUTE_GET_FREE_TRIAL);
                    window.scrollTo(0, 0);
                    NAVIGATION_STORE.setScreen(NAV_CONTACT_US);
                  }}
                />
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                item
                lg={4}
              >
                <div
                  className="btn-right"
                  onClick={() => {
                    window.open("https://organization.fitmedik.com/", "_self");
                  }}
                />
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
});

export default NavigationBar;
