import React from "react";
import AllPosts from "../../components/AllPosts/AllPosts";
import Footer from "../../components/Footer/Footer";
import "./styles.css";
import {Box, createTheme, Typography} from "@mui/material";
import Colors from "../../utils/Colors";
import TrialCard from "../../components/TrialCard/TrialCard";
import Navbar from "../../components/Navbar/Navbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Helmet} from "react-helmet";

const HomePage = () => {

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    return (
        <>
            <Navbar/>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{"Fitmedik Partnerships"}</title>
                <meta name="description" content={"Partnerships"}/>
            </Helmet>
            <div className={"db-main-bg"} style={{minHeight: '100vh'}}>
                <Box style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    paddingTop: isLaptop || isDesktop ? '8%' : '20%'
                }}>
                    <Typography
                        sx={{color: 'white', fontWeight: '700', marginBottom: '20px', textAlign: 'center'}}
                        gutterBottom
                        variant="h3"
                        component="div"
                    >
                        Fitmedik Partnerships
                    </Typography>
                    <Typography
                        sx={{color: Colors.GREY, textAlign: 'center'}}
                        gutterBottom
                        variant="subtitle"
                        component="div"
                    >
                        Fostering Collaborative Healthcare Innovation
                    </Typography>
                </Box>
                <AllPosts/>
            </div>
            <TrialCard/>
            <Footer/>
        </>
    )
}

export default HomePage