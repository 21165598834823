import React, {useEffect, useState} from "react";
import TagsFilter from "../../components/TagsFilter/TagsFilter";
import {useLocation} from "react-router-dom";
import axios from "axios";
import {BASE_API_URL} from "../../utils/Constants";
import EditorialDetails from "../../components/EditorialDetails/EditorialDetails";
import {createTheme, Grid} from "@mui/material";
import RightBarEditorials from "../../components/RightBarEditorials/RightBarEditorials";
import Footer from "../../components/Footer/Footer";
import BottomBarEditorials from "../../components/BottomBarEditorials/BottomBarEditorials";
import "./styles.css";
import {Helmet} from "react-helmet";
import NavBar from "../../../components/NavBar";
import useMediaQuery from "@mui/material/useMediaQuery";

const EditorialPage = () => {

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    const location = useLocation()
    const [editorial, setEditorial] = useState(null)

    const getEditorial = async () => {
        try {
            const searchParams = new URLSearchParams(location.search);
            const id = searchParams.get('id')
            await axios.get(`${BASE_API_URL}/editorials/getEditorial/${id}`)
                .then((response) => setEditorial(response.data.editorial))
                .catch((e) => console.error(e))
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        getEditorial()
    }, []);

    return (
        <>
            <NavBar/>
            <div className={"db-main-bg"} style={{paddingLeft: '1%', paddingRight: '1%', minHeight: '100vh'}}>
                {editorial === null ? (
                    <h3>Loading...</h3>
                ) : (
                    <>
                        <Helmet>
                            <meta charSet="utf-8"/>
                            <title>{editorial.metaTitle}</title>
                            <meta
                                name="description"
                                content={editorial.metaDesc}
                            />
                        </Helmet>
                        <Grid container columnSpacing={2}
                              sx={{
                                  paddingTop: isLaptop || isDesktop ? '5%' : '16%',
                                  paddingLeft: '5%',
                                  paddingRight: '5%',
                                  marginBottom: '20px',
                                  minHeight: '100vh'
                              }}>
                            <Grid item xs={12} md={9}>
                                <EditorialDetails editorial={editorial}/>
                            </Grid>
                            {isTablet || isLaptop || isDesktop ? (
                                <Grid item xs={12} md={3} sx={{marginTop: '20px'}}>
                                    <RightBarEditorials/>
                                </Grid>
                            ) : null}
                        </Grid>
                    </>
                )}
                <BottomBarEditorials/>
            </div>
            <Footer/>
        </>

    )
}

export default EditorialPage