import {observer} from "mobx-react";
import React, {useEffect} from "react";
import {NAVIGATION_STORE} from "../../state/NAVIGATION_STORE";
import {NAV_DIGITAL_TWIN_TECH} from "../../utils/nav-constants";
import ReactGA from "react-ga4";
import {intercom_initialiser} from "../../utils/intercom_initializer";
import * as colors from "../../utils/colors";
import {Helmet} from "react-helmet";
import {metaData} from "../../utils/meta-constants";
import NavigationBar from "../../components/NavBar";
import {Grid} from "@mui/material";
import DashboardFooter from "../Dashboard/Footer";
import "./styles.css";
import First from "./First";
import Second from "./Second";
import Third from "./Third";

const DigitalTwinTechnology = observer(() => {

    useEffect(() => {
        NAVIGATION_STORE.setScreen(NAV_DIGITAL_TWIN_TECH);
        ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
        ReactGA.send("pageview");
        intercom_initialiser();
    }, []);

    return (
        <div className="container" style={{backgroundColor: colors.MAIN_BACKGROUND}}>
            <Helmet>
                <meta name="title" content={metaData.digitalTwinTechnology.title}/>
                <meta name="description" content={metaData.digitalTwinTechnology.description}/>
                <title>{metaData.digitalTwinTechnology.title}</title>
            </Helmet>
            <NavigationBar/>
            <Grid container>
                <First/>
                <Second/>
                <Third/>
                <DashboardFooter/>
            </Grid>
        </div>
    );
})

export default DigitalTwinTechnology