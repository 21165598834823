import { action, computed, makeObservable, observable } from "mobx";
import { NAV_HOME } from "../utils/nav-constants";

class NavigationStore {
  state = {
    currentScreen: NAV_HOME,
  };

  constructor() {
    makeObservable(this, {
      state: observable,
      setScreen: action,
      getScreen: computed,
    });
  }

  setScreen = (screen = NAV_HOME) => {
    this.state.currentScreen = screen;
  };

  get getScreen() {
    return this.state.currentScreen;
  }
}

export const NAVIGATION_STORE = new NavigationStore();
