import React from "react";
import "./styles.css";
import {createTheme, Grid, useMediaQuery} from "@mui/material";
import * as routes from "../../../utils/ui-routes";
import {observer} from "mobx-react";
import {BROWSER_DIMENSIONS_STORE} from "../../../state/BROWSER_DIMENSIONS";
import {useNavigate} from "react-router-dom";
import {navigateToExternal} from "../../../utils/helper-functions";
import backgroundImage from "../../../assets/images/dashboard/background.png";
import Fade from "react-reveal/Fade";

const delay = async (time_in_millis = 500) => {
    return new Promise((resolve) => setTimeout(resolve, time_in_millis));
};

const DashboardMain = observer(() => {
    const ref = React.useRef(null);

    const scrollDown = () => {
        if (ref === null || ref.current === null) {
            console.log("Cannot scroll", ref);
        } else {
            const height = ref.current.clientHeight;
            console.log(height);
            window.scrollTo({top: height, behavior: "smooth"});
        }
    };

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    const navigate = useNavigate();

    return (
        <div
            ref={ref}
            style={{
                zIndex: 1,
                backgroundColor: '#131519',
                backgroundImage: isLaptop || isDesktop ? `url(${backgroundImage})` : null,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                minHeight: isMobile || isBigMobile ? '95vh' : isTablet ? '70vh' : '100vh',
                backgroundPosition: "center",
                paddingLeft: "14%",
            }}
            className="db-main-bg"
        >
            <Grid
                container
                flexDirection="column"
                sx={{pt: isMobile || isBigMobile || isTablet ? '26%' : isLaptop ? '13%' : '25%',}}
            >
                <Grid item container xs={12} rowSpacing={10}>
                    <Grid
                        item
                        container
                        marginLeft={"-15%"}
                        zIndex={99}
                        xs={12}
                        lg={8}
                        sx={{
                            pl: BROWSER_DIMENSIONS_STORE.getDimensions.width >= 600 ? 14 : 4,
                            pr: BROWSER_DIMENSIONS_STORE.getDimensions.width >= 600 ? 14 : 4,
                        }}
                    >
                        <Fade>
                            <label className="db_main_heading">
                                Helping care facilities
                                <br/>
                                <span className="db_main_heading db_main_heading_highlight_orange">
                                    {" "}retain{" "}
                                </span>
                                their superheroes.
                            </label>
                        </Fade>
                        <br/>
                        <Fade>
                            <label style={{marginTop: '20px'}} className="monitor-sub-text">
                                Proactive Excellence, Not Reactive Surveys
                            </label>
                            <label style={{marginTop: '16px'}} className="monitor-sub-text">
                                Copilot your wellbeing & engagement efforts with AI to retain care workers and fortify
                                the bottom line.
                            </label>
                        </Fade>
                        {isMobile || isBigMobile || isTablet ? (
                            <div style={{display: 'flex', flexDirection: 'column', paddingTop: '30px'}}>
                                <div
                                    className="db-main-button db-main-button-left"
                                    onClick={() => navigate(routes.ROUTE_GET_FREE_TRIAL)}
                                />
                                <div style={{height: '20px'}}/>
                                <div
                                    className="db-main-button db-main-button-right"
                                    onClick={() => navigateToExternal(routes.ROUTE_BOOK_A_DEMO)}
                                />
                            </div>
                        ) : (
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingTop: '40px'
                            }}>
                                <div
                                    className="db-main-button db-main-button-left"
                                    onClick={() => navigate(routes.ROUTE_GET_FREE_TRIAL)}
                                />
                                <div style={{width: '20px'}}/>
                                <div
                                    className="db-main-button db-main-button-right"
                                    onClick={() => navigateToExternal(routes.ROUTE_BOOK_A_DEMO)}
                                />
                            </div>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
});

export default DashboardMain;
