import Lock from "../../../assets/images/how_it_works/lock.svg";
import TrendingUp from "../../../assets/images/how_it_works/trending-up.svg";
import SSL from "../../../assets/images/how_it_works/ssl.svg";
import Overview from "../../../assets/images/how_it_works/overview.svg";

export const data = [
  {
    logo: Lock,
    title1: "Anonymized",
    title2: "Data",
    content:
      "All the processed data is anaonymized and stored at securved servers",
  },
  {
    logo: TrendingUp,
    title1: "HIPAA",
    title2: "Compliant",
    content:
      "Fitmedik is complaint with all the regulations required from HIPPA",
  },
  {
    logo: SSL,
    title1: "Encrypted SSL",
    title2: "Certificate",
    content: "All the analyzed data on the dashboard is SSL encrypted",
  },
  {
    logo: Overview,
    title1: "Departmental",
    title2: "overview",
    content: "Management does not have access to personal data of providers",
  },
];
