import {Alert, createTheme, Grid, Snackbar} from "@mui/material";
import React from "react";
import "./styles.css";
import ContactSoon from "../../assets/images/contact_us/nurse.png";
import Logo from "../../assets/images/nav_bar/logo1.svg";
import "../../components/GlassmorphBG/glassmorph_styles.css";
import ContactUsInputText from "./InputText";
import emailjs from "@emailjs/browser";
import {observer} from "mobx-react";
import {BROWSER_DIMENSIONS_STORE} from "../../state/BROWSER_DIMENSIONS";
import {NAVIGATION_STORE} from "../../state/NAVIGATION_STORE";
import {NAV_CONTACT_US} from "../../utils/nav-constants";
import NavigationBar from "../../components/NavBar";
import {GLOBAL_TOP_MARGIN} from "../../utils/ui-constants";
import {metaData} from "../../utils/meta-constants";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga4";
import {intercom_initialiser} from "../../utils/intercom_initializer";
import useMediaQuery from "@mui/material/useMediaQuery";

const ContactUs = observer(() => {
    const formRef = React.useRef(null);

    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [success, setSuccess] = React.useState(true);
    const [text, setText] = React.useState("");

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    React.useEffect(() => {
        NAVIGATION_STORE.setScreen(NAV_CONTACT_US);
        ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
        ReactGA.send("pageview");
        intercom_initialiser();
    }, []);

    const sendMail = async (e) => {
        try {
            setLoading(true);
            e.preventDefault();
            const response = await emailjs.sendForm(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EMAILJS_TEMPLATE_ID_CONTACT_US,
                formRef.current,
                process.env.REACT_APP_EMAILJS_PUBLIC_KEY
            );
            setLoading(false);
            setOpen(true);
            setSuccess(true);
            setText("E-mail sent successfully");

            console.log(formRef.current);
        } catch (e) {
            console.log(e);
            setLoading(false);
            setOpen(true);
            setSuccess(false);
            setText(
                e.response && e.response.text ? e.response.text : "An error occured"
            );
        }
    };

    return (
        <>
            <div
                style={{
                    backgroundColor: "#181B21",
                    zIndex: 1,
                    width: "100vw",
                    minHeight: "100vh",
                    maxWidth: '100%',
                    overflowX: 'hidden'
                }}
            >
                <NavigationBar/>
                <Helmet>
                    <meta name="title" content={metaData.contactUs.title}/>
                    <meta name="description" content={metaData.contactUs.description}/>
                    <title>{metaData.contactUs.title}</title>
                    <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11422412962"></script>
                    <script>
                        {
                            `
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                            gtag('config', 'AW-11422412962');
                            `
                        }
                    </script>
                </Helmet>
                <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={() => setOpen(false)}
                >
                    <Alert
                        severity={success === true ? "success" : "error"}
                        onClose={() => setOpen(false)}
                        sx={{width: "100%"}}
                    >
                        {text}
                    </Alert>
                </Snackbar>
                {BROWSER_DIMENSIONS_STORE.getDimensions.width >= 600 && (
                    <div className="contact-us-ellipse-1 blur400"/>
                )}
                <div className="contact-us-ellipse-2 blur400"/>
                <Grid container className="contact-us-main-bg">
                    <Grid container sx={{pb: 8, pt: GLOBAL_TOP_MARGIN}}>
                        <Grid item xs={1}/>
                        <Grid container item xs={10} rowSpacing={2}>
                            <Grid container item lg={5} xs={12} className="glassmorph_style">
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <img src={Logo} alt="fitmedik" className="center-fit"/>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <img
                                        src={ContactSoon}
                                        alt="we will get in touch soon"
                                        className="center-fit"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={1}/>
                            {/* FORM */}
                            <Grid
                                container
                                item
                                lg={6}
                                xs={12}
                                className="contact-us-card-bg"
                                sx={{
                                    paddingRight: isMobile || isBigMobile ? 2 : 4,
                                    paddingLeft: isMobile || isBigMobile ? 1.3 : 4,
                                    paddingY: 4
                                }}
                            >
                                <Grid item xs={12} sx={{mt: 3}}>
                                    <label className="contact-us-header">Tell us about Yourself</label>
                                </Grid>
                                <form onSubmit={(e) => sendMail(e)} ref={formRef}>
                                    <ContactUsInputText loading={loading}/>
                                </form>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}/>
                    </Grid>
                </Grid>
            </div>
        </>
    );
});

export default ContactUs;
