import { Grid } from "@mui/material";
import React from "react";
import * as colors from "../../utils/colors";
import PricingMain from "./Main";
import PricingSecond from "./Second";
import DashboardFooter from "../Dashboard/Footer";
import NavigationBar from "../../components/NavBar";
import { NAVIGATION_STORE } from "../../state/NAVIGATION_STORE";
import { NAV_PRICING } from "../../utils/nav-constants";
import { metaData } from "../../utils/meta-constants";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import { intercom_initialiser } from "../../utils/intercom_initializer";

const Pricing = () => {
  React.useEffect(() => {
    NAVIGATION_STORE.setScreen(NAV_PRICING);
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
    ReactGA.send("pageview");
    intercom_initialiser();
  }, []);

  return (
    <div
      style={{
        zIndex: 1,
        backgroundColor: colors.MAIN_BACKGROUND,
        width: "100vw",
        maxWidth: '100%',
        overflowX: 'hidden'
      }}
    >
      <Helmet>
        <meta name="title" content={metaData.pricing.title} />
        <meta name="description" content={metaData.pricing.description} />
        <title>{metaData.pricing.title}</title>
      </Helmet>
      <NavigationBar />
      <Grid container>
        <PricingMain />
        <PricingSecond />
        <DashboardFooter />
      </Grid>
    </div>
  );
};

export default Pricing;
