import {Grid, Link} from "@mui/material";
import React from "react";
import TrialCard from "../../../components/TrialCard";
import LogoRed from "../../../assets/images/footer/logo-red.svg";
import * as routes from "../../../utils/ui-routes";
import "./db_footer_styles.css";
import {BROWSER_DIMENSIONS_STORE} from "../../../state/BROWSER_DIMENSIONS";

const DashboardFooter = () => {
    return (
        <div
            style={{
                backgroundColor: "#13161B",
                zIndex: 2,
                width: "100vw",
            }}
        >
            <TrialCard/>
            <Grid container item xs={12} sx={{mt: 24}}>
                <Grid item xs={1}/>
                <Grid container item xs={10} spacing={4}>
                    <Grid
                        item
                        container
                        lg={6}
                        md={12}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={12}>
                            <img src={LogoRed} alt="logo red"/>
                        </Grid>
                        <Grid item xs={12} sx={{pt: 3}}>
                            <label className="footer-text">
                                Helping care facilities retain their superheroes.
                            </label>
                        </Grid>
                    </Grid>
                    <Grid item container lg={6} md={12} sx={{pt: 4}}>
                        <Grid item xs={12}>
                            <label className="footer-header">Useful Links</label>
                        </Grid>
                        <Grid item container xs={12} sx={{mt: 3}}>
                            <Grid container item xs={6} rowSpacing={3}>
                                <Grid item xs={12}>
                                    <Link
                                        style={{color: "white"}}
                                        underline="hover"
                                        className="footer-text"
                                        href={routes.ROUTE_HOME}
                                    >
                                        Home
                                    </Link>
                                </Grid>
                                <Grid item xs={12}>
                                    <Link
                                        style={{color: "white"}}
                                        underline="hover"
                                        className="footer-text"
                                        href={routes.ROUTE_HOW_IT_WORKS}
                                    >
                                        How Co-Pilot Works
                                    </Link>
                                </Grid>
                                <Grid item xs={12}>
                                    <Link
                                        style={{color: "white"}}
                                        underline="hover"
                                        className="footer-text"
                                        href={routes.ROUTE_DIGITAL_TWIN_TECHNOLOGY}
                                    >
                                        {BROWSER_DIMENSIONS_STORE.getDimensions.width <= 900 ? 'Twin Technology' : 'Digital Twin Technology'}
                                    </Link>
                                </Grid>
                                <Grid item xs={12}>
                                    <Link
                                        style={{color: "white"}}
                                        underline="hover"
                                        className="footer-text"
                                        href={routes.ROUTE_CARECHRONICLES}
                                    >
                                        Care Chronicles
                                    </Link>
                                </Grid>
                            </Grid>
                            <Grid container item xs={6} rowSpacing={3}>
                                <Grid item xs={12}>
                                    <Link
                                        style={{color: "white"}}
                                        underline="hover"
                                        className="footer-text"
                                        href={routes.ROUTE_ABOUT_US}
                                    >
                                        About Us
                                    </Link>
                                </Grid>
                                <Grid item xs={12}>
                                    <Link
                                        style={{color: "white"}}
                                        underline="hover"
                                        className="footer-text"
                                        href={routes.ROUTE_CONTACT_US}
                                    >
                                        Contact Us
                                    </Link>
                                </Grid>
                                <Grid item xs={12}>
                                    <Link
                                        style={{color: "white"}}
                                        underline="hover"
                                        className="footer-text"
                                        href={routes.ROUTE_PRIVACY_POLICY}
                                    >
                                        Privacy Policy
                                    </Link>
                                </Grid>
                                <Grid item xs={12}>
                                    <Link
                                        style={{color: "white"}}
                                        underline="hover"
                                        className="footer-text"
                                        href={routes.ROUTE_TERMS_CONDITIONS}
                                    >
                                        Terms and Conditions
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1}/>
            </Grid>
            <Grid
                item
                xs={12}
                container
                sx={{backgroundColor: "#13161B", mt: 9, pt: 1.5, pb: 1.5}}
                justifyContent="center"
                alignItems="center"
            >
                <label className="footer-rights-text">
                    {"©2024 Fitmedik. All rights reserved"}
                </label>
            </Grid>
        </div>
    );
};

export default DashboardFooter;
