import React from "react";
import "./styles.css";
import Footer from "../../components/Footer/Footer";
import {Box, Typography} from "@mui/material";
import {Helmet} from "react-helmet";
import Navbar from "../../components/Navbar/Navbar";

const AboutUs = () => {
    return (
        <>
            <Navbar/>
            <div className={"db-main-bg"} style={{minHeight: '100vh'}}>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{"About Us"}</title>
                    <meta
                        name="description"
                        content={"Fitmedik blog about us"}
                    />
                </Helmet>
                <Box style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '6%',
                    paddingBottom: '2%',
                    paddingLeft: '8.5%',
                    paddingRight: '8.5%',
                    minHeight: '100vh'
                }}>
                    <Typography
                        sx={{color: 'white', fontWeight: '700', marginBottom: '40px', fontFamily: 'Acumin Pro, sans-serif'}}
                        gutterBottom
                        variant="h4"
                        component="div"
                    >
                        About Us
                    </Typography>
                    <Typography
                        sx={{color: 'white', fontSize: 18}}
                        gutterBottom
                        variant="body1"
                        component="div"
                    >
                        The Care Chronicle is dedicated to providing valuable and insightful information that empowers
                        non-medical
                        professionals in the healthcare sector. Whether you are a senior-level official in a reputed
                        hospital or
                        someone starting their journey In this field. The Care Chronicle aims to equip you with the
                        knowledge and
                        the necessary tools to understand and optimize the performance of your Hospital.
                        We equip you with the latest developments and disruptions in the Healthcare ecosystem while
                        keeping
                        true
                        to the core of ethical managerial values.
                    </Typography>
                </Box>
            </div>
            <Footer/>
        </>
    )
}

export default AboutUs