export const metaData = {
  home: {
    title: "Fitmedik | Manage Burnout of your Hospital",
    description:
      "Fitmedik is a solution that can help you Monitor and Manage Burnout of your healthcare workers in real-time. As we know Hospitals are under constant pressure to stay afloat and provide quality healthcare to their patients. But with the increasing demands on healthcare workers, burnout is becoming a major issue.",
  },
  hiw: {
    title: "Fitmedik | How it Works",
    description:
      "At Fitmedik, we understand the Impact of Burnout and provide evidence-based solutions to help you cope. Our Treatment of Burnout plans focus on physical, mental and emotional health to ensure long-term recovery. Contact us today to learn more!",
  },
  pricing: {
    title: "Fitmedik | Pricing",
    description: "Pricing",
  },
  contactUs: {
    title: "Fitmedik | Contact Us",
    description: "Contact Us",
  },
  privacyPolicy: {
    title: "Fitmedik | Privacy Policy",
    description: "Privacy Policy",
  },
  termsAndConditions: {
    title: "Fitmedik | Terms & Conditions",
    description: "Terms & Conditions",
  },
  clinicianBurnoutFoundation: {
    title: "Fitmedik | Clinician Burnout Foundation",
    description: "Clinician Burnout Foundation",
  },
  aboutUs: {
    title: "Fitmedik | About Us",
    description: "About Fitmedik",
  },
  digitalTwinTechnology: {
    title: "Fitmedik | Digital Twin Technology",
    description: "Fitmedik | Digital Twin Technology",
  },
  newsletter: {
    title: "Fitmedik | Newsletter",
    description:
      "Subscribe to our newsletter to stay updated with the latest news and updates from Fitmedik.",
  },
};
