export const ROUTE_HOME = "/";
export const ROUTE_HOW_IT_WORKS = "/how-it-works";
export const ROUTE_PRICING = "/pricing";
export const ROUTE_GET_FREE_TRIAL = "/contact-us";
export const ROUTE_CONTACT_US = "/contact-us";
export const ROUTE_PRIVACY_POLICY = "/privacy-policy"
export const ROUTE_TERMS_CONDITIONS = "/terms-and-conditions"
export const ROUTE_CARECHRONICLES = "/carechronicles"
export const ROUTE_PARTNERSHIPS = "/partnerships"
export const ROUTE_CLINICIAN_BURNOUT_FOUNDATION = "/clinician-burnout-foundation"
export const ROUTE_ABOUT_US = "/about-us"
export const ROUTE_DIGITAL_TWIN_TECHNOLOGY = "/digital-twin-technology"
export const ROUTE_BOOK_A_DEMO = "https://calendly.com/fitmedik_intro/30min";