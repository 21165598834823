import React, {useEffect, useState} from "react";
import TagsFilter from "../../components/TagsFilter/TagsFilter";
import {useLocation} from "react-router-dom";
import axios from "axios";
import {BASE_API_URL} from "../../utils/Constants";
import PartnershipDetails from "../../components/PartnershipDetails/PartnershipDetails";
import {createTheme, Grid, LinearProgress} from "@mui/material";
import Footer from "../../components/Footer/Footer";
import BottomBarBlogs from "../../components/BottomBarBlogs/BottomBarBlogs";
import "./styles.css";
import {Helmet} from "react-helmet";
import useMediaQuery from "@mui/material/useMediaQuery";
import TrialCard from "../../components/TrialCard/TrialCard";
import Navbar from "../../components/Navbar/Navbar";

const PartnershipPage = () => {

    const location = useLocation()
    const [partnership, setPartnership] = useState(null)
    const [loading, setLoading] = useState(false)

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    let padding = '1%';

    if (isMobile) {
        padding = '1%'
    } else if (isBigMobile) {
        padding = '2%'
    } else if (isTablet) {
        padding = '3%'
    } else if (isLaptop) {
        padding = '8.5%'
    } else if (isDesktop) {
        padding = '8.5%'
    }

    const getPartnership = async () => {
        setLoading(true)
        try {
            const searchParams = new URLSearchParams(location.search);
            const id = searchParams.get('id')
            await axios.get(`${BASE_API_URL}/partnerships/getPartnership/${id}`)
                .then((response) => setPartnership(response.data.partnership))
                .catch((e) => console.error(e))
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getPartnership()
    }, []);

    if (loading) {
        return <LinearProgress style={{marginRight: '10%', marginLeft: '10%', marginTop: '4%'}}/>
    }

    return (
        <>
            <Navbar/>
            <div className={"db-main-bg"} style={{minHeight: '100vh'}}>
                {partnership === null ? (
                    <h3>Loading...</h3>
                ) : (
                    <>
                        <Helmet>
                            <meta charSet="utf-8"/>
                            <title>{partnership.metaTitle}</title>
                            <meta
                                name="description"
                                content={partnership.metaDesc}
                            />
                        </Helmet>
                        <Grid container
                              sx={{
                                  paddingTop: '100px',
                                  paddingLeft: padding,
                                  paddingRight: padding,
                                  marginBottom: '20px',
                                  minHeight: '100vh',
                                  justifyContent: 'center'
                              }}>
                            <PartnershipDetails partnership={partnership}/>
                        </Grid>
                    </>
                )}
                <BottomBarBlogs/>
                <div style={{paddingBottom: isMobile || isBigMobile || isTablet ? '70%' : '10%'}} />
                <TrialCard/>
            </div>
            <Footer/>
        </>
    )
}

export default PartnershipPage