import React from "react";
import { NAVIGATION_STORE } from "../../state/NAVIGATION_STORE";
import { NAV_NEWSLETTER } from "../../utils/nav-constants";
import ReactGA from "react-ga4";
import * as colors from "../../utils/colors";
import { Helmet } from "react-helmet";
import { metaData } from "../../utils/meta-constants";
import NavigationBar from "../../components/NavBar";
import { Grid, Typography, Alert, Snackbar } from "@mui/material";
import { observer } from "mobx-react";
import { createTheme, useMediaQuery, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BROWSER_DIMENSIONS_STORE } from "../../state/BROWSER_DIMENSIONS";
import { LARGE_SIZE } from "../../utils/ui-constants";
import emailjs from "@emailjs/browser";
import "../ContactUs/InputText/cu_ipt_styles.css";
import axios from "axios";

export default function Newsletter() {
  React.useEffect(() => {
    NAVIGATION_STORE.setScreen(NAV_NEWSLETTER);
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
    ReactGA.send("pageview");
  }, []);

  return (
    <div
      className="container"
      style={{ backgroundColor: colors.MAIN_BACKGROUND }}
    >
      <Helmet>
        <meta name="title" content={metaData.newsletter.title} />
        <meta name="description" content={metaData.newsletter.description} />
        <title>{metaData.newsletter.title}</title>
      </Helmet>
      <NavigationBar />
      <Grid container>
        <Content />
      </Grid>
    </div>
  );
}

const Content = observer(() => {
  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [text, setText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1920,
      },
    },
  });
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isBigMobile = useMediaQuery(theme.breakpoints.only("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.only("md"));
  const isLaptop = useMediaQuery(theme.breakpoints.only("lg"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("xl"));

  const navigate = useNavigate();
  const sendMail = async (e) => {
    try {
      e.preventDefault();
      const data = {};
      const formData = new FormData(e.target);
      formData.forEach((value, key) => {
        data[key] = value;
      });
      setIsLoading(true);

      await axios.post(
        `https://fitmedik-backend.in/editorials/subscribe`,
        data
      );

      setIsLoading(false);
      setOpen(true);
      setSuccess(true);
      setText("E-mail sent successfully");
      e.target.reset();
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      setOpen(true);
      setSuccess(false);
      setText("An error occured");
    }
  };

  return (
    <div
      style={{
        zIndex: 1,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        paddingLeft: "8%",
        paddingRight: "8%",
        paddingTop: isMobile || isBigMobile || isTablet ? "20%" : "12%",
        backgroundColor: colors.MAIN_BACKGROUND,
      }}
    >
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
      >
        <Alert
          severity={success === true ? "success" : "error"}
          onClose={() => setOpen(false)}
          sx={{ width: "100%" }}
        >
          {text}
        </Alert>
      </Snackbar>
      {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
        <div className="ab_ellipse_second_2 blur200" />
      )}
      {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
        <div className="ab_ellipse_second_3 blur200" />
      )}

      <div
        style={{
          display: "flex",
          flexDirection: isMobile || isBigMobile || isTablet ? "column" : "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            bgcolor: "#181B21",
            borderRadius: "16px",
            p: "32px",
          }}
          width="50vw"
          mx="auto"
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <Typography color="white" fontSize="32px" fontWeight="600">
            Make informed decisions by
            <br /> considering the Voice of -{" "}
            <span style={{ color: "#FF6355" }}>Market</span>
          </Typography>
          <Typography
            textAlign="justify"
            color="#FFFFFFCC"
            fontWeight="400"
            fontSize="16px"
          >
            Gain valuable insights and stay ahead of the competition. Join our
            community and receive regular updates, reports, and analysis
            directly to your inbox. Make informed decisions with Care Chronicle!
          </Typography>

          <Box
            component="form"
            display="flex"
            flexDirection="column"
            gap={2}
            onSubmit={sendMail}
          >
            <Box display="flex" justifyContent="space-between" gap={4}>
              <label style={{ flex: 1 }} className="contact-us-subheading-text">
                First Name
                <input
                  style={{ padding: 10, marginTop: 10 }}
                  className="contact-us-input"
                  type="text"
                  name="firstName"
                  placeholder="Enter your first name"
                  required
                />
              </label>

              <label style={{ flex: 1 }} className="contact-us-subheading-text">
                Last Name
                <input
                  style={{ padding: 10, marginTop: 10 }}
                  className="contact-us-input"
                  type="text"
                  name="lastName"
                  placeholder="Enter your last name"
                  required
                />
              </label>
            </Box>

            <label className="contact-us-subheading-text">
              Email Address
              <input
                style={{ padding: 10, marginTop: 10 }}
                className="contact-us-input"
                type="email"
                name="email"
                placeholder="Enter your email address"
                required
              />
            </label>

            <label className="contact-us-subheading-text">
              Company Name (Optional)
              <input
                className="contact-us-input"
                type="text"
                name="company"
                placeholder="Enter your company name"
                style={{ padding: 10, marginTop: 10 }}
              />
            </label>

            <label style={{ color: "#FFFFFFCC" }}>
              <input
                style={{ accentColor: "#FF6355", marginRight: 10 }}
                defaultChecked
                type="checkbox"
                name="t&c"
                required
              />
              I have read and agree to the Terms and Conditions and Privacy
              Policy.
            </label>

            <button
              disabled={isLoading}
              className="contact-us-button"
              style={{ padding: 10, opacity: isLoading ? 0.5 : 1 }}
            >
              Submit
            </button>
          </Box>
        </Box>
      </div>
    </div>
  );
});
