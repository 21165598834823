import React, {Component} from "react";
import "./DigitalTwin.css";

let imgBaseDir = "/img/digitaltwin/";

class HoverPoint extends Component {
    render() {
        return (
            <div
                className="hover_point"
                onMouseEnter={() => this.props.updater(this.props.Tag, true)}
                onMouseLeave={() => this.props.updater(this.props.Tag, false)}
                style={this.props.style}
            >
                {this.props.children}
            </div>
        );
    }
}

class DigitalTwin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stat: 0,
        };
        this.statupdater = this.statupdater.bind(this);
    }

    statupdater(tag, state) {
        if (state) this.setState({stat: tag});
        else this.setState({stat: 0});
    }

    render() {
        return (
            <section>
                <div className="overflow-hidden">
                    <div className="doctor_container" id="digitaltwin">
                        <div className="doctor_row">
                            <div className="left_doctor">
                                <img
                                    style={{marginLeft: '60px'}}
                                    height={500}
                                    src={imgBaseDir + "left_doc_new.svg"}
                                    alt=""
                                    className="center-fit"
                                />
                                <div style={{top: 0}}>
                                    <HoverPoint
                                        style={{
                                            top: "0",
                                            height: "100px",
                                            left: "110px",
                                            width: "150px",
                                        }}
                                        Tag="head"
                                        updater={this.statupdater}
                                    ></HoverPoint>
                                    <HoverPoint
                                        style={{
                                            top: "100px",
                                            left: "110px",
                                            height: "150px",
                                            width: "180px",
                                        }}
                                        Tag="body"
                                        updater={this.statupdater}
                                    ></HoverPoint>
                                    <HoverPoint
                                        style={{
                                            top: "250px",
                                            left: "110px",
                                            height: "200px",
                                            width: "180px",
                                        }}
                                        Tag="lower"
                                        updater={this.statupdater}
                                    ></HoverPoint>
                                </div>
                                {this.state.stat ? (
                                    ""
                                ) : (
                                    <div className="doctor_clickanimation">
                                        <img
                                            src={imgBaseDir + "clickamination.gif"}
                                            height="300px"
                                            alt=""
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="shield">
                                <img
                                    height={180}
                                    src={imgBaseDir + "shield.png"}
                                    alt=""
                                    className="center-fit"
                                />
                            </div>
                            <div className="right_doctor">
                                <img
                                    height={500}
                                    src={imgBaseDir + "right_doc_new.svg"}
                                    alt=""
                                    className="center-fit"
                                />
                                {this.state.stat === "head" ? (
                                    <div
                                        className="doctor_statcontainer"
                                        style={{
                                            top: "0px",
                                            display: "flex",
                                            left: "-105px",
                                        }}
                                    >
                                        <div>
                                            <img src={imgBaseDir + "head_left.svg"} alt=""/>
                                        </div>
                                        <div>
                                            <div style={{height: "30px"}}></div>
                                            <img src={imgBaseDir + "head_right.svg"} alt=""/>
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                                {this.state.stat === "body" ? (
                                    <div
                                        className="doctor_statcontainer"
                                        style={{top: "100px", display: "flex", left: "-105px"}}
                                    >
                                        <div>
                                            <img src={imgBaseDir + "body_left.svg"} alt=""/>
                                        </div>
                                        <div>
                                            <div style={{height: "30px"}}></div>
                                            <img src={imgBaseDir + "body_right.svg"} alt=""/>
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                                {this.state.stat === "lower" ? (
                                    <div
                                        className="doctor_statcontainer"
                                        style={{
                                            top: "230px",
                                            left: "130px",
                                        }}
                                    >
                                        <img src={imgBaseDir + "lower.svg"} alt=""/>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                        {/* FOR MOBILE */}
                        <div className="doctor_row_mobile">
                            <div style={{position: "relative"}}>
                                <img height={500} src={imgBaseDir + "right_doc_new.png"} alt=""/>
                                <div
                                    className="doctor_statcontainer"
                                    id="mobstat1"
                                    style={{top: "0px", display: "flex", left: "-75px"}}
                                >
                                    <div>
                                        <img src={imgBaseDir + "head_left.svg"} alt=""/>
                                    </div>
                                    <div>
                                        <div style={{height: "30px"}}></div>
                                        <img src={imgBaseDir + "head_right.svg"} alt=""/>
                                    </div>
                                </div>
                                <div
                                    className="doctor_statcontainer"
                                    id="mobstat2"
                                    style={{top: "100px", display: "flex", left: "-75px"}}
                                >
                                    <div>
                                        <img src={imgBaseDir + "body_left.svg"} alt=""/>
                                    </div>
                                    <div>
                                        <div style={{height: "30px"}}></div>
                                        <img src={imgBaseDir + "body_right.svg"} alt=""/>
                                    </div>
                                </div>
                                <div
                                    className="doctor_statcontainer"
                                    id="mobstat3"
                                    style={{top: "230px", left: "175px"}}
                                >
                                    <img src={imgBaseDir + "lower.svg"} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default DigitalTwin;
