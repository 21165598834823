import React from "react";
import MidBarEditorials from "../../components/MidBarEditorials/MidBarEditorials";
import AllPosts from "../../components/AllPosts/AllPosts";
import Footer from "../../components/Footer/Footer";
import "./styles.css";
import {Helmet} from "react-helmet";
import NavBar from "../../../components/NavBar";

const HomePage = () => {
    return (
        <>
            <NavBar/>
            <div>
                <div className={"db-main-bg"} style={{minHeight: '100vh'}}>
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>{"Care Chronicles"}</title>
                        <meta name="description" content={"Care Chronicles"}/>
                    </Helmet>
                    <MidBarEditorials/>
                    <AllPosts/>
                </div>
                <Footer/>
            </div>
        </>
    )
}

export default HomePage