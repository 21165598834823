import React from "react";
import {Card, CardContent, CardMedia, createTheme, Typography} from "@mui/material";
import {formatDate} from "../../utils/HelperFunctions";
import "./styles.css";
import Colors from "../../utils/Colors";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useNavigate} from "react-router-dom";

const PartnershipCard = ({item, borderRadius}) => {

    const {_id, title, pic, logo, createdAt} = item
    const navigate = useNavigate()

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    return (
        <Card
            className={"glassmorph_style"}
            elevation={4}
            sx={{
                backgroundColor: 'transparent',
                width: '100%',
                height: '100%',
                cursor: 'pointer',
                transition: 'box-shadow 0.3s ease',
                '&:hover': {
                    boxShadow: '0 0 10px rgba(128, 128, 128, 0.8)',
                },
                borderRadius: borderRadius,
                borderWidth: 0,
            }}
            onClick={() => {
                window.open(`/partnerships/open-partnership?id=${_id}`, '_self')
                // window.location.reload()
                // window.scrollTo(0, 0)
            }}
        >
            <CardMedia
                sx={{height: '300px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}
                image={pic}
                title="image"
            />
            <CardContent>
                <Typography
                    sx={{
                        color: 'white',
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        fontSize: isDesktop ? '26px' : isLaptop ? '24px' : '22px'
                    }}
                    gutterBottom
                    component="div"
                >
                    {title}
                </Typography>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Typography
                        gutterBottom
                        color={Colors.GREY}
                        component="div"
                        sx={{marginTop: '20px', fontSize: '13px'}}
                    >
                        {formatDate(createdAt)}
                    </Typography>
                    <img style={{height: '24%', width: '24%', objectFit: 'contain', borderRadius: '4px'}} src={logo} alt={'logo'}/>
                </div>
            </CardContent>
        </Card>
    )
}

export default PartnershipCard