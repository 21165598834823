import React from "react";
import "./styles.css";
import TagsFilter from "../../components/TagsFilter/TagsFilter";
import Footer from "../../components/Footer/Footer";
import {AuthorsData} from "../../utils/AuthorsData";
import AuthorCard from "../../components/AuthorCard/AuthorCard";
import {Box, createTheme, Grid, Typography} from "@mui/material";
import {Helmet} from "react-helmet";
import useMediaQuery from "@mui/material/useMediaQuery";
import NavBar from "../../../components/NavBar";

const Authors = () => {

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    let cardsPerRow = 1;

    if (isMobile) {
        cardsPerRow = 1
    } else if (isBigMobile) {
        cardsPerRow = 1
    } else if (isTablet) {
        cardsPerRow = 2
    } else if (isLaptop) {
        cardsPerRow = 4
    } else if (isDesktop) {
        cardsPerRow = 6
    }

    return (
        <>
            <NavBar/>
            <div
                className={"db-main-bg"}
                style={{
                    paddingLeft: '1%',
                    paddingRight: '1%',
                    minHeight: '100vh'
                }}
            >
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{"Authors"}</title>
                    <meta
                        name="description"
                        content={"Fitmedik Editorial Authors"}
                    />
                </Helmet>
                <Grid sx={{flexGrow: 1, padding: '4%'}} container>
                    <Typography
                        sx={{
                            color: 'white',
                            fontWeight: '700',
                            marginBottom: '40px',
                            fontFamily: "Calibri, sans-serif",
                            fontSize: '40px',
                            paddingLeft: '8%',
                            paddingRight: '8%'
                        }}
                        gutterBottom
                        component="div"
                    >
                        Authors
                    </Typography>
                    <Box
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                            paddingLeft: isMobile || isBigMobile || isTablet ? '2%' : '0%',
                            paddingRight: isMobile || isBigMobile || isTablet ? '2%' : '0%',
                        }}
                        gap={1}
                    >
                        {AuthorsData.map((item, index) => (
                            <Box
                                key={index}
                                width={isMobile || isBigMobile || isTablet ? '100%' : `calc(100% / ${cardsPerRow + 1})`}
                                maxWidth={isMobile || isBigMobile || isTablet ? '100%' : `calc(100% / ${cardsPerRow + 1})`}
                                maxHeight={'100%'}
                                flexShrink={0}
                            >
                                <AuthorCard item={item}/>
                            </Box>
                        ))}
                    </Box>
                </Grid>
            </div>
            <Footer/>
        </>
    )
}

export default Authors