import React, {useRef} from "react";
import {createTheme, Grid, useMediaQuery} from "@mui/material";
import "./styles.css";
import * as colors from "../../../utils/colors";
import * as routes from "../../../utils/ui-routes";
import {observer} from "mobx-react";
import {BROWSER_DIMENSIONS_STORE} from "../../../state/BROWSER_DIMENSIONS";
import {useNavigate} from "react-router-dom";
import {LARGE_SIZE} from "../../../utils/ui-constants";
import backgroundImage from "../../../assets/images/cbf/female_nurse_gloves.png";

const First = observer(() => {
    const ref = useRef(null);

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    const navigate = useNavigate();

    return (
        <div
            ref={ref}
            style={{
                zIndex: 1,
                backgroundColor: colors.MAIN_BACKGROUND,
                backgroundImage: isLaptop || isDesktop ? `url(${backgroundImage})` : null,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                minHeight: '100vh',
                backgroundPosition: "left top",
                marginLeft: "11%",
            }}
        >
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="cbf_first_ellipse-1"/>
            )}
            <Grid
                container
                flexDirection="column"
                sx={{mt: isMobile || isBigMobile || isTablet ? '20%' : isLaptop ? '13%' : isDesktop ? '25%' : '28%'}}
            >
                <Grid item container xs={12} rowSpacing={10}>
                    <Grid
                        item
                        container
                        marginLeft={"-15%"}
                        zIndex={99}
                        xs={12}
                        lg={8}
                        sx={{
                            pl: BROWSER_DIMENSIONS_STORE.getDimensions.width >= 600 ? 14 : 4,
                            pr: BROWSER_DIMENSIONS_STORE.getDimensions.width >= 600 ? 10 : 4,
                        }}
                    >
                        <label className="db_main_heading">
                            Get access to
                            <br/>
                            <span className="db_main_heading cbf_first_heading_highlight">
                                {" "}AI Retention Companion{" "}
                            </span>
                            <br/>
                            Full sponsored by CBF
                        </label>
                        <br/>
                        <label className="monitor-sub-text">
                            {
                                "Autopilot burnout monitoring with AI, make data-driven interventions, and prioritize what truly matters-  delivering exceptional patient care."
                            }
                        </label>
                        <Grid container spacing={3} sx={{mt: 0.5, mb: 0.5, display: 'flex', flexDirection: 'column'}}>
                            <Grid item xs={6} sm={6} md={12} lg={6}>
                                <div
                                    className="cbf_first_button cbf_first_button-right"
                                    onClick={() => navigate(routes.ROUTE_CONTACT_US)}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={12} lg={6}>
                                <div
                                    className="cbf_first_button cbf_second_button-right"
                                    onClick={() => navigate(routes.ROUTE_CONTACT_US)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
});

export default First;
