import { action, computed, makeObservable, observable } from "mobx";

class ScrollStore {
  state = {
    yOffset: 0,
  };

  constructor() {
    makeObservable(this, {
      state: observable,
      setYOffset: action,
      getYOffset: computed,
    });
  }

  setYOffset = (yOffset = 0) => {
    this.state.yOffset = yOffset;
  };

  get getYOffset() {
    return this.state.yOffset;
  }
}

export const SCROLL_STORE = new ScrollStore();
