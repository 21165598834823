import {Card, createTheme, FormControlLabel, Grid, Radio, RadioGroup, Typography} from "@mui/material";
import React, {useState} from "react";
import "./cu_ipt_styles.css";
import useMediaQuery from "@mui/material/useMediaQuery";

const ContactUsInputText = ({loading}) => {

    const scaleValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const [selectedScaleValue, setSelectedScaleValue] = useState(null);

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    return (
        <>
            <Grid
                container
                rowSpacing={2.5}
                sx={{
                    mt: 1,
                    maxHeight: '65vh',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                        width: '12px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#888',
                        borderRadius: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                        width: '5px'
                    },
                }}
            >
                <Grid container item xs={12} sx={{display: 'flex', flexDirection: 'column', paddingRight: 3}}>
                    <label className={'contact-us-subheading-text'}>
                        What's your full name?
                    </label>
                    <input
                        className="contact-us-input"
                        placeholder="Enter your full name"
                        type="text"
                        name="user_name"
                        style={{padding: 10}}
                        required={true}
                    />
                </Grid>
                <Grid container item xs={12} sx={{display: 'flex', flexDirection: 'column', paddingRight: 3}}>
                    <label className={'contact-us-subheading-text'}>
                        What's your email?
                    </label>
                    <input
                        className="contact-us-input"
                        placeholder="Enter your email address"
                        type="email"
                        name="user_email"
                        style={{padding: 10}}
                        required={true}
                    />
                </Grid>
                <Grid container item xs={12} sx={{display: 'flex', flexDirection: 'column', paddingRight: 3}}>
                    <label className={'contact-us-subheading-text'}>
                        What's the name of your organization?
                    </label>
                    <input
                        className="contact-us-input"
                        placeholder="Enter name of your organization"
                        type="text"
                        name="user_organization_name"
                        style={{padding: 10}}
                        required={true}
                    />
                </Grid>
                <Grid container item xs={12} sx={{display: 'flex', flexDirection: 'column', paddingRight: 3}}>
                    <label className={'contact-us-subheading-text'}>
                        What's the size of your organization?
                    </label>
                    <input
                        className="contact-us-input"
                        placeholder="Enter size of your organization"
                        type="number"
                        name="user_organization_size"
                        style={{padding: 10}}
                        required={true}
                    />
                </Grid>
                <Grid container item xs={12} sx={{display: 'flex', flexDirection: 'column', paddingRight: 3}}>
                    <label className={'contact-us-subheading-text'}>
                        What's your role/designation?
                    </label>
                    <input
                        className="contact-us-input"
                        placeholder="Enter your role/designation"
                        type="text"
                        name="user_role"
                        style={{padding: 10}}
                        required={true}
                    />
                </Grid>
                <Grid container item xs={12} sx={{display: 'flex', flexDirection: 'column', paddingRight: 2.5}}>
                    <label className={'contact-us-subheading-text'}>
                        On a scale of 0-10, how urgent is it for your healthcare facility to tackle turnover risk?
                        (0 is not urgent, 10 is extremely urgent)
                    </label>
                    {isMobile || isBigMobile ? (
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'row',
                                justifyContent: 'space-evenly',
                                alignItems: 'center'
                            }}>
                                {[1, 2, 3, 4, 5].map((value) => (
                                    <Card
                                        sx={{
                                            height: 50,
                                            width: 48,
                                            cursor: 'pointer',
                                            backgroundColor: value === selectedScaleValue ? '#FF6355' : 'rgba(255, 255, 255, 0.2)',
                                        }}
                                        onClick={() => setSelectedScaleValue(value)}
                                    >
                                        <div style={{
                                            display: 'flex',
                                            flex: 1,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexDirection: 'column',
                                            marginTop: 8
                                        }}>
                                            <Typography color={'#EDEDED'} fontStyle={'Poppins'} variant="h6"
                                                        align="center">
                                                {value}
                                            </Typography>
                                        </div>
                                    </Card>
                                ))}
                            </div>
                            <div style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'row',
                                justifyContent: 'space-evenly',
                                alignItems: 'center',
                                marginTop: 8
                            }}>
                                {[6, 7, 8, 9, 10].map((value) => (
                                    <Card
                                        sx={{
                                            height: 50,
                                            width: 48,
                                            cursor: 'pointer',
                                            backgroundColor: value === selectedScaleValue ? '#FF6355' : 'rgba(255, 255, 255, 0.2)',
                                        }}
                                        onClick={() => setSelectedScaleValue(value)}
                                    >
                                        <div style={{
                                            display: 'flex',
                                            flex: 1,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexDirection: 'column',
                                            marginTop: 8
                                        }}>
                                            <Typography color={'#EDEDED'} fontStyle={'Poppins'} variant="h6"
                                                        align="center">
                                                {value}
                                            </Typography>
                                        </div>
                                    </Card>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                            alignItems: 'center'
                        }}>
                            {scaleValues.map((value) => (
                                <Card
                                    sx={{
                                        height: 50,
                                        width: 48,
                                        cursor: 'pointer',
                                        backgroundColor: value === selectedScaleValue ? '#FF6355' : 'rgba(255, 255, 255, 0.2)',
                                    }}
                                    onClick={() => setSelectedScaleValue(value)}
                                >
                                    <div style={{
                                        display: 'flex',
                                        flex: 1,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        marginTop: 8
                                    }}>
                                        <Typography color={'#EDEDED'} fontStyle={'Poppins'} variant="h6" align="center">
                                            {value}
                                        </Typography>
                                    </div>
                                </Card>
                            ))}
                        </div>
                    )}
                </Grid>
                <Grid container sx={{display: 'flex', flexDirection: 'column', paddingRight: 2.5, marginTop: 4}}>
                    <label className={'contact-us-subheading-text'}>
                        How do you foresee using Fitmedik at your healthcare facility?
                    </label>
                    <RadioGroup name="question_2" defaultValue="">
                        <FormControlLabel
                            value="Q2_A_Find out which departments are struggling the most"
                            control={<Radio/>}
                            label="A. Find out which departments are struggling the most"
                            sx={{
                                marginLeft: 0,
                                paddingLeft: 1.5,
                                paddingRight: 0.5,
                                flexDirection: 'row-reverse',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                borderRadius: 1,
                                color: '#EDEDED',
                                marginBottom: 1,
                                '&.Mui-checked': {
                                    color: '#FF6355',
                                },
                            }}
                        />
                        <FormControlLabel
                            value="Q2_B_Identify the root causes of turnover risk"
                            control={<Radio/>}
                            label="B. Identify the root causes of turnover risk"
                            sx={{
                                marginLeft: 0,
                                paddingLeft: 1.5,
                                paddingRight: 0.5,
                                flexDirection: 'row-reverse',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                borderRadius: 1,
                                color: '#EDEDED',
                                marginBottom: 1,
                                '&.Mui-checked': {
                                    color: '#FF6355',
                                },
                            }}
                        />
                        <FormControlLabel
                            value="Q2_C_Monitor the effectiveness of turnover risk recovery interventions"
                            control={<Radio/>}
                            label="C. Monitor the effectiveness of turnover risk recovery interventions"
                            sx={{
                                marginLeft: 0,
                                paddingLeft: 1.5,
                                paddingRight: 0.5,
                                flexDirection: 'row-reverse',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                borderRadius: 1,
                                color: '#EDEDED',
                                marginBottom: 1,
                                '&.Mui-checked': {
                                    color: '#FF6355',
                                },
                            }}
                        />
                        <FormControlLabel
                            value="Q2_D_Understand the financial implications of turnover risk"
                            control={<Radio/>}
                            label="D. Understand the financial implications of turnover risk"
                            sx={{
                                marginLeft: 0,
                                paddingLeft: 1.5,
                                paddingRight: 0.5,
                                flexDirection: 'row-reverse',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                borderRadius: 1,
                                color: '#EDEDED',
                                '&.Mui-checked': {
                                    color: '#FF6355',
                                },
                            }}
                        />
                    </RadioGroup>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{mt: 2}}>
                <input
                    disabled={loading}
                    style={{opacity: loading === true ? 0.5 : 1}}
                    className="contact-us-button"
                    type="submit"
                    value={loading === true ? "Sending..." : "Send"}
                />
            </Grid>
        </>
    );
};

export default ContactUsInputText;
