export const GetPageYOffset = (window, document) => {
  return (
    window.pageYOffset ||
    (document.documentElement || document.body.parentNode || document.body)
      .scrollTop
  );
};

export const navigateToExternal = (url) => {
  window.location.href = url;
};
