export const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
        day: "numeric",
        month: "long",
        year: "numeric",
    })
}

export const tzToLocale = (dateString) => {
    const date = new Date(dateString)
    return date.toLocaleDateString()
}