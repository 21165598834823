import React, {useState} from "react";
import {Box, Divider, Grid, Link, ListItemText, Menu, MenuItem} from "@mui/material";
import "./styles.css";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {useNavigate} from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../assets/logo-white.png";
import {LARGE_SIZE} from "../../utils/Constants";

const Navbar = () => {

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const toggleMenu = () => {
        setOpen(!open);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const openMenu = Boolean(anchorEl);

    return (
        <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            rowSpacing={1}
            style={{
                zIndex: 100,
                position: "fixed",
                display: 'flex',
                flexDirection: 'row',
                paddingTop: '10px',
                paddingBottom: '5px'
            }}
            className="navigation-bar-bg"
        >
            {window.innerWidth >= LARGE_SIZE ? (
                <>
                    <Box style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flex: 0.2,
                        flexDirection: 'row',
                        paddingTop: '10px'
                    }}>
                        <img
                            src={Logo}
                            alt="Fitmedik"
                            className="navBar-logo center-fit"
                            onClick={(e) => {
                                e.preventDefault();
                                window.location.href = "/";
                            }}
                        />
                    </Box>
                    <Box style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '60px',
                        flex: 0.6,
                        flexDirection: 'row',
                        paddingTop: '10px'
                    }}>
                        <Link
                            underline="hover"
                            style={{
                                color: "white",
                            }}
                            className="link-text"
                            href={'/'}
                            onClick={() => navigate('/')}
                        >
                            Home
                        </Link>
                        <Link
                            underline="hover"
                            style={{
                                color: "white",
                            }}
                            className="link-text"
                            href={'/how-it-works'}
                            onClick={() => navigate('/how-it-works')}
                        >
                            How it Works
                        </Link>
                        <Link
                            alignSelf={"center"}
                            underline="hover"
                            style={{
                                color: "white",
                            }}
                            className="link-text"
                            href={'/pricing'}
                            onClick={() => navigate('/pricing')}
                        >
                            Pricing
                        </Link>
                        <Grid container justifyContent="center" alignItems="center" item lg={1}>
                            <Link
                                alignSelf="center"
                                underline="hover"
                                style={{
                                    color: 'white',
                                    cursor: 'pointer'
                                }}
                                className="link-text"
                                onClick={handleMenuOpen}
                            >
                                Resources
                                <ArrowDropDownIcon/>
                            </Link>
                            <Menu
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={handleMenuClose}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                sx={
                                    {"& .MuiMenu-paper": {backgroundColor: "#282C34",}}
                                }
                            >
                                <MenuItem
                                    onClick={() => {
                                        navigate('/carechronicles')
                                        handleMenuClose();
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <h1 className={'text-primary'}>
                                                Care Chronicles
                                            </h1>
                                        }
                                        secondary={
                                            <h1 className={'text-secondary'}>
                                                Fresh news, stories and customer success
                                            </h1>
                                        }
                                    />
                                </MenuItem>
                                <Divider/>
                                <MenuItem
                                    onClick={() => {
                                        navigate('/partnerships')
                                        handleMenuClose();
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <h1 className={'text-primary'}>
                                                Partnerships
                                            </h1>
                                        }
                                        secondary={
                                            <h1 className={'text-secondary'}>
                                                Fostering collaborative healthcare innovation
                                            </h1>
                                        }
                                    />
                                </MenuItem>
                            </Menu>
                        </Grid>
                        <Link
                            underline="hover"
                            style={{
                                color: "white",
                            }}
                            className="link-text"
                            href={"/contact-us"}
                            onClick={() => {
                                navigate("/contact-us")
                                window.scrollTo(0, 0)
                            }}
                        >
                            Contact Us
                        </Link>
                    </Box>
                    <Box style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flex: 0.2,
                        flexDirection: 'row',
                        paddingTop: '10px',
                        paddingRight: '10px',
                        gap: '10px'
                    }}>
                        <div
                            className="button button-left navBar-button"
                            onClick={() => navigate('/contact-us')}
                        />
                        <div
                            className="btn-right"
                            onClick={() => {
                                window.open('https://organization.fitmedik.com/', '_self')
                            }}
                        />
                    </Box>
                </>
            ) : (
                <>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        item
                        xs={window.innerWidth >= LARGE_SIZE ? 3 : 6}
                        sx={{paddingTop: '10px', paddingBottom: '5px'}}
                    >
                        <img
                            src={Logo}
                            alt="Fitmedik"
                            onClick={() => navigate('/partnerships')}
                            className="navBar-logo center-fit"
                        />
                    </Grid>
                    {window.innerWidth < LARGE_SIZE && (
                        <Grid container item xs={6} justifyContent="center" alignItems="center">
                            <Grid item xs={5}/>
                            <Grid
                                container
                                item
                                xs={5}
                                justifyContent="center"
                                alignItems="center"
                            >
                                <div className="menu" onClick={toggleMenu}>
                                    <MenuIcon style={{color: "white"}}/>
                                </div>
                            </Grid>
                            <Grid item xs={2}/>
                        </Grid>
                    )}
                    {(window.innerWidth >= LARGE_SIZE || open) && (
                        <>
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                item
                                lg={1}
                            >
                                <Link
                                    underline="hover"
                                    style={{
                                        color: "white",
                                    }}
                                    className="link-text"
                                    href={'/'}
                                    onClick={() => navigate('/')}
                                >
                                    Home
                                </Link>
                            </Grid>
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                item
                                lg={1}
                            >
                                <Link
                                    underline="hover"
                                    style={{
                                        color: "white",
                                    }}
                                    className="link-text"
                                    href={'/how-it-works'}
                                    onClick={() => navigate('/how-it-works')}
                                >
                                    How it Works
                                </Link>
                            </Grid>
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                item
                                lg={1}
                            >
                                <Link
                                    alignSelf={"center"}
                                    underline="hover"
                                    style={{
                                        color: "white",
                                    }}
                                    className="link-text"
                                    href={'/pricing'}
                                    onClick={() => navigate('/pricing')}
                                >
                                    Pricing
                                </Link>
                            </Grid>
                            <Grid container justifyContent="center" alignItems="center" item lg={1}>
                                <Link
                                    alignSelf="center"
                                    underline="hover"
                                    style={{
                                        color: 'white',
                                        cursor: 'pointer'
                                    }}
                                    className="link-text"
                                    onClick={handleMenuOpen}
                                >
                                    Resources
                                    <ArrowDropDownIcon/>
                                </Link>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={openMenu}
                                    onClose={handleMenuClose}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    sx={
                                        {"& .MuiMenu-paper": {backgroundColor: "#282C34",}}
                                    }
                                >
                                    <MenuItem
                                        onClick={() => {
                                            navigate('/carechronicles')
                                            handleMenuClose();
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <h1 className={'text-primary'}>
                                                    Care Chronicles
                                                </h1>
                                            }
                                            secondary={
                                                <h1 className={'text-secondary'}>
                                                    Fresh news, stories and customer success
                                                </h1>
                                            }
                                        />
                                    </MenuItem>
                                    <Divider/>
                                    <MenuItem
                                        onClick={() => {
                                            navigate('/partnerships')
                                            handleMenuClose();
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <h1 className={'text-primary'}>
                                                    Partnerships
                                                </h1>
                                            }
                                            secondary={
                                                <h1 className={'text-secondary'}>
                                                    Fostering collaborative healthcare innovation
                                                </h1>
                                            }
                                        />
                                    </MenuItem>
                                </Menu>
                            </Grid>
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                item
                                lg={1}
                            >
                                <Link
                                    underline="hover"
                                    style={{
                                        color: "white",
                                    }}
                                    className="link-text"
                                    href={"/contact-us"}
                                    onClick={() => {
                                        navigate("/contact-us")
                                        window.scrollTo(0, 0)
                                    }}
                                >
                                    Contact Us
                                </Link>
                            </Grid>
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                item
                                lg={4}
                            >
                                <div
                                    className="button button-left navBar-button"
                                    onClick={() => navigate('/contact-us')}
                                />
                            </Grid>
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                item
                                lg={4}
                            >
                                <div
                                    className="btn-right"
                                    onClick={() => {
                                        window.open('https://organization.fitmedik.com/', '_self')
                                    }}
                                />
                            </Grid>
                        </>
                    )}
                </>
            )}
        </Grid>
    );
}

export default Navbar