import React from "react";
import {observer} from "mobx-react";
import * as colors from "../../../utils/colors";
import {createTheme, useMediaQuery} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logo1 from "./logos/logo1.png";
import logo12 from "./logos/logo12.png";
import logo13 from "./logos/logo13.png";
import logo14 from "./logos/logo14.png";
import logo15 from "./logos/logo15.png";
import logo17 from "./logos/logo17.png";
import logo18 from "./logos/logo18.png";
import logo2 from "./logos/logo2.png";
import "./styles.css";
import GlassmorphBox from "../../../components/GlassmorphBG/GlassmorphBox";
import {BROWSER_DIMENSIONS_STORE} from "../../../state/BROWSER_DIMENSIONS";
import {LARGE_SIZE} from "../../../utils/ui-constants";
import Fade from "react-reveal/Fade";

const Second = observer(() => {

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    const settings = {
        dots: true,
        infinite: true,
        lazyLoad: true,
        speed: 500,
        slidesToShow: isLaptop || isDesktop ? 3 : 2,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 2000,
        className: 'slider-container'
    };

    return (
        <div style={{
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            paddingLeft: '8%',
            paddingRight: '8%',
            paddingTop: isMobile || isBigMobile || isTablet ? '20%' : '10%',
            paddingBottom: '10%',
            backgroundColor: colors.MAIN_BACKGROUND
        }}
        >
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="ab_sec_ellipse1 blur200"/>
            )}
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="ab_sec_ellipse2 blur200"/>
            )}

            <div className="cbf_placard_glassmorph_chip">
                <label className="cbf_placard_glassmorph_chip_text">
                    Backed by Partners
                </label>
            </div>
            <br/>
            <label className="ab_heading">
                Trusted Partners
            </label>
            <br/>
            <br/>
            <Fade big>
                <div style={{
                    width: '76vw',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#22262D',
                    padding: '4%',
                    borderRadius: 8
                }}>
                    <Slider {...settings}>
                        <img
                            className={'logo'}
                            src={logo1}
                            height={'70px'}
                            style={{alignItems: 'center', justifyContent: 'center', padding: '20px'}}
                            alt={'logo'}
                        />
                        <img
                            className={'logo'}
                            src={logo12}
                            height={'70px'}
                            style={{alignItems: 'center', justifyContent: 'center', padding: '20px'}}
                            alt={'logo'}
                        />
                        <img
                            className={'logo'}
                            src={logo13}
                            height={'70px'}
                            style={{alignItems: 'center', justifyContent: 'center', padding: '20px'}}
                            alt={'logo'}
                        />
                        <img
                            className={'logo'}
                            src={logo14}
                            height={'70px'}
                            style={{alignItems: 'center', justifyContent: 'center', padding: '20px'}}
                            alt={'logo'}
                        />
                        <img
                            className={'logo'}
                            src={logo15}
                            height={'70px'}
                            style={{alignItems: 'center', justifyContent: 'center', padding: '20px'}}
                            alt={'logo'}
                        />
                        <img
                            className={'logo'}
                            src={logo2}
                            height={'70px'}
                            style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '20px'
                            }}
                            alt={'logo'}
                        />
                        <img
                            className={'logo'}
                            src={logo17}
                            height={'70px'}
                            style={{alignItems: 'center', justifyContent: 'center', padding: '20px'}}
                            alt={'logo'}
                        />
                        <img
                            className={'logo'}
                            src={logo18}
                            height={'70px'}
                            style={{alignItems: 'center', justifyContent: 'center', padding: '20px'}}
                            alt={'logo'}
                        />
                    </Slider>
                </div>
            </Fade>

            <div style={{display: 'flex', marginTop: '15%', flexDirection: 'column'}}>
                <Fade>
                    <div className="cbf_placard_glassmorph_chip">
                        <label className="cbf_placard_glassmorph_chip_text">
                            Our Values
                        </label>
                    </div>
                    <br/>
                    <label className="ab_heading">
                        What keeps us up at night?
                    </label>
                </Fade>
                <br/>
                <br/>
                <div style={{
                    display: 'flex',
                    flexDirection: isMobile || isBigMobile ? 'column' : 'row',
                    justifyContent: 'space-between'
                }}>
                    <Fade bottom big>
                        <div style={{display: 'flex', flex: 0.492}}>
                            <GlassmorphBox
                                logo={require('../../../assets/images/about_us/Group.png')}
                                title={''}
                                content={'Growth for us is growth of people. By fostering an environment that prioritizes the growth, learning, and wellbeing of our people, we believe in creating a ripple effect that extends beyond the individual, contributing to the overall success and prosperity of Fitmedik.'}
                            />
                        </div>
                        <div style={{display: 'flex', flex: 0.492, marginTop: isMobile || isBigMobile ? '20px' : 0}}>
                            <GlassmorphBox
                                logo={require('../../../assets/images/about_us/Group (1).png')}
                                title={''}
                                content={'At Fitmedik, we celebrate the power of \'Many\' as we recognize that our strength is rooted in the collaborative efforts of our diverse team, working together towards a common goal – the wellbeing of healthcare professionals and the advancement of healthcare excellence.'}
                            />
                        </div>
                    </Fade>
                </div>
                <br/>
                <div style={{
                    display: 'flex',
                    flexDirection: isMobile || isBigMobile ? 'column' : 'row',
                    justifyContent: 'space-between'
                }}>
                    <Fade bottom big>
                        <div style={{display: 'flex', flex: 0.492}}>
                            <GlassmorphBox
                                logo={require('../../../assets/images/about_us/Group (2).png')}
                                title={''}
                                content={'Intentions Matter. By emphasizing the significance of genuine intentions, we foster a culture at Fitmedik where every effort, innovation, and service is driven by a commitment to the wellbeing of healthcare professionals and the betterment of healthcare as a whole.'}
                            />
                        </div>
                        <div style={{display: 'flex', flex: 0.492, marginTop: isMobile || isBigMobile ? '20px' : 0}}>
                            <GlassmorphBox
                                logo={require('../../../assets/images/about_us/Group (3).png')}
                                title={''}
                                content={'Process matters more than results. Our commitment to excellence extends beyond the endpoint – it is woven into the fabric of our processes. By prioritizing a robust, ethical, and collaborative approach, we ensure that the journey itself becomes a testament to our dedication to quality and innovation'}
                            />
                        </div>
                    </Fade>
                </div>
            </div>
        </div>
    )
})

export default Second