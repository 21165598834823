import React from "react";
import {createTheme, Grid, Typography, useMediaQuery} from "@mui/material";
import * as colors from "../../utils/colors";
import NavigationBar from "../../components/NavBar";
import {GLOBAL_TOP_MARGIN} from "../../utils/ui-constants";
import DashboardFooter from "../Dashboard/Footer";
import "./styles.css";
import {Helmet} from "react-helmet";
import {metaData} from "../../utils/meta-constants";

const TermsConditions = () => {

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    return (
        <div style={{backgroundColor: colors.MAIN_BACKGROUND, zIndex: 1}} className="pnf-container">
            {/*<div className="pnf-ellipse-1 blur300"/>*/}
            {/*<div className="pnf-ellipse-2 blur300"/>*/}
            <NavigationBar/>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{metaData.termsAndConditions.title}</title>
                <meta name="description" content={metaData.termsAndConditions.description}/>
            </Helmet>
            <Grid container>
                <Grid container style={{
                    paddingTop: GLOBAL_TOP_MARGIN,
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: isLaptop || isDesktop ? '12%' : '8%',
                    paddingRight: isLaptop || isDesktop ? '12%' : '8%',
                    paddingBottom: isLaptop || isDesktop ? '10%' : '18%',
                    minHeight: '100vh'
                }}>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{
                            fontWeight: '700',
                            color: 'white',
                            textAlign: 'center',
                            marginTop: isLaptop || isDesktop ? '5%' : '15%',
                            fontSize: '36px'
                        }}
                    >
                        Terms & Conditions
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '2%', fontSize: '18px'}}
                    >
                        Effective Date: 8th July 2023

                        Please read these Terms and Conditions ("Terms") carefully before using the Fitmedik mobile
                        application ("App") provided by Fitmedik, a Delaware C Corp Company ("Fitmedik," "we," "us," or
                        "our"). By accessing or using the App, you agree to be bound by these Terms. If you do not agree
                        to
                        these Terms, you may not use the App.
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '2%', fontSize: '18px', fontWeight: '600'}}
                    >
                        1. Use of the App
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '1%', fontSize: '18px'}}
                    >
                        • 1.1 License: Fitmedik grants you a non-exclusive, non-transferable, revocable license to use
                        the
                        App for your personal, non-commercial use. You may not modify, reproduce, distribute, or create
                        derivative works based on the App, in whole or in part, without our prior written consent.
                        <br/>
                        • 1.2 User Obligations: You agree to use the App in compliance with applicable laws,
                        regulations,
                        and these Terms. You are responsible for maintaining the confidentiality of your account
                        information
                        and for all activities that occur under your account.
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '2%', fontSize: '18px', fontWeight: '600'}}
                    >
                        2. Data Collection and Privacy
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '1%', fontSize: '18px'}}
                    >
                        • 2.1 Personal Information: When using the App, you may be required to provide certain personal
                        information, including but not limited to demographic data and GPS location information. We
                        collect,
                        use, and protect your personal information in accordance with our Privacy Policy, which forms an
                        integral part of these Terms.
                        <br/>
                        • 2.2 End-to-End Encryption: We employ industry-standard encryption measures to secure your
                        personal
                        information and maintain its confidentiality. However, please note that no data transmission
                        over
                        the Internet or electronic storage method is 100% secure, and we cannot guarantee the absolute
                        security of your information.
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '2%', fontSize: '18px', fontWeight: '600'}}
                    >
                        3. Intellectual Property
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '1%', fontSize: '18px'}}
                    >
                        • 3.1 Ownership: The App and all associated content, including but not limited to text,
                        graphics,
                        logos, images, audio, video, and software, are the property of Fitmedik or its licensors and are
                        protected by intellectual property laws. You may not use, reproduce, modify, or distribute any
                        content from the App without our prior written consent.
                        <br/>
                        • 3.2 Trademarks: Fitmedik, the Fitmedik logo, and other trademarks, service marks, graphics,
                        and
                        logos used in connection with the App are trademarks or registered trademarks of Fitmedik. You
                        are
                        prohibited from using any trademarks or logos without our prior written permission.
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '2%', fontSize: '18px', fontWeight: '600'}}
                    >
                        4. Limitation of Liability
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '1%', fontSize: '18px'}}
                    >
                        • 4.1 Disclaimer: The App is provided on an "as is" and "as available" basis. Fitmedik makes no
                        warranties, expressed or implied, regarding the App's reliability, accuracy, or fitness for a
                        particular purpose. We do not guarantee uninterrupted or error-free access to the App.
                        <br/>
                        • 4.2 Limitation of Liability: Fitmedik and its directors, officers, employees, and affiliates
                        shall
                        not be liable for any direct, indirect, incidental, consequential, or exemplary damages arising
                        from
                        your use of the App or any content or services provided through the App, even if we have been
                        advised of the possibility of such damages.
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '2%', fontSize: '18px', fontWeight: '600'}}
                    >
                        5. Governing Law and Jurisdiction
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '1%', fontSize: '18px'}}
                    >
                        These Terms shall be governed by and construed in accordance with the laws of the State of
                        Delaware,
                        without regard to its conflict of laws principles. Any disputes arising out of or related to
                        these
                        Terms or your use of the App shall be subject to the exclusive jurisdiction of the state and
                        federal
                        courts located within the State of Delaware.
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '2%', fontSize: '18px', fontWeight: '600'}}
                    >
                        6. Severability
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '1%', fontSize: '18px'}}
                    >
                        If any provision of these Terms is held to be invalid or unenforceable, such provision shall be
                        struck and the remaining provisions shall be enforced to the fullest extent permitted by law. In
                        such cases, the invalid or unenforceable provision shall be modified to the extent necessary to
                        make
                        it valid and enforceable while preserving its intent.
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '2%', fontSize: '18px', fontWeight: '600'}}
                    >
                        7. Changes to the Terms
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '1%', fontSize: '18px'}}
                    >
                        We reserve the right to modify or update these Terms from time to time. The most current version
                        of
                        the Terms will be posted within the App. Your continued use of the App after any modifications
                        or
                        updates constitutes your acceptance of the revised Terms.
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '2%', fontSize: '18px', fontWeight: '600'}}
                    >
                        8. Entire Agreement
                    </Typography>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{color: 'white', marginTop: '1%', fontSize: '18px'}}
                    >
                        These Terms constitute the entire agreement between you and Fitmedik regarding the use of the
                        App
                        and supersede any prior agreements or understandings, whether written or oral.
                        <br/>
                        If you have any questions or concerns regarding these Terms, please contact us at
                        kamal@fitmedik.com
                        <br/>
                        By using our App, you acknowledge that you have read and understood these Terms and agree to be
                        bound by them.
                    </Typography>
                </Grid>
                <DashboardFooter/>
            </Grid>
        </div>
    )
}

export default TermsConditions