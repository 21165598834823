import React, {useEffect} from "react";
import {Grid} from "@mui/material";
import "./styles.css";
import * as colors from "../../utils/colors";
import {NAVIGATION_STORE} from "../../state/NAVIGATION_STORE";
import {NAV_CBF} from "../../utils/nav-constants";
import NavigationBar from "../../components/NavBar";
import {metaData} from "../../utils/meta-constants";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga4";
import {intercom_initialiser} from "../../utils/intercom_initializer";
import First from "./First";
import Second from "./Second";
import Third from "./Third";
import Fourth from "./Fourth";
import Footer from "./Footer";

const ClinicianBurnoutFoundation = () => {

    useEffect(() => {
        NAVIGATION_STORE.setScreen(NAV_CBF);
        ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
        ReactGA.send("pageview");
        intercom_initialiser();
    }, []);

    return (
        <div
            className="container"
            style={{backgroundColor: colors.MAIN_BACKGROUND}}
        >
            <Helmet>
                <meta name="title" content={metaData.clinicianBurnoutFoundation.title}/>
                <meta name="description" content={metaData.clinicianBurnoutFoundation.description}/>
                <title>{metaData.clinicianBurnoutFoundation.title}</title>
            </Helmet>
            <NavigationBar/>
            <Grid container>
                <First/>
                <Second/>
                <Third/>
                <Fourth/>
                <Footer/>
            </Grid>
        </div>
    );
};

export default ClinicianBurnoutFoundation
