import React from "react";
import "../glassmorph_styles.css";

const GlassmorphBox = ({title, content, logo}) => {
    return (
        <div
            style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                width: 'fit-content',
                padding: '30px',
            }}
            className="glassmorph_style"
        >
            <img height={'64px'} width={'64px'} src={logo} alt={'logo'}/>
            <br/>
            <label className="glassmorph_title2">{title}</label>
            <br/>
            <label className="glassmorph_content">{content}</label>
        </div>
    );
};

export default GlassmorphBox