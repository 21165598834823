export const newsData = [
    {
        title: "Healthcare M&A trends post-pandemic",
        link: "/carechronicles/open-editorial?id=64cbd3d03495d745437e9568"
    },
    {
        title: "Here's how NLC can affect your hospital!",
        link: "/carechronicles/open-editorial?id=64cbcc973495d745437e476f"
    },
    {
        title: "Is your healthcare team resistant to change? Build your dream healthcare team!",
        link: "/carechronicles/open-editorial?id=64cbd1313495d745437e6825"
    },
]