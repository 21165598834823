import React, {useState} from "react";
import TagsFilter from "../../components/TagsFilter/TagsFilter";
import Footer from "../../components/Footer/Footer";
import "./styles.css";
import {Alert, Box, Button, Snackbar, TextField, Typography, createTheme} from "@mui/material";
import contactus from "../../assets/contact-us.webp";
import axios from "axios";
import {BASE_API_URL} from "../../utils/Constants";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Helmet} from "react-helmet";

const ContactUs = () => {

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = React.useState(false)
    const [error, setError] = useState(false)

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    }

    const handleSendClick = async () => {
        try {
            setLoading(true)
            const requestBody = {
                name: name.trim(),
                email: email.trim(),
                subject: subject.trim(),
                message: message.trim()
            }
            await axios.post(`${BASE_API_URL}/editorials/addEditorialContact`, requestBody)
                .then((response) => {
                    setOpen(true)
                    setName("")
                    setEmail("")
                    setSubject("")
                    setMessage("")
                })
                .catch((e) => setError(true))
        } catch (e) {
            setError(true)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <div className={"db-main-bg"} style={{paddingLeft: '1%', paddingRight: '1%', minHeight: '100vh'}}>
                <TagsFilter/>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{"Contact Us"}</title>
                    <meta
                        name="description"
                        content={"Fitmedik editorial contact us"}
                    />
                </Helmet>
                <Box style={{
                    display: 'flex',
                    paddingTop: '4%',
                    paddingBottom: '4%',
                    paddingLeft: '8.5%',
                    paddingRight: '8.5%',
                    alignItems: 'center',
                    flexDirection: isMobile || isBigMobile || isTablet ? 'column' : 'row'
                }}>
                    <Box
                        className={"glassmorph_style"}
                        style={{
                            display: 'flex',
                            flex: 0.5,
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingTop: '30px',
                            paddingBottom: '30px',
                            height: '70vh'
                        }}
                    >
                        <img
                            src={contactus}
                            alt="we will get in touch soon"
                        />
                    </Box>
                    <Box
                        style={{
                            display: 'flex',
                            flex: 0.5,
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginLeft: isMobile || isBigMobile || isTablet ? '0px' : '80px',
                            marginTop: isMobile || isBigMobile || isTablet ? '20px' : '0px',
                            marginBottom: isMobile || isBigMobile || isTablet ? '20px' : '0px'
                        }}
                    >
                        <Typography
                            sx={{color: 'white', fontWeight: '700', fontFamily: "Calibri, sans-serif"}}
                            gutterBottom
                            variant="h4"
                            component="div"
                        >
                            Get In Touch
                        </Typography>
                        <TextField
                            style={{width: '100%', marginTop: '20px', backgroundColor: 'rgba(255, 255, 255, 0.2)'}}
                            label="Name"
                            variant="outlined"
                            size="small"
                            InputProps={{
                                style: {color: 'white'},
                            }}
                            InputLabelProps={{
                                style: {color: 'gray'},
                            }}
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                        />
                        <TextField
                            style={{width: '100%', marginTop: 14, backgroundColor: 'rgba(255, 255, 255, 0.2)'}}
                            label="Email Address"
                            variant="outlined"
                            size="small"
                            InputProps={{
                                style: {color: 'white'},
                            }}
                            InputLabelProps={{
                                style: {color: 'gray'},
                            }}
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                        <TextField
                            style={{width: '100%', marginTop: 14, backgroundColor: 'rgba(255, 255, 255, 0.2)'}}
                            label="Subject"
                            variant="outlined"
                            size="small"
                            InputProps={{
                                style: {color: 'white'},
                            }}
                            InputLabelProps={{
                                style: {color: 'gray'},
                            }}
                            value={subject}
                            onChange={(event) => setSubject(event.target.value)}
                        />
                        <TextField
                            style={{width: '100%', marginTop: 14, backgroundColor: 'rgba(255, 255, 255, 0.2)'}}
                            label="Message"
                            variant="outlined"
                            size="small"
                            multiline={true}
                            rows={4}
                            maxRows={7}
                            InputProps={{
                                style: {color: 'white'},
                            }}
                            InputLabelProps={{
                                style: {color: 'gray'},
                            }}
                            value={message}
                            onChange={(event) => setMessage(event.target.value)}
                        />
                        <Button
                            style={{marginTop: 20, width: '100%'}}
                            variant="contained"
                            color="primary"
                            size="large"
                            disabled={(!(name.trim() !== "" && email.trim() !== "" && subject.trim() !== ""
                                && message.trim() !== ""))}
                            onClick={() => handleSendClick()}
                        >
                        <span style={{
                            color: name.trim() !== "" && email.trim() !== "" && subject.trim() !== ""
                            && message.trim() !== "" ? 'white' : 'slategray'
                        }}>
                            {loading ? "Sending..." : "Send"}
                        </span>
                        </Button>
                        <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                            {!error ? (
                                <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                                    Your message has been sent!
                                </Alert>
                            ) : (
                                <Alert onClose={handleClose} severity="error" sx={{width: '100%'}}>
                                    Cannot process your request
                                </Alert>
                            )}
                        </Snackbar>
                    </Box>
                </Box>
            </div>
            <Footer/>
        </>
    )
}

export default ContactUs