import {createTheme, useMediaQuery} from "@mui/material";
import React, {useState} from "react";
import "../../Dashboard/Second/db_second_styles.css";
import "../../../components/GlassmorphBG/glassmorph_styles.css";
import {BROWSER_DIMENSIONS_STORE} from "../../../state/BROWSER_DIMENSIONS";
import {observer} from "mobx-react";
import {LARGE_SIZE} from "../../../utils/ui-constants";
import * as colors from "../../../utils/colors";
import Placard from "./Placard";
import Fade from "react-reveal/Fade";

const Second = observer(() => {

    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1920,
            },
        },
    });
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const isBigMobile = useMediaQuery(theme.breakpoints.only('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.only('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    return (
        <div
            style={{
                backgroundColor: colors.MAIN_BACKGROUND,
                zIndex: 1,
                width: "100%",
                height: "100%",
                paddingBottom: '4%'
            }}
        >
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="ellipse_second_1 blur200"/>
            )}
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="ellipse_second_2 blur200"/>
            )}
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="ellipse_second_3 blur200"/>
            )}
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="ellipse_second_4 blur200"/>
            )}

            <Placard/>

            <br/>
            <br/>
            <br/>
            <br/>
            <br/>

            <div style={{paddingLeft: '5%', paddingRight: '5%'}} className="db-second-bg">
                <Fade>
                    <div className="cbf_placard_glassmorph_chip">
                        <label className="cbf_placard_glassmorph_chip_text">
                            How the co-pilot works
                        </label>
                    </div>
                    <br/>
                    <label className="cbf_placard_heading">
                        Transform the way you retain employees
                        <br/>
                        through culture, experience & engagement
                    </label>
                </Fade>
                <br/>
                <br/>
                <br/>
                <br/>
                <Fade big>
                    <img
                        src={require('../../../assets/gifs/dashboard/AI Copilot.gif')}
                        width={'100%'}
                        height={'100%'}
                        alt={'img_1'}
                    />
                </Fade>
            </div>
        </div>
    );
});

export default Second;
