import Heart from "../../../assets/images/dashboard/heart.svg";
import TrendingUp from "../../../assets/images/dashboard/trending-up.svg";
import Smile from "../../../assets/images/dashboard/smile.svg";
import Loader from "../../../assets/images/dashboard/loader.svg";

export const infoCards = [
    {
        logo: Heart,
        title1: "Efficient",
        title2: "Patient Care",
        content: "Enhance the efficiency of care by more than 40%.",
    },
    {
        logo: TrendingUp,
        title1: "Save",
        title2: "Turnover Costs",
        content: "Save costs upto $9M annually from hiring & training new workers.",
    },
    {
        logo: Smile,
        title1: "Counter",
        title2: "Bad Experiences",
        content: "Provide good patient experience by having having better care worker experience.",
    },
    {
        logo: Loader,
        title1: "Reduce",
        title2: "Medical Errors",
        content: "Elevate patient safety through wellbeing of care workers.",
    },
];
