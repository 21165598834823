import React from "react";
import {Grid} from "@mui/material";
import "./db_third_styles.css";
import * as colors from "../../../utils/colors";
import {observer} from "mobx-react";
import {BROWSER_DIMENSIONS_STORE} from "../../../state/BROWSER_DIMENSIONS";
import {LARGE_SIZE} from "../../../utils/ui-constants";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import GlassmorphMessageBox from "../../../components/GlassmorphBG/GlassmorphMessageBox";
import Fade from "react-reveal/Fade";

const DashboardThird = observer(() => {

    return (
        <div
            style={{
                zIndex: 1,
                backgroundColor: colors.MAIN_BACKGROUND,
                width: "100%",
            }}
        >
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="ellipse_third_1 center-fit blur200"/>
            )}
            {BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE && (
                <div className="ellipse_third_2 center-fit blur200"/>
            )}
            <Grid container className="db-third-bg">
                <Grid item xs={1}/>
                <Grid
                    container
                    item
                    xs={10}
                    sx={{mt: 12, flexDirection: 'column'}}
                >
                    <Fade>
                        <div className="glassmorph_chip">
                            <label className="glassmorph_chip_text">
                                What Key Opinion Leaders Say About Burnout
                            </label>
                        </div>
                        <br/>
                        <div>
                            <label className="db_main_heading">
                                Hear from Key Opinion Leaders
                            </label>
                        </div>
                    </Fade>
                </Grid>
                <br/>
                <br/>
                <Fade>
                    <div className="slider-container"
                         style={{
                             width: '85%', paddingLeft: '8%', paddingTop: '5%', paddingBottom: '30px',
                             position: 'relative'
                         }}>
                        <Carousel
                            className={'carousel'}
                            additionalTransfrom={0}
                            arrows
                            autoPlay
                            autoPlaySpeed={3500}
                            centerMode={false}
                            containerClass="container-with-dots"
                            draggable
                            focusOnSelect={false}
                            infinite
                            keyBoardControl
                            minimumTouchDrag={80}
                            pauseOnHover
                            partialVisible
                            renderArrowsWhenDisabled={false}
                            renderButtonGroupOutside={false}
                            renderDotsOutside={true}
                            responsive={{
                                desktop: {
                                    breakpoint: {
                                        max: 3000,
                                        min: 1024
                                    },
                                    items: 2,
                                    partialVisibilityGutter: 40
                                },
                                mobile: {
                                    breakpoint: {
                                        max: 464,
                                        min: 0
                                    },
                                    items: 1,
                                    partialVisibilityGutter: 30
                                },
                                tablet: {
                                    breakpoint: {
                                        max: 1024,
                                        min: 464
                                    },
                                    items: 1,
                                    partialVisibilityGutter: 30
                                }
                            }}
                            rewind={false}
                            rewindWithAnimation={false}
                            rtl={false}
                            shouldResetAutoplay
                            showDots={true}
                            sliderClass=""
                            slidesToSlide={1}
                            swipeable
                        >
                            <GlassmorphMessageBox
                                title={"“We all will pay the price”"}
                                content={"“Unless the major underlying factors of burnout are addressed, an exodus of epic proportions will cripple our health care system,”"}
                                image={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4vEHi-P2o0zFZfABAwqtp8X5Zj0dnx-zbmw&usqp=CAU"}
                                name={"Union of Health Care Professionals"}
                                position={"United Nurses Associations of California"}
                            />
                            <GlassmorphMessageBox
                                title={"“We are absolutely destroyed”"}
                                content={"“Witnessing distressing outcomes and grappling with exhaustion, our mental health has borne the brunt. Sadly, approximately 1 in 5 healthcare providers have left their roles since the pandemic's onset. It's a crucial moment to acknowledge and address the pressing issue of burnout in our healthcare heroes.”"}
                                image={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4vEHi-P2o0zFZfABAwqtp8X5Zj0dnx-zbmw&usqp=CAU"}
                                name={"Dr. Darren Markland"}
                                position={"Intensive care Physician at Kingsway Nephrology"}
                            />
                            <GlassmorphMessageBox
                                title={"“They have reached the breaking point”"}
                                content={"“Today, when I visit a hospital, clinic or health department and ask staff how they're doing, many tell me they feel exhausted, helpless and heartbroken. They also confess they don't see how the health workforce can continue like this.”"}
                                image={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4vEHi-P2o0zFZfABAwqtp8X5Zj0dnx-zbmw&usqp=CAU"}
                                name={"Vivek Murthy"}
                                position={"United States Surgeon General"}
                            />
                            <GlassmorphMessageBox
                                title={"“You cannot be forced to choose between your life and your job without people having your back,”"}
                                content={"“It's crucial to prioritize the well-being of healthcare providers, recognizing the toll on our emotional health and the necessity of robust support systems.”"}
                                image={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4vEHi-P2o0zFZfABAwqtp8X5Zj0dnx-zbmw&usqp=CAU"}
                                name={"Chao Smith"}
                                position={"Registered Nurse"}
                            />
                            <GlassmorphMessageBox
                                title={"“Act Now, Healthcare workers are asking for help”"}
                                content={"““Doctors and nurses play a vital role in the health and well-being of our society. In our research, they have been clear about the areas they need support; we must act now to protect, equip and inspire the clinician of the future.”"}
                                image={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4vEHi-P2o0zFZfABAwqtp8X5Zj0dnx-zbmw&usqp=CAU"}
                                name={"Jan Herzhoff"}
                                position={"President at Elsevier Health"}
                            />
                        </Carousel>
                    </div>
                </Fade>
                {/*<Grid*/}
                {/*    container*/}
                {/*    alignItems="center"*/}
                {/*    justifyContent="center"*/}
                {/*    sx={{mt: 8, mb: 20}}*/}
                {/*>*/}
                {/*    <Grid item xs={1}/>*/}
                {/*    <Grid container item xs={10} spacing={2}>*/}
                {/*        {data.map((val, index) => (*/}
                {/*            <Grid item container md={6} sm={12}>*/}
                {/*                <ExpertMessageCard data={val} key={index}/>*/}
                {/*            </Grid>*/}
                {/*        ))}*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={1}/>*/}
                {/*</Grid>*/}
            </Grid>
        </div>
    );
});

export default DashboardThird;
