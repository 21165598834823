import React from "react";
import DashboardMain from "./Main";
import {Grid} from "@mui/material";
import DashboardSecond from "./Second";
import "./styles.css";
import DashboardThird from "./Third";
import DashboardFooter from "./Footer";
import * as colors from "../../utils/colors";
import {NAVIGATION_STORE} from "../../state/NAVIGATION_STORE";
import {NAV_HOME} from "../../utils/nav-constants";
import NavigationBar from "../../components/NavBar";
import {metaData} from "../../utils/meta-constants";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga4";
import {intercom_initialiser} from "../../utils/intercom_initializer";
import DashboardFourth from "./Fourth";

const Dashboard = () => {
    React.useEffect(() => {
        NAVIGATION_STORE.setScreen(NAV_HOME);
        ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
        ReactGA.send("pageview");

        intercom_initialiser();
    }, []);

    return (
        <div
            className="container"
            style={{backgroundColor: colors.MAIN_BACKGROUND}}
        >
            <Helmet>
                <meta name="title" content={metaData.home.title}/>
                <meta name="description" content={metaData.home.description}/>
                <title>{metaData.home.title}</title>
            </Helmet>
            <NavigationBar/>
            <Grid container>
                <DashboardMain/>
                <DashboardSecond/>
                <DashboardThird/>
                <DashboardFourth/>
                <DashboardFooter/>
            </Grid>
        </div>
    );
};

export default Dashboard;
