import React from "react";
import {Grid} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logo1 from "./logos/logo1.png";
import logo12 from "./logos/logo12.png";
import logo13 from "./logos/logo13.png";
import logo14 from "./logos/logo14.png";
import logo15 from "./logos/logo15.png";
import logo17 from "./logos/logo17.png";
import logo18 from "./logos/logo18.png";
import logo2 from "./logos/logo2.png";

import "./LogoSlider.css";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {BROWSER_DIMENSIONS_STORE} from "../../state/BROWSER_DIMENSIONS";
import {LARGE_SIZE, LARGER_SIZE} from "../../utils/ui-constants";

const LogoSlider = () => {
    const ref = React.useRef(null);
    const [height, setHeight] = React.useState(0);

    React.useLayoutEffect(() => {
        if (ref.current) {
            setHeight(ref.current.clientHeight);
        }
    }, []);

    return (
        <div
            ref={ref}
            style={{
                marginTop: (BROWSER_DIMENSIONS_STORE.getDimensions.width >= LARGE_SIZE &&
                    BROWSER_DIMENSIONS_STORE.getDimensions.width < LARGER_SIZE)
                || (BROWSER_DIMENSIONS_STORE.getDimensions.width <= 375) ? 0 : "-80px",
                zIndex: 99,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <div style={{alignItems: 'center', justifyContent: 'center'}}>
                <div style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '81vw'
                }}
                     className={'card'}>
                    <label className="text"> Trusted Partners </label>
                    <Grid item className="slider-container">
                        <Carousel
                            className={'carousel'}
                            additionalTransfrom={0}
                            arrows
                            autoPlay
                            autoPlaySpeed={3500}
                            centerMode={false}
                            containerClass="container-with-dots"
                            draggable
                            focusOnSelect={false}
                            infinite
                            keyBoardControl
                            minimumTouchDrag={80}
                            pauseOnHover
                            renderArrowsWhenDisabled={false}
                            renderButtonGroupOutside={false}
                            renderDotsOutside={false}
                            responsive={{
                                desktop: {
                                    breakpoint: {
                                        max: 3000,
                                        min: 1024
                                    },
                                    items: 4,
                                    partialVisibilityGutter: 40
                                },
                                mobile: {
                                    breakpoint: {
                                        max: 464,
                                        min: 0
                                    },
                                    items: 1,
                                    partialVisibilityGutter: 30
                                },
                                tablet: {
                                    breakpoint: {
                                        max: 1024,
                                        min: 464
                                    },
                                    items: 2,
                                    partialVisibilityGutter: 30
                                }
                            }}
                            rewind={false}
                            rewindWithAnimation={false}
                            rtl={false}
                            shouldResetAutoplay
                            showDots={true}
                            sliderClass=""
                            slidesToSlide={1}
                            swipeable
                        >
                            <img
                                className={'logo'}
                                src={logo12}
                                height={'70px'}
                                style={{
                                    alignItems: 'center', justifyContent: 'center', paddingLeft: '80px',
                                    paddingRight: '20px',
                                    paddingTop: '20px',
                                    paddingBottom: '20px'
                                }}
                                alt={'logo'}
                            />
                            <img
                                className={'logo'}
                                src={logo13}
                                height={'70px'}
                                style={{alignItems: 'center', justifyContent: 'center', padding: '20px'}}
                                alt={'logo'}
                            />
                            <img
                                className={'logo'}
                                src={logo15}
                                height={'70px'}
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                    paddingTop: '20px',
                                    paddingBottom: '20px'
                                }}
                                alt={'logo'}
                            />
                            <img
                                className={'logo'}
                                src={logo2}
                                height={'70px'}
                                style={{alignItems: 'center', justifyContent: 'center', padding: '20px'}}
                                alt={'logo'}
                            />
                            <img
                                className={'logo'}
                                src={logo17}
                                height={'70px'}
                                style={{alignItems: 'center', justifyContent: 'center', padding: '20px'}}
                                alt={'logo'}
                            />
                            <img
                                className={'logo'}
                                src={logo18}
                                height={'70px'}
                                style={{alignItems: 'center', justifyContent: 'center', padding: '20px'}}
                                alt={'logo'}
                            />
                            <img
                                className={'logo'}
                                src={logo1}
                                height={'70px'}
                                style={{alignItems: 'center', justifyContent: 'center', padding: '20px'}}
                                alt={'logo'}
                            />
                            <img
                                className={'logo'}
                                src={logo14}
                                height={'70px'}
                                style={{alignItems: 'center', justifyContent: 'center', padding: '20px'}}
                                alt={'logo'}
                            />
                        </Carousel>
                    </Grid>
                </div>
            </div>
        </div>
    );
};

export default LogoSlider;
