import jordan from "../assets/jordanlee.png";
import jamie from "../assets/jamie.png";
import riley from "../assets/riley.png";
import aiden from "../assets/aiden.png";

export const AuthorsData = [
    {
        "name": "Jordan Lee",
        "image": jordan,
        "designation": "Jordan Lee is a visionary thinker with a passion for driving innovation and shaping strategic initiatives. Their articles will explore the dynamic world of business, covering topics such as design thinking, disruptive technologies, market trends, and organizational agility. By examining successful innovation strategies and offering practical insights, Jordan aims to inspire readers to embrace change and cultivate a culture of innovation."
    },
    {
        "name": "Aiden Taylor",
        "image": aiden,
        "designation": "Aiden Taylor is a tech enthusiast with a passion for exploring the ever-evolving landscape of digital technology. With a keen eye for innovation and a knack for breaking down complex concepts into accessible ideas, Aiden's articles will delve into the latest trends, advancements, and challenges in the digital realm. From artificial intelligence to cybersecurity and everything in between, Aiden will provide readers with informative and thought-provoking content that sheds light on the digital transformation shaping our world."
    },
    {
        "name": "Jamie Clarke",
        "image": jamie,
        "designation": "Jamie Clarke is a meticulous observer, dedicated to unraveling the intricacies of quality and safety in various spheres of life. Their articles will delve into topics such as healthcare, product quality, workplace safety, and more. Jamie's expertise lies in highlighting best practices, dissecting regulatory frameworks, and offering insights into how organizations can prioritize quality and safety to ensure the well-being of individuals and communities."
    },
    {
        "name": "Riley Carter",
        "image": riley,
        "designation": "Riley Carter is a compassionate advocate for the human side of organizations. With a deep understanding of the intricacies of human resources, Riley's articles will delve into topics such as employee engagement, diversity and inclusion, talent management, and workplace culture. By exploring both the challenges and opportunities that HR professionals face, Riley aims to provide practical advice and foster healthy, productive work environments."
    }
]